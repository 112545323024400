const imageURI = process.env.VUE_APP_MILESTONES_PATH;

const primarySteps = [
  {
    level: "Apprendista Digitale",
    points: 0,
    imageLock: imageURI + "primary/Apprendista-Livello-1-Locked.svg",
    imageUnlock: imageURI + "primary/Apprendista-Livello-1-Unlocked.svg",
  },
  {
    level: "Programmatore Digitale",
    points: 100,
    imageLock: imageURI + "primary/Apprendista-Livello-2-Locked.svg",
    imageUnlock: imageURI + "primary/Apprendista-Livello-2-Unlocked.svg",
  },
  {
    level: "Esploratore Digitale",
    points: 250,
    imageLock: imageURI + "primary/Esploratore-Livello-3-Locked.svg",
    imageUnlock: imageURI + "primary/Esploratore-Livello-3-Unlocked.svg",
  },
  {
    level: "Visionario del futuro",
    points: 500,
    imageLock: imageURI + "primary/Esploratore-Livello-4-Locked.svg",
    imageUnlock: imageURI + "primary/Esploratore-Livello-4-Unlocked.svg",
  },
  {
    level: "Aiuto Mago Digitale",
    points: 1000,
    imageLock: imageURI + "primary/Mago-Livello-5-Locked.svg",
    imageUnlock: imageURI + "primary/Mago-Livello-5-Unlocked.svg",
  },
  {
    level: "Mago Digitale",
    points: 2000,
    imageLock: imageURI + "primary/Mago-Livello-6-Locked.svg",
    imageUnlock: imageURI + "primary/Mago-Livello-6-Unlocked.svg",
  },
];

const secondarySteps = [
  {
    level: "Future Dreamer",
    points: 50,
    imageLock: imageURI + "secondary/Digital-researcher-Locked.svg",
    imageUnlock: imageURI + "secondary/Digital-researcher-Unlocked.svg",
  },
  {
    level: "Digital Researcher",
    points: 100,
    imageLock: imageURI + "secondary/Digital-Master_Commander-Locked.svg",
    imageUnlock: imageURI + "secondary/Digital-Master_Commander-Unlocked.svg",
  },
  {
    level: "Future Digital Expert",
    points: 250,
    imageLock: imageURI + "secondary/Digital-Expert-Locked.svg",
    imageUnlock: imageURI + "secondary/Digital-Expert-Unlocked.svg",
  },
  {
    level: "Digital Genius",
    points: 500,
    imageLock: imageURI + "secondary/Digital-Genius-Locked.svg",
    imageUnlock: imageURI + "secondary/Digital Genius-Unlocked.svg",
  },
  {
    level: "Digital Genius",
    points: 1000,
    imageLock: imageURI + "secondary/Future-digital-expert-Locked.svg",
    imageUnlock: imageURI + "secondary/Future-digital-expert-Unlocked.svg",
  },
  {
    level: "Digital Master & Commander",
    points: 2000,
    imageLock: imageURI + "secondary/Future-dreamer-Locked.svg",
    imageUnlock: imageURI + "secondary/Future-dreamer-Unlocked.svg",
  },
];

const highSteps = [
  {
    level: "Lega Coding Adventurers",
    points: 50,
    imageLock: imageURI + "high/Lega-Coding-Adventurers-Locked.svg",
    imageUnlock: imageURI + "high/Lega-Coding-Adventurers-Unlocked.svg",
  },
  {
    level: "Lega Fullstack Developer",
    points: 100,
    imageLock: imageURI + "high/Lega-Fullstack-Developer-Locked.svg",
    imageUnlock: imageURI + "high/Lega-Fullstack-Developer-Unlocked.svg",
  },
  {
    level: "Lega Future Innovators",
    points: 250,
    imageLock: imageURI + "high/Lega-Future-Innovators-Locked.svg",
    imageUnlock: imageURI + "high/Lega-Future-Innovators-Unlocked.svg",
  },
  {
    level: "Lega jr Developer",
    points: 500,
    imageLock: imageURI + "high/Lega-jr-Developer-Locked.svg",
    imageUnlock: imageURI + "high/Lega-jr-Developer-Unlocked.svg",
  },
  {
    level: "Lega Newbie Creatives",
    points: 1000,
    imageLock: imageURI + "high/Lega-Newbie-Creatives-Locked.svg",
    imageUnlock: imageURI + "high/Lega-Newbie-Creatives-Unlocked.svg",
  },
  {
    level: "Lega Pro Coders",
    points: 2000,
    imageLock: imageURI + "high/Lega-Pro-Coders-Locked.svg",
    imageUnlock: imageURI + "high/Lega-Pro-Coders-Unlocked.svg",
  },
];

const stepName = function(role: string, points: string) {
    let steps;
    switch( role ) {
        case "primary-student": 
            steps = primarySteps;
            break;
        case "secondary-student": 
            steps = secondarySteps;
            break;
        case "high-student": 
            steps = highSteps;
            break;
    }
    const step = steps?.find( (value) => {
        return value.points == parseInt(points)
    });
    return step?.level;
}
export { primarySteps, secondarySteps, highSteps, stepName };
