<template>
  <svg
    width="278"
    height="307"
    viewBox="0 0 278 307"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="OMINO BENDA">
      <g id="Zaino" filter="url(#filter0_i_966_12367)">
        <path
          d="M39.999 108C39.999 96.9542 48.9533 87.9999 59.999 87.9999H257.999C269.045 87.9999 277.999 96.9542 277.999 108V205C277.999 216.046 269.045 225 257.999 225H59.999C48.9533 225 39.999 216.046 39.999 205V108Z"
          :fill="feet"
        />
      </g>
      <g
        id="riflesso zaino"
        style="mix-blend-mode: screen"
        filter="url(#filter1_i_966_12367)"
      >
        <path
          d="M261.999 101C266.97 101 270.999 105.029 270.999 110L270.999 209C270.999 213.97 266.97 218 261.999 218C257.028 218 252.999 213.97 252.999 209L252.999 110C252.999 105.029 257.028 101 261.999 101Z"
          fill="#4D93AA"
        />
      </g>
      <g id="Scarpa dx" filter="url(#filter2_i_966_12367)">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M89.999 306L96.999 306L106.999 306C107.003 306 107.007 306 107.011 306H132.999V270H106.999H96.999H89.999C80.0579 270 71.999 278.059 71.999 288C71.999 297.941 80.0579 306 89.999 306Z"
          fill="url(#paint0_radial_966_12367)"
        />
      </g>
      <g id="Union" filter="url(#filter3_i_966_12367)">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M154.999 306L161.999 306L171.999 306C172.003 306 172.007 306 172.011 306H197.999V270H171.999H161.999H154.999C145.058 270 136.999 278.059 136.999 288C136.999 297.941 145.058 306 154.999 306Z"
          fill="url(#paint1_radial_966_12367)"
        />
      </g>
      <g id="Gamba dx" filter="url(#filter4_i_966_12367)">
        <rect
          x="105.999"
          y="206"
          width="27"
          height="79"
          rx="13.5"
          fill="url(#paint2_linear_966_12367)"
        />
      </g>
      <g id="Gamba sx" filter="url(#filter5_i_966_12367)">
        <path
          d="M170.999 219.5C170.999 212.044 177.043 206 184.499 206C191.955 206 197.999 212.044 197.999 219.5V271.5C197.999 278.956 191.955 285 184.499 285C177.043 285 170.999 278.956 170.999 271.5V219.5Z"
          fill="url(#paint3_linear_966_12367)"
        />
      </g>
      <path
        id="Orecchio sx"
        d="M254.999 92.9999C257.888 92.9999 260.749 92.4308 263.418 91.3252C266.087 90.2196 268.512 88.5991 270.555 86.5562C272.598 84.5133 274.219 82.0881 275.324 79.4189C276.43 76.7498 276.999 73.889 276.999 70.9999C276.999 68.1108 276.43 65.25 275.324 62.5808C274.219 59.9117 272.598 57.4864 270.555 55.4435C268.512 53.4006 266.087 51.7801 263.418 50.6745C260.749 49.5689 257.888 48.9999 254.999 48.9999L254.999 70.9999L254.999 92.9999Z"
        fill="url(#paint4_radial_966_12367)"
      />
      <path
        id="Orecchio dx"
        d="M41.999 92.9999C39.1099 92.9999 36.2492 92.4308 33.58 91.3252C30.9108 90.2196 28.4856 88.5991 26.4427 86.5562C24.3998 84.5133 22.7793 82.0881 21.6737 79.4189C20.5681 76.7498 19.999 73.889 19.999 70.9999C19.999 68.1108 20.5681 65.25 21.6737 62.5808C22.7793 59.9117 24.3998 57.4864 26.4427 55.4435C28.4856 53.4006 30.9108 51.7801 33.58 50.6745C36.2492 49.5689 39.1099 48.9999 41.999 48.9999L41.999 70.9999L41.999 92.9999Z"
        fill="url(#paint5_radial_966_12367)"
      />
      <path
        id="Body"
        d="M177.517 121H117.481C89.6007 121 66.999 146.744 66.999 178.5C66.999 210.256 89.6007 236 117.481 236H177.517C205.397 236 227.999 210.256 227.999 178.5C227.999 146.744 205.397 121 177.517 121Z"
        :fill="body"
      />
      <g id="Viso" filter="url(#filter6_di_966_12367)">
        <path
          d="M40 70C40 31.8924 69.1463 1 105.1 1H191.9C227.854 1 257 31.8924 257 70V79C257 117.108 227.854 148 191.9 148H105.1C69.1463 148 40 117.108 40 79V70Z"
          :fill="face"
        />
      </g>
      <g id="Ovale">
        <g id="Ovale botttom" filter="url(#filter7_i_966_12367)">
          <path
            d="M159.999 153C173.254 153 183.999 163.745 183.999 177V183C183.999 196.255 173.254 207 159.999 207H133.999C120.744 207 109.999 196.255 109.999 183L109.999 177C109.999 163.745 120.744 153 133.999 153L159.999 153Z"
            fill="#D9D9D9"
          />
        </g>
        <g id="Ovale top" filter="url(#filter8_i_966_12367)">
          <path
            d="M157.891 157.378C168.997 157.378 178 166.381 178 177.486V182.513C178 193.619 168.997 202.622 157.891 202.622H136.107C125.001 202.622 115.998 193.619 115.998 182.513L115.998 177.486C115.998 166.381 125.001 157.378 136.107 157.378L157.891 157.378Z"
            fill="#C2C2C2"
          />
        </g>
      </g>
      <g id="occhio X">
        <path
          id="Rectangle 12"
          d="M197.41 76.8036C198.504 77.6942 198.191 79.89 196.712 81.7081C195.232 83.5262 193.145 84.278 192.051 83.3874L174.616 69.1964C173.522 68.3058 173.834 66.11 175.314 64.2919C176.794 62.4738 178.881 61.722 179.975 62.6126L197.41 76.8036Z"
          fill="#5D6A85"
        />
        <path
          id="Rectangle 13"
          d="M173.608 76.8036C172.514 77.6942 172.827 79.89 174.307 81.7081C175.786 83.5262 177.873 84.278 178.967 83.3874L196.402 69.1964C197.496 68.3058 197.184 66.11 195.704 64.2919C194.224 62.4738 192.137 61.722 191.043 62.6126L173.608 76.8036Z"
          fill="#5D6A85"
        />
      </g>
      <path
        id="Occhio sx"
        d="M202.999 37.9999C220.12 37.9999 233.999 51.8791 233.999 68.9999C233.999 86.1207 220.12 99.9999 202.999 99.9999H177.999C160.878 99.9999 146.999 86.1207 146.999 68.9999C146.999 51.8791 160.878 37.9999 177.999 37.9999L202.999 37.9999Z"
        :fill="mask"
      />
      <g id="Bocca" filter="url(#filter9_i_966_12367)">
        <path
          d="M171.999 107C174.76 107 176.999 109.238 176.999 112C176.999 114.761 174.76 117 171.999 117L127.999 117C125.238 117 122.999 114.761 122.999 112C122.999 109.238 125.238 107 127.999 107L171.999 107Z"
          fill="#5D6A85"
        />
      </g>
      <g id="Suola dx" filter="url(#filter10_i_966_12367)">
        <path
          d="M129.499 296C132.537 296 134.999 298.462 134.999 301.5C134.999 304.537 132.537 307 129.499 307L75.499 307C72.4615 307 69.999 304.537 69.999 301.5C69.999 298.462 72.4615 296 75.499 296L129.499 296Z"
          fill="url(#paint8_radial_966_12367)"
        />
      </g>
      <g id="Suola sx" filter="url(#filter11_i_966_12367)">
        <path
          d="M195.499 296C198.537 296 200.999 298.462 200.999 301.5C200.999 304.537 198.537 307 195.499 307L142.499 307C139.461 307 136.999 304.537 136.999 301.5C136.999 298.462 139.461 296 142.499 296L195.499 296Z"
          fill="url(#paint9_radial_966_12367)"
        />
      </g>
      <g id="Body-bottom">
        <path
          d="M75.7852 211.135C84.8777 226.373 100.161 236.373 117.481 236.373H177.517C194.837 236.373 210.12 226.373 219.213 211.135H75.7852Z"
          :fill="body"
        />
      </g>
      <g id="Braccio sx" filter="url(#filter13_i_966_12367)">
        <path
          d="M204.383 161.601C202.493 150.22 210.186 139.461 221.567 137.57C232.948 135.68 243.707 143.373 245.598 154.754L252.811 198.176C254.702 209.557 247.009 220.316 235.627 222.207C224.246 224.097 213.488 216.404 211.597 205.023L204.383 161.601Z"
          fill="url(#paint10_radial_966_12367)"
        />
      </g>
      <g id="Braccio dx" filter="url(#filter14_i_966_12367)">
        <path
          d="M62.6877 152.058C65.9107 140.98 77.5038 134.613 88.5815 137.836C99.6592 141.059 106.027 152.652 102.804 163.73L90.5068 205.994C87.2837 217.072 75.6907 223.439 64.613 220.216C53.5353 216.993 47.1678 205.4 50.3909 194.322L62.6877 152.058Z"
          fill="url(#paint11_radial_966_12367)"
        />
      </g>
      <g id="avambraccio sx" filter="url(#filter15_di_966_12367)">
        <path
          d="M239.441 184.474C249.573 187.204 255.573 197.631 252.843 207.763C250.113 217.895 239.686 223.895 229.554 221.165L202.863 213.972C192.731 211.242 186.731 200.815 189.461 190.683C192.192 180.551 202.619 174.551 212.751 177.281L239.441 184.474Z"
          fill="#FDB2A6"
        />
      </g>
      <g id="avambraccio dx" filter="url(#filter16_di_966_12367)">
        <path
          d="M80.1359 184.165C89.8309 188.18 94.4355 199.294 90.4206 208.989C86.4057 218.684 75.2917 223.289 65.5967 219.274L37.5532 207.66C27.8583 203.646 23.2537 192.532 27.2686 182.837C31.2835 173.142 42.3975 168.537 52.0925 172.552L80.1359 184.165Z"
          fill="#FDB2A6"
        />
      </g>
      <g id="Occhio dx">
        <path
          id="Vector"
          d="M119.499 101C136.344 101 149.999 87.5684 149.999 70.9999C149.999 54.4313 136.344 40.9999 119.499 40.9999C102.654 40.9999 88.999 54.4313 88.999 70.9999C88.999 87.5684 102.654 101 119.499 101Z"
          fill="#7B8794"
        />
        <path
          id="Vector (Stroke)"
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M155.999 70.9999C155.999 90.9752 139.563 107 119.499 107C99.4344 107 82.999 90.9752 82.999 70.9999C82.999 51.0245 99.4344 34.9999 119.499 34.9999C139.563 34.9999 155.999 51.0245 155.999 70.9999ZM149.999 70.9999C149.999 87.5684 136.344 101 119.499 101C102.654 101 88.999 87.5684 88.999 70.9999C88.999 54.4313 102.654 40.9999 119.499 40.9999C136.344 40.9999 149.999 54.4313 149.999 70.9999Z"
          fill="#3E4C59"
        />
        <g id="Vector_2" filter="url(#filter17_i_966_12367)">
          <path
            d="M121.499 95.9999C136.134 95.9999 147.999 84.3593 147.999 69.9999C147.999 55.6405 136.134 43.9999 121.499 43.9999C106.863 43.9999 94.999 55.6405 94.999 69.9999C94.999 84.3593 106.863 95.9999 121.499 95.9999Z"
            fill="white"
          />
        </g>
        <g id="Vector_3" filter="url(#filter18_di_966_12367)">
          <path
            d="M123 85C131.837 85 139 78.0604 139 69.5C139 60.9396 131.837 54 123 54C114.163 54 107 60.9396 107 69.5C107 78.0604 114.163 85 123 85Z"
            fill="#282828"
          />
        </g>
      </g>
      <g id="Benda">
        <g id="fascia nera">
          <path
            d="M27 47C27 38.7157 33.8586 32 42.3191 32H251.681C260.141 32 267 38.7157 267 47C267 55.2843 260.141 62 251.681 62H42.3191C33.8586 62 27 55.2843 27 47Z"
            :fill="mask"
          />
        </g>
        <g
          id="riflesso"
          style="mix-blend-mode: screen"
          filter="url(#filter20_i_966_12367)"
        >
          <path
            d="M39.999 52.4999C39.999 50.5669 41.566 48.9999 43.499 48.9999H230.499C232.432 48.9999 233.999 50.5669 233.999 52.4999C233.999 54.4329 232.432 55.9999 230.499 55.9999H43.499C41.566 55.9999 39.999 54.4329 39.999 52.4999Z"
            fill="#616E7C"
          />
        </g>
      </g>
      <g id="Mano dx">
        <path
          id="Ellipse 3"
          d="M45.999 180C45.999 192.702 35.7016 203 22.999 203C10.2965 203 -0.000976562 192.702 -0.000976562 180C-0.000976562 167.297 10.2965 157 22.999 157C35.7016 157 45.999 167.297 45.999 180Z"
          :fill="hands"
        />
        <path
          id="Rectangle 22"
          d="M46.6714 165.443C56.8929 169.629 61.7853 181.309 57.5988 191.531L56.0013 195.431C51.8147 205.653 40.1347 210.545 29.9131 206.359L26.96 205.149C16.7385 200.962 11.8461 189.282 16.0326 179.061L17.6301 175.16C21.8167 164.939 33.4967 160.047 43.7183 164.233L46.6714 165.443Z"
          :fill="hands"
        />
        <path
          id="pollice dx"
          d="M36.2677 145.29C39.2741 143.897 42.841 145.204 44.2346 148.211L53.9615 169.195C55.3551 172.201 54.0476 175.768 51.0412 177.162L50.4987 177.413C47.4923 178.807 43.9254 177.5 42.5318 174.493L32.8049 153.509C31.4113 150.502 32.7188 146.936 35.7252 145.542L36.2677 145.29Z"
          :fill="hands"
        />
      </g>
      <g id="Mano sx">
        <path
          id="Ellipse 4"
          d="M208.999 186.233C208.999 198.935 198.702 209.233 185.999 209.233C173.296 209.233 162.999 198.935 162.999 186.233C162.999 173.53 173.296 163.233 185.999 163.233C198.702 163.233 208.999 173.53 208.999 186.233Z"
          :fill="hands"
        />
        <path
          id="Rectangle 24"
          d="M209.671 171.675C219.893 175.862 224.785 187.542 220.599 197.763L219.001 201.664C214.815 211.885 203.135 216.778 192.913 212.591L189.96 211.382C179.738 207.195 174.846 195.515 179.033 185.293L180.63 181.393C184.817 171.172 196.497 166.279 206.718 170.466L209.671 171.675Z"
          :fill="hands"
        />
        <path
          id="pollice sx"
          d="M199.268 151.523C202.274 150.13 205.841 151.437 207.235 154.443L216.961 175.428C218.355 178.434 217.048 182.001 214.041 183.395L213.499 183.646C210.492 185.04 206.925 183.732 205.532 180.726L195.805 159.742C194.411 156.735 195.719 153.168 198.725 151.775L199.268 151.523Z"
          :fill="hands"
        />
      </g>
    </g>
    <defs>
      <filter
        id="filter0_i_966_12367"
        x="39.999"
        y="81.9999"
        width="244"
        height="143"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="6" dy="-6" />
        <feGaussianBlur stdDeviation="3.5" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect1_innerShadow_966_12367"
        />
      </filter>
      <filter
        id="filter1_i_966_12367"
        x="252.999"
        y="101"
        width="18"
        height="119"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="2" />
        <feGaussianBlur stdDeviation="1.5" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect1_innerShadow_966_12367"
        />
      </filter>
      <filter
        id="filter2_i_966_12367"
        x="71.999"
        y="270"
        width="61"
        height="41.0588"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="5.0588" />
        <feGaussianBlur stdDeviation="2.5294" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect1_innerShadow_966_12367"
        />
      </filter>
      <filter
        id="filter3_i_966_12367"
        x="136.999"
        y="270"
        width="61"
        height="41.0588"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="5.0588" />
        <feGaussianBlur stdDeviation="2.5294" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect1_innerShadow_966_12367"
        />
      </filter>
      <filter
        id="filter4_i_966_12367"
        x="105.999"
        y="206"
        width="27"
        height="83"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="5" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect1_innerShadow_966_12367"
        />
      </filter>
      <filter
        id="filter5_i_966_12367"
        x="170.999"
        y="206"
        width="27"
        height="83"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="5" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect1_innerShadow_966_12367"
        />
      </filter>
      <filter
        id="filter6_di_966_12367"
        x="35"
        y="0"
        width="227"
        height="157"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="2.5" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_966_12367"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_966_12367"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1" />
        <feGaussianBlur stdDeviation="4.5" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect2_innerShadow_966_12367"
        />
      </filter>
      <filter
        id="filter7_i_966_12367"
        x="109.999"
        y="153"
        width="76"
        height="56"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="2" dy="2" />
        <feGaussianBlur stdDeviation="1.5" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect1_innerShadow_966_12367"
        />
      </filter>
      <filter
        id="filter8_i_966_12367"
        x="115.998"
        y="157.378"
        width="63.6777"
        height="46.9204"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="1.67573" dy="1.67573" />
        <feGaussianBlur stdDeviation="1.2568" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect1_innerShadow_966_12367"
        />
      </filter>
      <filter
        id="filter9_i_966_12367"
        x="122.999"
        y="105"
        width="54"
        height="12"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="-4" />
        <feGaussianBlur stdDeviation="1" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect1_innerShadow_966_12367"
        />
      </filter>
      <filter
        id="filter10_i_966_12367"
        x="65.999"
        y="296"
        width="69"
        height="15"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="-4" dy="4" />
        <feGaussianBlur stdDeviation="12" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.121569 0 0 0 0 0.160784 0 0 0 0 0.2 0 0 0 1 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect1_innerShadow_966_12367"
        />
      </filter>
      <filter
        id="filter11_i_966_12367"
        x="132.999"
        y="296"
        width="68"
        height="15"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="-4" dy="4" />
        <feGaussianBlur stdDeviation="12" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.121569 0 0 0 0 0.160784 0 0 0 0 0.2 0 0 0 1 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect1_innerShadow_966_12367"
        />
      </filter>
      <filter
        id="filter12_i_966_12367"
        x="71.7852"
        y="211.135"
        width="147.427"
        height="29.2379"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="-4" dy="4" />
        <feGaussianBlur stdDeviation="10" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.337405 0 0 0 0 0.0432639 0 0 0 0 0.598403 0 0 0 1 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect1_innerShadow_966_12367"
        />
      </filter>
      <filter
        id="filter13_i_966_12367"
        x="200.098"
        y="137.285"
        width="52.9995"
        height="89.2076"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="-4" dy="4" />
        <feGaussianBlur stdDeviation="12" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.121569 0 0 0 0 0.160784 0 0 0 0 0.2 0 0 0 1 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect1_innerShadow_966_12367"
        />
      </filter>
      <filter
        id="filter14_i_966_12367"
        x="45.5537"
        y="136.999"
        width="58.0869"
        height="88.0546"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="-4" dy="4" />
        <feGaussianBlur stdDeviation="12" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.121569 0 0 0 0 0.160784 0 0 0 0 0.2 0 0 0 1 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect1_innerShadow_966_12367"
        />
      </filter>
      <filter
        id="filter15_di_966_12367"
        x="183.802"
        y="175.622"
        width="74.6997"
        height="55.2018"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="2.5" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_966_12367"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_966_12367"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1" />
        <feGaussianBlur stdDeviation="4.5" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect2_innerShadow_966_12367"
        />
      </filter>
      <filter
        id="filter16_di_966_12367"
        x="20.8179"
        y="170.101"
        width="76.0532"
        height="59.6234"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="2.5" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_966_12367"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_966_12367"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1" />
        <feGaussianBlur stdDeviation="4.5" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect2_innerShadow_966_12367"
        />
      </filter>
      <filter
        id="filter17_i_966_12367"
        x="94.999"
        y="41.9999"
        width="55"
        height="54"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="2" dy="-2" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect1_innerShadow_966_12367"
        />
      </filter>
      <filter
        id="filter18_di_966_12367"
        x="105"
        y="53"
        width="36"
        height="36"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1" />
        <feGaussianBlur stdDeviation="1" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_966_12367"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_966_12367"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.44 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect2_innerShadow_966_12367"
        />
      </filter>
      <filter
        id="filter19_i_966_12367"
        x="23"
        y="32"
        width="244"
        height="34"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="-4" dy="4" />
        <feGaussianBlur stdDeviation="12" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.121569 0 0 0 0 0.160784 0 0 0 0 0.2 0 0 0 1 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect1_innerShadow_966_12367"
        />
      </filter>
      <filter
        id="filter20_i_966_12367"
        x="39.999"
        y="48.9999"
        width="194"
        height="11"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect1_innerShadow_966_12367"
        />
      </filter>
      <radialGradient
        id="paint0_radial_966_12367"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(98.0137 285.353) rotate(63.0543) scale(17.8166 26.1492)"
      >
        <stop stop-color="#706C7C" />
        <stop offset="1" stop-color="#212124" />
      </radialGradient>
      <radialGradient
        id="paint1_radial_966_12367"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(163.014 285.353) rotate(63.0543) scale(17.8166 26.1492)"
      >
        <stop stop-color="#706C7C" />
        <stop offset="1" stop-color="#212124" />
      </radialGradient>
      <linearGradient
        id="paint2_linear_966_12367"
        x1="119"
        y1="219"
        x2="119"
        y2="257.5"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#616E7C" />
        <stop offset="1" stop-color="#D9D9D9" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_966_12367"
        x1="184"
        y1="219"
        x2="184"
        y2="257.5"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#616E7C" />
        <stop offset="1" stop-color="#D9D9D9" />
      </linearGradient>
      <radialGradient
        id="paint4_radial_966_12367"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(254.999 70.9999) rotate(-180) scale(22)"
      >
        <stop stop-color="#7A11D7" />
        <stop offset="1" stop-color="#3A006C" />
      </radialGradient>
      <radialGradient
        id="paint5_radial_966_12367"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(41.999 70.9999) scale(22)"
      >
        <stop stop-color="#7A11D7" />
        <stop offset="1" stop-color="#3A006C" />
      </radialGradient>
      <radialGradient
        id="paint6_radial_966_12367"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(148.5 74.5) rotate(176.706) scale(104.426 118.147)"
      >
        <stop stop-color="#FDCBC3" />
        <stop offset="1" stop-color="#FDB2A6" />
      </radialGradient>
      <radialGradient
        id="paint7_radial_966_12367"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(196.499 57.9999) rotate(178.152) scale(46.5242 33.1552)"
      >
        <stop offset="0.0178998" />
        <stop offset="1" stop-color="#1F2933" />
      </radialGradient>
      <radialGradient
        id="paint8_radial_966_12367"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(90.9668 300.741) rotate(75.0252) scale(6.08599 33.5965)"
      >
        <stop offset="0.0178998" />
        <stop offset="1" stop-color="#1F2933" />
      </radialGradient>
      <radialGradient
        id="paint9_radial_966_12367"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(157.644 300.741) rotate(75.2455) scale(6.07979 33.1134)"
      >
        <stop offset="0.0178998" />
        <stop offset="1" stop-color="#1F2933" />
      </radialGradient>
      <radialGradient
        id="paint10_radial_966_12367"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(219.904 174.012) rotate(88.5089) scale(45.5574 26.2077)"
      >
        <stop offset="0.0178998" />
        <stop offset="1" stop-color="#1F2933" />
      </radialGradient>
      <radialGradient
        id="paint11_radial_966_12367"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(67.0011 173.229) rotate(88.3316) scale(44.9448 28.9264)"
      >
        <stop offset="0.0178998" />
        <stop offset="1" stop-color="#1F2933" />
      </radialGradient>
      <radialGradient
        id="paint12_radial_966_12367"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(104.419 44.931) rotate(70.0935) scale(17.0534 120.737)"
      >
        <stop offset="0.0178998" />
        <stop offset="1" stop-color="#1F2933" />
      </radialGradient>
      <radialGradient
        id="paint13_radial_966_12367"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(9.5 176) rotate(64.8852) scale(17.6706)"
      >
        <stop stop-color="#946BBF" />
        <stop offset="1" stop-color="#6D25BA" />
      </radialGradient>
      <linearGradient
        id="paint14_linear_966_12367"
        x1="56.8001"
        y1="193.481"
        x2="16.8314"
        y2="177.111"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.0833333" stop-color="#D9D9D9" />
        <stop offset="0.973958" stop-color="#8800FF" />
      </linearGradient>
      <radialGradient
        id="paint15_radial_966_12367"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(172.5 182.233) rotate(64.8852) scale(17.6706)"
      >
        <stop stop-color="#946BBF" />
        <stop offset="1" stop-color="#6D25BA" />
      </radialGradient>
      <linearGradient
        id="paint16_linear_966_12367"
        x1="219.8"
        y1="199.714"
        x2="179.831"
        y2="183.343"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.0833333" stop-color="#D9D9D9" />
        <stop offset="0.973958" stop-color="#8800FF" />
      </linearGradient>
    </defs>
  </svg>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "Secondary3",
  props: {
    mask: String,
    face: String,
    body: String,
    hands: String,
    feet: String,
  },
});
</script>
