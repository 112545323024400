<template>
  <svg
    width="190"
    height="188"
    viewBox="0 0 190 188"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M167.295 93.4033C167.295 145.385 135.046 187.53 95.2805 187.53C55.5154 187.53 23.2661 145.385 23.2661 93.4033"
      :fill="body"
    />
    <path
      d="M95.2805 100.456C135.054 100.456 167.295 97.2957 167.295 93.4031C167.295 89.5105 135.046 86.3503 95.2805 86.3503C55.5154 86.3503 23.2661 89.5078 23.2661 93.4031C23.2661 97.2984 55.51 100.456 95.2805 100.456Z"
      :fill="body"
      filter="brightness(1.3)"
    />
    <path
      d="M159.552 108.379C164.865 106.321 170.93 109.12 173.097 114.635L188.792 154.561C190.96 160.076 188.407 166.226 183.091 168.271C177.778 170.327 171.714 167.528 169.547 162.016L153.851 122.086C151.684 116.574 154.247 110.436 159.552 108.379Z"
      fill="#1F2933"
    />
    <path
      d="M36.1468 120.909L20.454 160.844C18.2867 166.356 12.2221 169.158 6.90643 167.099C1.59076 165.04 -0.959152 158.904 1.20811 153.392L16.9011 113.463C19.0683 107.951 25.1328 105.149 30.4485 107.208C35.7614 109.259 38.3141 115.397 36.1468 120.909Z"
      fill="#1F2933"
    />
    <path
      d="M22.988 136.496C32.5334 136.496 40.2714 128.815 40.2714 119.341C40.2714 109.867 32.5334 102.187 22.988 102.187C13.4426 102.187 5.70447 109.867 5.70447 119.341C5.70447 128.815 13.4426 136.496 22.988 136.496Z"
      :fill="hands"
    />
    <path
      d="M164.524 141.172C175.227 141.172 183.904 132.56 183.904 121.937C183.904 111.314 175.227 102.702 164.524 102.702C153.821 102.702 145.145 111.314 145.145 121.937C145.145 132.56 153.821 141.172 164.524 141.172Z"
      :fill="hands"
    />
    <path
      d="M16.2477 16.2624C16.2477 7.5468 25.6875 0.480469 37.33 0.480469H154.346C165.991 0.480469 175.431 7.53324 175.431 16.2624V60.0627C175.431 68.1879 172.179 75.9803 166.39 81.7257C160.602 87.4711 152.751 90.6988 144.564 90.6988H47.1141C43.0605 90.6991 39.0467 89.907 35.3016 88.3675C31.5566 86.828 28.1537 84.5714 25.2874 81.7265C22.4211 78.8816 20.1475 75.5041 18.5965 71.787C17.0454 68.0699 16.2473 64.086 16.2477 60.0627V16.2624Z"
      :fill="head"
    />
    <path
      d="M130.091 35.3375C133.859 35.3426 137.471 36.8303 140.135 39.4745C142.799 42.1187 144.298 45.7036 144.303 49.443V61.0394C144.298 64.7789 142.799 68.3637 140.135 71.008C137.471 73.6522 133.859 75.1399 130.091 75.1449H61.5998C57.8322 75.1399 54.2204 73.6522 51.5563 71.008C48.8922 68.3637 47.3933 64.7789 47.3882 61.0394V49.443C47.3933 45.7036 48.8922 42.1187 51.5563 39.4745C54.2204 36.8303 57.8322 35.3426 61.5998 35.3375H130.102H130.091ZM130.102 23.4021H61.5998C54.6414 23.4021 47.9679 26.1457 43.0476 31.0293C38.1272 35.9129 35.363 42.5366 35.363 49.443V61.0394C35.363 67.9459 38.1272 74.5695 43.0476 79.4531C47.9679 84.3368 54.6414 87.0803 61.5998 87.0803H130.102C137.061 87.0803 143.734 84.3368 148.654 79.4531C153.575 74.5695 156.339 67.9459 156.339 61.0394V49.443C156.339 42.5366 153.575 35.9129 148.654 31.0293C143.734 26.1457 137.061 23.4021 130.102 23.4021V23.4021Z"
      :fill="hair"
    />
    <path
      d="M51.7054 33.2405L44.6133 5.79717C44.0995 3.81155 46.0153 1.61978 48.9041 0.884661L49.5873 0.711051C52.4761 -0.0267756 55.2173 0.982294 55.7311 2.9652L62.8232 30.4086C63.337 32.3942 61.4212 34.586 58.5324 35.3211L57.8491 35.4947C54.9549 36.2108 52.211 35.2017 51.7054 33.2405Z"
      :fill="hair"
    />
    <path
      d="M129.577 30.5468L136.986 3.04645C137.532 1.05812 140.265 0.0625878 143.157 0.835678L143.838 1.01743C146.718 1.78239 148.609 4.00128 148.074 5.98961L140.664 33.4899C140.118 35.4783 137.385 36.4738 134.493 35.7007L133.813 35.5189C130.938 34.7323 129.047 32.5134 129.577 30.5468Z"
      :fill="hair"
    />
    <path d="M102.441 113.335L67.7377 133.569L88.123 168.013" :fill="dot" />
    <path d="M88.1223 168.013L122.826 147.78L102.441 113.335" fill="#E4FCED" />
    <path
      d="M106.027 68.9086C113.631 68.9086 119.796 62.79 119.796 55.2425C119.796 47.6949 113.631 41.5764 106.027 41.5764C98.4224 41.5764 92.2579 47.6949 92.2579 55.2425C92.2579 62.79 98.4224 68.9086 106.027 68.9086Z"
      fill="white"
    />
    <path
      d="M84.5344 68.9086C92.1387 68.9086 98.3032 62.79 98.3032 55.2425C98.3032 47.6949 92.1387 41.5764 84.5344 41.5764C76.9301 41.5764 70.7656 47.6949 70.7656 55.2425C70.7656 62.79 76.9301 68.9086 84.5344 68.9086Z"
      fill="white"
    />
    <path
      d="M88.5736 63.3315C93.0746 63.3315 96.7233 59.7099 96.7233 55.2425C96.7233 50.7751 93.0746 47.1536 88.5736 47.1536C84.0726 47.1536 80.4237 50.7751 80.4237 55.2425C80.4237 59.7099 84.0726 63.3315 88.5736 63.3315Z"
      fill="#1F2933"
    />
    <path
      d="M102.34 63.3315C106.841 63.3315 110.49 59.7099 110.49 55.2425C110.49 50.7751 106.841 47.1536 102.34 47.1536C97.8387 47.1536 94.1899 50.7751 94.1899 55.2425C94.1899 59.7099 97.8387 63.3315 102.34 63.3315Z"
      fill="#1F2933"
    />
  </svg>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "Avatar3",
  props: {
    hair: String,
    head: String,
    body: String,
    hands: String,
    dot: String,
  },

});
</script>
