<template>
  <div class="svg-container">
    <template v-if="role == 'primary-student'">
      <Primary1
        v-if="avatar == 'robot-1'"
        :hair="part1"
        :head="part2"
        :body="part3"
        :hands="part4"
        :dot="part5"
      />
      <Primary2
        v-if="avatar == 'robot-2'"
        :hair="part1"
        :head="part2"
        :body="part3"
        :hands="part4"
        :dot="part5"
      />
      <Primary3
        v-if="avatar == 'robot-3'"
        :hair="part1"
        :head="part2"
        :body="part3"
        :hands="part4"
        :dot="part5"
      />
      <Primary4
        v-if="avatar == 'robot-4'"
        :hair="part1"
        :head="part2"
        :body="part3"
        :hands="part4"
        :dot="part5"
      />
      <Primary5
        v-if="avatar == 'robot-5'"
        :hair="part1"
        :head="part2"
        :body="part3"
        :hands="part4"
        :dot="part5"
      />
    </template>
    <template v-else-if="role == 'secondary-student'">
      <Secondary1
        v-if="avatar == 'guy-green-violet'"
        :mask="part1"
        :face="part2"
        :body="part3"
        :hands="part4"
        :feet="part5"
      />
      <Secondary2
        v-if="avatar == 'gal-band'"
        :mask="part1"
        :face="part2"
        :body="part3"
        :hands="part4"
        :feet="part5"
      />
      <Secondary3
        v-if="avatar == 'guy-band'"
        :mask="part1"
        :face="part2"
        :body="part3"
        :hands="part4"
        :feet="part5"
      />
      <Secondary4
        v-if="avatar == 'bot-blue'"
        :mask="part1"
        :face="part2"
        :body="part3"
        :hands="part4"
        :feet="part5"
      />
      <Secondary5
        v-if="avatar == 'bot-salmon'"
        :mask="part1"
        :face="part2"
        :body="part3"
        :hands="part4"
        :feet="part5"
      />
    </template>

    <template v-else>
      <High1
        v-if="avatar == 'boys'"
        :body="part1"
        :eyes="part2"
        :eyeBrow="part3"
        :hair="part4"
        :tshirt="part5"
      />
      <High2
        v-if="avatar == 'girls'"
        :body="part1"
        :eyes="part2"
        :eyeBrow="part3"
        :hair="part4"
        :tshirt="part5"
      />
    </template>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import { useUserInfoStore } from "@/store";

import Primary1 from "@/assets/images/avatars/Primary1.vue";
import Primary2 from "@/assets/images/avatars/Primary2.vue";
import Primary3 from "@/assets/images/avatars/Primary3.vue";
import Primary4 from "@/assets/images/avatars/Primary4.vue";
import Primary5 from "@/assets/images/avatars/Primary5.vue";

import Secondary1 from "@/assets/images/avatars/Secondary1.vue";
import Secondary2 from "@/assets/images/avatars/Secondary2.vue";
import Secondary3 from "@/assets/images/avatars/Secondary3.vue";
import Secondary4 from "@/assets/images/avatars/Secondary4.vue";
import Secondary5 from "@/assets/images/avatars/Secondary5.vue";

import High1 from "@/assets/images/avatars/High1.vue";
import High2 from "@/assets/images/avatars/High2.vue";

export default defineComponent({
  name: "Profile",
  props: {
    avatar: String,
    part1: String,
    part2: String,
    part3: String,
    part4: String,
    part5: String,
  },
  components: {
    Primary1,
    Primary2,
    Primary3,
    Primary4,
    Primary5,
    Secondary1,
    Secondary2,
    Secondary3,
    Secondary4,
    Secondary5,
    High1,
    High2,
  },
  setup() {
    const userStore = useUserInfoStore();

    const role = userStore.currentUserInfo.role;

    return {
      role,
    };
  },
});
</script>

<style scoped>
.svg-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100% !important;
  width: 100% !important;
}

.svg-container-sm {
  width: 70px;
  height: 70px;
}
.svg-container-lg {
  width: 100px !important;
  height: 100px !important;
}

.svg-container-xl {
  width: 150px !important;
  height: 150px !important;
}

.svg-container-xxl {
  width: 75% !important;
  height: 75% !important;
}
.svg-container > svg {
  height: 100% !important;
  width: 100% !important;
}
</style>
