<template>
  <!-- Open Password Modal 3 -->
  <button
    id="OpenPasswordModal3"
    type="button"
    data-bs-target="#CreatePasswordModal3"
    data-bs-toggle="modal"
    class="d-none"
  ></button>
  <!-- Close Password Modal 2 -->
  <button
    id="ClosePasswordModal2"
    type="button"
    data-bs-target="#CreatePasswordModal2"
    data-bs-dismiss="modal"
    class="d-none"
  ></button>

  <!-- Modal -->
  <div
    class="modal fade"
    id="CreatePasswordModal2"
    data-bs-backdrop="static"
    tabindex="-1"
    aria-labelledby="CreatePasswordModal2Label"
    aria-hidden="true"
  >
    <div
      class="modal-dialog modal-dialog-slideout modal-lg modal-dialog-centered"
    >
      <div class="modal-content m-4 m-lg-0">
        <div class="row p-3 p-lg-4">
          <div class="col-12">
            <h2 class="text1 mt-2">Crea la tua password</h2>
            <p class="text2 my-4">
              Inserisci una nuova password per cambiare quella provvisoria.
            </p>
            <form @submit.prevent="changePassword">
              <div
                v-if="errors && errors?.message"
                class="login-error-wrapper mb-2 p-1 d-flex align-items-center"
              >
                <p class="login-error-text p-0 m-0">{{ errors?.message }}</p>
              </div>

              <div class="mt-3 mb-2 position-relative">
                <label for="new_password" class="form-label ms-3"
                  >Nuova Password</label
                >
                <input
                  :type="show1 == false ? 'password' : 'text'"
                  v-model="password"
                  class="form-control px-4"
                  :class="[
                    password != '' ? 'active' : '',
                    errors && errors?.message ? 'is-invalid' : '',
                  ]"
                  id="new_password"
                  placeholder="Inserisci nuova password"
                  aria-describedby="password"
                  required
                  @input="passwordChanged"
                />
                <img
                  v-if="!show1"
                  class="position-absolute eye"
                  @click="show1 = !show1"
                  src="@/assets/images/icons/Eye-Crossed.svg"
                  alt=""
                />
                <img
                  v-else
                  class="position-absolute eye"
                  @click="show1 = !show1"
                  style="top: 45px; right: 35px; cursor: pointer"
                  src="@/assets/images/icons/Eye-Active.svg"
                  alt=""
                />
              </div>
              <div class="my-3 position-relative">
                <label for="confirm_password" class="form-label ms-3"
                  >Conferma Password</label
                >
                <input
                  :type="show2 == false ? 'password' : 'text'"
                  v-model="confirm_password"
                  class="form-control px-4"
                  :class="[
                    confirm_password != '' ? 'active' : '',
                    errors && errors?.message ? 'is-invalid' : '',
                  ]"
                  placeholder="Inserisci la password"
                  id="confirm_password"
                  aria-describedby="confirmPassword"
                  required
                />
                <img
                  v-if="!show2"
                  class="position-absolute eye"
                  @click="show2 = !show2"
                  src="@/assets/images/icons/Eye-Crossed.svg"
                  alt=""
                />
                <img
                  v-else
                  class="position-absolute eye"
                  @click="show2 = !show2"
                  style="top: 45px; right: 35px; cursor: pointer"
                  src="@/assets/images/icons/Eye-Active.svg"
                  alt=""
                />
              </div>
              <div class="ps-2">
                <p class="password-check mt-5">
                  La tua password deve contenere:
                </p>
                <ul class="list-group ps-1 ps-lg-2">
                  <li class="list-group-item mt-2" :class="rulePwdMin8Chars.class()">
                    Minimo 8 caratteri
                  </li>
                  <!-- li class="list-group-item mt-2">
                    <img
                      src="@/assets/images/icons/Path.svg"
                      class="me-2"
                      alt=""
                    />Almeno una lettera maiuscola
                  </li>
                  <li class="list-group-item mt-2">
                    <img
                      src="@/assets/images/icons/Path.svg"
                      class="me-2"
                      alt=""
                    />Almeno un numero
                  </li>
                  <li class="list-group-item mt-2">
                    <img
                      src="@/assets/images/icons/Path.svg"
                      class="me-1"
                      alt=""
                    />
                    Almeno un carattere speciale (es. $ ! &)
                  </li -->
                </ul>
              </div>
              <div class="row d-flex justify-content-center mt-2">
                <div class="col-12 col-lg-6 d-flex justify-content-center">
                  <button
                    type="submit"
                    class="form-submit-button btn btn-platform mt-4 w-100"
                    :disabled="password == '' || confirm_password == ''"
                  >
                    CONFERMA
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import ApiService from "@/core/services/ApiService";
import router from "@/router";
import { useUserInfoStore } from "@/store";
import { defineComponent, ref } from "vue";

export default defineComponent({
  name: "CreatePasswordModal2",
  setup() {
    const password = ref("");
    const confirm_password = ref("");

    const rulePwdConfirmed = {
        rule: (value1: string, value2: string) => {
            if( value1 == value2 ) {
                return true;
            }
            return false;
        },
        message: "La nuova password e la password di conferma non corrispondono.",
        result: ref<null|boolean>(null)
    };
    const rulePwdMin8Chars = {
        rule: (value: string) => {
            if( value.length >= 8 ) {
                return true;
            }
            return false;
        },
        message: "La nuova password deve essere lunga almeno 8 caratteri.",
        result: ref<null|boolean>(null),
        class: () => {
            if( rulePwdMin8Chars.result.value == null ) {
                return "";
            } else if( rulePwdMin8Chars.result.value == true ) {
                return "rule-ok";
            } else if( rulePwdMin8Chars.result.value == false ) {
                return "rule-nok";
            }
        }
    };

    const rules = [
        rulePwdMin8Chars,
        rulePwdConfirmed
    ]

    const errors = ref({} as any);

    const userStore = useUserInfoStore();

    const validateForm = () => {
        return rules.reduce( (carry, rule) => {
            if( carry && !(rule.result.value = rule.rule(password.value, confirm_password.value)) ) {
                errors.value.message = rule.message;
                carry = false;
            }
            return carry;

        }, true);
    };

    const passwordChanged = () => {
        rulePwdMin8Chars.result.value = rulePwdMin8Chars.rule(password.value);
    };

    const changePassword = () => {
      if (!validateForm()) {
        return;
      } else {
        errors.value = {};
      }

      if (password.value == confirm_password.value) {
        const data = {
          data: {
            // password: password.value,
            "new-password": password.value,
          },
        };

        ApiService.patch("api/password/set", data)
          .then(() => {
            const user = userStore.currentUser;

            if (user.nickname && user.avatar) {
              document  
                .querySelector<HTMLButtonElement>("#ClosePasswordModal2")
                ?.click();
              router.push("/");
            } else {
              document
                .querySelector<HTMLButtonElement>("#OpenPasswordModal3")
                ?.click();
            }
          })
          .catch((error) => {
            console.log(error.response.data);
            errors.value.message = error.response.data.errors.join();
          });
      }
    };

    const show1 = ref(false);
    const show2 = ref(false);

    return {
      password,
      confirm_password,
      passwordChanged,
      changePassword,
      rulePwdMin8Chars,
      show1,
      show2,
      errors,
    };
  },
});
</script>

<style lang="scss" scoped>
.modal-content {
  background: #ffffff !important;
  border: 2px solid #fcfcfc !important;
  box-shadow: 0px 8px 18px rgba(123, 135, 148, 0.12),
    0px 12px 42px rgba(123, 135, 148, 0.12) !important;
  border-radius: 16px !important;
}

.text1 {
  font-weight: 600;
  font-size: 2rem;
  line-height: 120%;
  letter-spacing: -0.019em;
  color: var(--bs-blue);
  text-align: center;
}

.text2 {
  font-weight: 500;
  font-size: 1rem;
  line-height: 120%;
  letter-spacing: 0.01em;
  color: #1f2933;
  text-align: center;
}

.eye {
  top: 45px;
  right: 20px;
  cursor: pointer;
}
.password-check {
  font-weight: 600;
  font-size: 1rem;
  line-height: 120%;
  color: $platform-text-color-2;
}

.list-group-item {
  font-style: normal;
  font-weight: 400;
  font-size: 0.9em;
  line-height: 120%;
  color: #7b8794;
  background-color: inherit !important;

  &.rule-ok {
    color: green;

    &:before {
        content: "✓";
        display: inline-block;
        margin-right: 4px;
    }
  }
  &.rule-nok {
    color: red;
    &:before {
        content: "-";
        display: inline-block;
        margin-right: 4px;
    }
  }
}

.form-control.active {
  border: 3px solid $platform-bg !important;
  border-radius: 49px !important;
}

.form-control:focus-visible {
  border: 3px solid $platform-bg !important;
  box-shadow: 0px 0px 0px 4px rgba(3, 40, 142, 0.3) !important;
  border-radius: 49px !important;
}

.form-control.is-invalid {
  border: 3px solid #ff5252 !important;
  box-shadow: 0px 0px 0px 4px #e8533a !important;
  border-radius: 49px !important;
  background-image: none !important;
}

.login-error-wrapper {
  background: #fde3e4;
  border-radius: 0px 5px 5px 0px;
  border-left: 3px solid #ff5252 !important;
  border-radius: 5px 5px 5px 5px;
}

.login-error-text {
  font-weight: 500;
  font-size: 16px;
  line-height: 120%;
  color: #1f2933;
}
</style>
