<template>
  <div class="row">
    <div class="col-12 d-none d-lg-flex justify-content-end position-relative">
      <img src="@/assets/images/logos/logo.svg" alt="" />
      <button
        @click="$emit('returnBack')"
        class="btn turn-back d-flex align-items-center px-3"
      >
        <img src="@/assets/images/icons/Back.svg" class="me-2" alt="" />
        <span class="turn-back-text">INDIETRO</span>
      </button>
    </div>
    <div class="col-12 d-flex justify-content-center d-lg-none">
      <img src="@/assets/images/logos/logo45x45.svg" alt="" />
      <button
        @click="$emit('returnBack')"
        class="btn turn-back d-flex align-items-center"
      >
        <img src="@/assets/images/icons/Back.svg" alt="" />
      </button>
    </div>
  </div>
  <div
    class="row d-flex flex-column align-items-center justify-content-center mt-5"
  >
    <div class="col-10 col-lg-8">
      <h1 class="title text-center text-primary my-4">
        {{ "Ottima scelta " + (user.nickname ?? user.name) + "!" }}
      </h1>
      <p class="subtitle text-center m-0 px-4">
        Adesso personalizza il tuo avatar: clicca sui vestiti, sulle parti del
        corpo e sul colore che preferisci.
      </p>
    </div>
    <EditAvatar :startAvatar="avatar" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import { useUserInfoStore } from "@/store";

import EditAvatar from "@/components/profile/EditAvatar.vue";

export default defineComponent({
  name: "ChooseColors",
  components: {
    EditAvatar,
  },
  props: {
    avatar: Object,
  },
  emits: ["returnBack"],
  setup(props) {
    const userStore = useUserInfoStore();

    const user = userStore.currentUser;

    return { user, props };
  },
});
</script>

<style lang="scss" scoped>
.glide {
  cursor: pointer;
}
.glide__slides {
  display: flex;
  align-items: center;
}

.glide__slide {
  display: flex;
  justify-content: center;
  align-items: center;
}
.robot-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 193px !important;
  width: 193px !important;
  background: radial-gradient(
    56.47% 56.47% at 50% 50%,
    rgba(254, 225, 220, 0) 0%,
    #fee1dc 100%
  );
  border: 5px solid #fdcbc3;
  border-radius: 44.6877px;
}

.robot {
  height: 80%;
  width: 80%;
}

.form-control {
  background: #ffffff;
  border: 1px solid #cbd2d9;
  box-shadow: 0px 0px 4px rgba(123, 135, 148, 0.08),
    0px 2px 4px rgba(123, 135, 148, 0.1);
  border-radius: 49px;
}

.title {
  font-weight: 700;
  font-size: 2.4rem;
  line-height: 120%;
  text-align: center;
  letter-spacing: -0.019em;
}

.subtitle {
  font-weight: 500;
  font-size: 1.2rem;
  line-height: 120%;
  color: #242424;
}

.turn-back {
  position: absolute;
  top: 15px;
  left: 20px;
  //background: #fde3e4;
  //box-shadow: 0px 1px 8px rgba(123, 135, 148, 0.12),
  //  0px 8px 16px rgba(123, 135, 148, 0.08);
  //border-radius: 50px;
  padding: 8px 8px;
  margin: 0 !important;
}

.turn-back-text {
  /*
  font-weight: 600;
  font-size: 1.2rem;
  line-height: 120%;
  text-align: justify;
  letter-spacing: 0.5px;
  color: var(--bs-blue);
  */
    border-radius: 50px;
    padding: 18px 24px 18px 24px;
    border: 3px solid rgba(60, 232, 218, 1);
    box-shadow: 0px 8px 16px 0px rgba(123, 135, 148, 0.08);
    box-shadow: 0px 1px 8px 0px rgba(123, 135, 148, 0.12);

    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0.5px;
    text-align: center;
    color: rgba(65, 45, 205, 1);
    text-transform: uppercase;

}

@media only screen and (max-width: 768px) {
  .glide__slide--active + li .robot-wrapper {
    height: 243px !important;
    width: 243px !important;
  }
}

@media only screen and (min-width: 768px) {
  .glide__slide--active + li + li .robot-wrapper {
    height: 243px !important;
    width: 243px !important;
  }
}
</style>
