<template>
  <div class="container-fluid py-5">
    <div class="row justify-content-center justify-content-xl-evenly">
      <div
        class=" col-8 col-sm-4 col-lg-auto d-flex justify-content-center align-items-center"
      >
        <div class="d-flex flex-column align-items-center">
          <div class="img-avatar-container col-8 col-sm-4 col-lg-auto d-flex justify-content-center align-items-center">
            <div class="img-avatar p-lg-4">
              <ProfileAvatar
                :avatar="avatar"
                :part1="part1"
                :part2="part2"
                :part3="part3"
                :part4="part4"
                :part5="part5"
              />
            </div>
          </div>
          <div class="col-8 col-sm-4 col-lg-auto d-flex justify-content-center align-items-center mt-3">
            <button
              type="button"
              @click="resetAvatar()"
              class="course-header-high-link pb-1"
            >
              RESET AVATAR
            </button>
          </div>
        </div>
      </div>
      
      
      <div
        class="col-12 col-lg-auto d-flex flex-column align-items-center justify-content-start mt-5 mt-lg-0 py-3 rounded-4 parts-wrapper"
      >
        <div class="p-3 divisor-sm w-100">
          <h5 class="colors-title mb-3">{{ getPartName }}</h5>
          <div class="row g-2 gx-3">
            <div
              v-for="(part, index) in parts"
              :key="part"
              class="col-4 col-lg-auto"
            >
              <button
                @click="currentPart = 'part' + (index + 1)"
                class="btn avatar-piece rounded-4"
                :class="currentPart == 'part' + (index + 1) ? 'select' : ''"
              >
                <img class="img-fluid" :src="getURI + part + '.svg'" alt="" />
              </button>
            </div>
          </div>
        </div>

        <div class="mt-4 divisor p-3 pe-0 w-100">
          <h5 class="colors-title mb-3">Colore</h5>

          <div class="row g-2 gx-3">
            <div v-for="color in colors" :key="color" class="col-auto">
              <button
                :style="{ backgroundColor: color }"
                @click="changeColor(color)"
                :class="currentColor == color ? 'select' : ''"
                class="btn avatar-piece-color rounded-circle"
              ></button>
            </div>
          </div>
        </div>
      </div>

      <div class="d-flex justify-content-center w-100 mt-4">
        <button
          type="button"
          @click="confirmChange()"
          class="btn btn-save px-5 rounded-5"
        >
          HO SCELTO
        </button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, ref } from "vue";

import ProfileAvatar from "@/components/profile/ProfileAvatar.vue";
import { useUserInfoStore } from "@/store";
import ApiService from "@/core/services/ApiService";
import { useRoute, useRouter } from "vue-router";

import {
  primaryAvatarParts,
  secondaryAvatarParts,
  highAvatarParts,
  primaryAvatarPartsItalian, primaryAvatarPartsItalianKey,
  secondaryAvatarPartsItalian,
  highAvatarPartsItalian,
} from "@/core/data/parts";

export default defineComponent({
  name: "EditAvatar",
  components: {
    ProfileAvatar,
  },
  props: {
    startAvatar: Object,
  },
  setup(props) {
    const router = useRouter();
    const route = useRoute();

    const imageURI = process.env.VUE_APP_S3_PATH + "avatars/";

    const currentPart = ref("part1");
    const currentColor = ref("");

    const role = ref("");
    const avatar = ref("");

    const part1 = ref("");
    const part2 = ref("");
    const part3 = ref("");
    const part4 = ref("");
    const part5 = ref("");

    const userStore = useUserInfoStore();

    const getData = async () => {
      const user = userStore.currentUser;
      role.value = userStore.currentUserInfo.role;

      console.log(props.startAvatar);

      avatar.value = user?.avatar?.base ?? props.startAvatar?.avatar?.base;

      part1.value =
        user?.avatar?.parts.part1 ?? props.startAvatar?.avatar?.parts.part1;
      part2.value =
        user?.avatar?.parts.part2 ?? props.startAvatar?.avatar?.parts.part2;
      part3.value =
        user?.avatar?.parts.part3 ?? props.startAvatar?.avatar?.parts.part3;
      part4.value =
        user?.avatar?.parts.part4 ?? props.startAvatar?.avatar?.parts.part4;
      part5.value =
        user?.avatar?.parts.part5 ?? props.startAvatar?.avatar?.parts.part5;
    };

    getData();

    const changeColor = (color: string) => {
      currentColor.value = color;

      if (currentPart.value && currentPart.value != "") {
        if (currentPart.value == "part1") {
          part1.value = color;
        } else if (currentPart.value == "part2") {
          part2.value = color;
        } else if (currentPart.value == "part3") {
          part3.value = color;
        } else if (currentPart.value == "part4") {
          part4.value = color;
        } else if (currentPart.value == "part5") {
          part5.value = color;
        } else {
          return;
        }
      }
    };

    const resetAvatar = () => {
      console.log('reset avatar');
      getData();
    };

    const getURI = computed(() => {
      if (role.value == "primary-student") {
        return imageURI + "primary/" + avatar.value + "/";
      } else if (role.value == "secondary-student") {
        return imageURI + "secondary/" + avatar.value + "/";
      } else {
        return imageURI + "high/" + avatar.value + "/";
      }
    });

    function getParts() {
      const role = userStore.currentUserInfo.role;

      if (role == "primary-student") {
        return primaryAvatarParts;
      } else if (role == "secondary-student") {
        return secondaryAvatarParts;
      } else {
        return highAvatarParts;
      }
    }

    function getPartsName() {
      const role = userStore.currentUserInfo.role;

      if (role == "primary-student") {
        return primaryAvatarPartsItalian[avatar.value as primaryAvatarPartsItalianKey];
      } else if (role == "secondary-student") {
        return secondaryAvatarPartsItalian;
      } else {
        return highAvatarPartsItalian;
      }
    }

    const parts = getParts();

    const getPartName = computed(() => {
      switch (currentPart.value) {
        case "part1":
          return getPartsName()[0];
        case "part2":
          return getPartsName()[1];
        case "part3":
          return getPartsName()[2];
        case "part4":
          return getPartsName()[3];
        case "part5":
          return getPartsName()[4];
        default:
          return "";
      }
    });

    const colors = [
      "#94B0E8",
      "#FDE3E4",
      "#DEE0E2",
      "#ED4C42",
      "#F28F32",
      "#EDC742",
      "#4FAF04",
      "#567EEB",
      "#143FB6",
      "#6F6FD9",
      "#E88BD3",
      "#06133F",
    ];

    const confirmChange = async () => {
      let data = {
        data: {
          avatar: {
            base: avatar.value,
            parts: {
              part1: part1.value,
              part2: part2.value,
              part3: part3.value,
              part4: part4.value,
              part5: part5.value,
            },
          },
        },
      };

      await ApiService.put("/api/avatar", data).then(async () => {
        await userStore.verifyAuth();

        if (route.name == "chooseAvatar") {
          const confirmModal = document.getElementById("ConfirmAvatarButton");
          confirmModal?.click();
        } else {
          router.push("/profile");
        }
      });

      // if (route.name == "chooseColors") {
      //   const confirmModal = document.getElementById("ConfirmAvatarButton");
      //   confirmModal?.click();
      // } else {
      //   router.push("/profile");
      // }
    };

    return {
      avatar,
      part1,
      part2,
      part3,
      part4,
      part5,
      parts,
      colors,
      getURI,
      currentPart,
      currentColor,
      changeColor,
      confirmChange,
      props,
      getPartName,
      resetAvatar
    };
  },
});
</script>

<style lang="scss" scoped>
.img-avatar-container {
  height: 300px;
  width: 300px;
  background: #fcfcfc;
  border: 6px solid #a1e6e0;
  border-radius: 44.6877px;
  box-shadow: 0px 12px 42px 0px #7b87941f;
  box-shadow: 0px 8px 18px 0px #7b87941f;
}

.img-avatar {
  height: 80%;
  width: 80%;
}

.avatar-piece {
  height: 115px;
  width: 115px;
  background-color: #ebf0ff;
}

.avatar-piece.select {
  border: 3px solid $platform-bg !important;
}

@media screen and (max-width: 992px) {
  .avatar-piece {
    height: 140px;
    width: 100%;
  }

  .divisor-sm {
    border-top: 1px solid #e4e7eb;
  }
}

@media screen and (max-width: 576px) {
  .avatar-piece {
    height: 90px;
    width: 100%;
  }
}

.divisor {
  border-top: 1px solid #e4e7eb;
}

.colors-title {
  font-size: 1.2rem;
  font-weight: 600;
  line-height: 19px;
  letter-spacing: 0px;
  color: var(--bs-blue);
}
.avatar-piece-color {
  height: 2rem;
  width: 2rem;
}

.avatar-piece-color.select {
  border: 3px solid $platform-bg;
}

.btn-save {
  padding: 20px 25px;
  background: $platform-bg;
  box-shadow: 0px 1px 8px rgba(123, 135, 148, 0.12),
    0px 8px 16px rgba(123, 135, 148, 0.08);
  font-weight: 600;
  font-size: 1.2rem;
  line-height: 120%;
  text-align: center;
  letter-spacing: 0.5px;
  color: $platform-text-color-2;
}

.btn-reset {
  font-weight: 600;
  font-size: 14px;
  line-height: 120%;
  text-align: center;
  letter-spacing: 0.5px;
  color: $platform-text-color-2;
  text-decoration:underline;
}

.course-header-high-link {
  font-size: 18px;
  font-weight: 600;
  line-height: 19px;
  letter-spacing: 0px;
  text-align: center;
  background-color: rgba(234, 236, 254, 0.5);
  color: $platform-text-color-2;
  padding-top: 16px;
  border-bottom: 3px $platform-bg solid;
}
</style>
