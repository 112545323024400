<template>
  <!-- Toggle -->
  <button
    id="ConfirmAvatarButton"
    type="button"
    class="d-none"
    data-bs-toggle="modal"
    data-bs-target="#ConfirmAvatar"
  ></button>
  <!-- Modal -->
  <div
    class="modal fade"
    id="ConfirmAvatar"
    tabindex="-1"
    aria-labelledby="ConfirmAvatarLabel"
    aria-hidden="true"
  >
    <div
      class="modal-dialog modal-dialog-slideout modal-lg modal-dialog-centered"
    >
      <div class="modal-content m-4 m-lg-0">
        <div class="row flex-column-reverse flex-lg-row p-4 p-lg-5">
          <div
            class="col-12 col-lg-6 text-center text-lg-start d-flex flex-column justify-content-between mt-3 mt-lg-0"
          >
            <h2 class="text-primary modal-title">Vai così!</h2>
            <p class="fs-4 modal-p mt-3">
              Stai andando alla grande, ecco per te un altro distintivo. Ti
              incuriosisce vedere quanti ne puoi conquistare? <br /><br />
              Vai avanti e scoprilo!
            </p>
            <button
              type="button"
              class="btn btn-platform mt-3"
              data-bs-toggle="modal"
              data-bs-target="#StartEasycodeModal"
            >
              VAI AVANTI
            </button>
          </div>
          <div class="col-12 col-lg-6 d-flex justify-content-center">
            <img
              class="img-fluid"
              src="@/assets/images/frame/reward1.svg"
              alt="floppy"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "ConfirmAvatarModal",
});
</script>

<style lang="scss" scoped>
.modal-title {
  font-weight: 600;
  font-size: 32px;
  line-height: 120%;
  letter-spacing: 0.01em;
}
.modal-p {
  font-weight: 500;
  line-height: 120%;
  letter-spacing: 0.01em;
}
</style>
