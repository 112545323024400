<template>
  <div class="row">
    <div class="col-12 d-none d-lg-flex justify-content-end position-relative">
      <img src="@/assets/images/logos/logo.svg" alt="" />
      <router-link
        to="/start"
        class="btn turn-back d-flex align-items-center px-3"
      >
        <img src="@/assets/images/icons/Back.svg" class="me-2" alt="" />
        <span class="turn-back-text">INDIETRO</span>
      </router-link>
    </div>
    <div class="col-12 d-flex justify-content-center d-lg-none">
      <img src="@/assets/images/logos/logo45x45.svg" alt="" />
      <router-link to="/start" class="btn turn-back d-flex align-items-center">
        <img src="@/assets/images/icons/Back.svg" alt="" />
      </router-link>
    </div>
  </div>
  <div
    class="row d-flex flex-column align-items-center justify-content-center pt-5 mt-lg-5"
    style="z-index: 1"
  >
    <div class="col-12 col-lg-8">
      <h1 class="title text-center text-primary my-4">Scegli il tuo avatar!</h1>
      <p class="subtitle text-center m-0 px-4">
        {{
          user.nickname + ", scegli l’avatar. Dopo potrai anche cambiarlo se vuoi."
        }}
      </p>
    </div>
    <div
      class="col-12 col-lg-10 d-flex justify-content-center my-4 my-lg-5 p-0"
    >
      <div v-if="width <= 768" class="glide">
        <div data-glide-el="track" class="glide__track">
          <ul class="glide__slides py-5">
            <li class="glide__slide">
              <button @click="changeAvatar(0)" class="btn robot-wrapper">
                <img
                  src="@/assets/images/avatars/start/High1.svg"
                  class="robot"
                  alt=""
                /><img
                  class="avatar-select-icon"
                  src="@/assets/images/icons/Selected.svg"
                  alt=""
                />
              </button>
            </li>
            <li class="glide__slide">
              <button @click="changeAvatar(1)" class="btn robot-wrapper">
                <img
                  src="@/assets/images/avatars/start/High2.svg"
                  class="robot"
                  alt=""
                /><img
                  class="avatar-select-icon"
                  src="@/assets/images/icons/Selected.svg"
                  alt=""
                />
              </button>
            </li>
          </ul>
        </div>
      </div>
      <div v-else class="row w-100 d-flex justify-content-evenly my-4">
        <div class="col-4 d-flex justify-content-center">
          <button
            @click="changeAvatar(0)"
            class="btn robot-wrapper"
            :class="{ active: currentAvatar == 0 }"
          >
            <img
              src="@/assets/images/avatars/start/High1.svg"
              class="robot"
              alt=""
            /><img
              class="avatar-select-icon"
              src="@/assets/images/icons/Selected.svg"
              alt=""
            />
          </button>
        </div>
        <div class="col-4 d-flex justify-content-center">
          <button
            @click="changeAvatar(1)"
            class="btn robot-wrapper"
            :class="{ active: currentAvatar == 1 }"
          >
            <img
              src="@/assets/images/avatars/start/High2.svg"
              class="robot"
              alt=""
            /><img
              class="avatar-select-icon"
              src="@/assets/images/icons/Selected.svg"
              alt=""
            />
          </button>
        </div>
      </div>
    </div>
    <div class="col-12 col-lg-4 d-flex justify-content-center px-4 px-lg-0">
      <button
        type="button"
        @click="confirmAvatar()"
        data-bs-toggle="modal"
        data-bs-target="#WelcomeModal2"
        class="btn btn-platform w-100 mt-4"
      >
        HO SCELTO!
      </button>
    </div>
  </div>
</template>

<script>
import { defineComponent, nextTick, onMounted, onUnmounted, ref } from "vue";

// eslint-disable-next-line
import Glide from "@glidejs/glide";

import { useUserInfoStore } from "@/store";

import { highAvatarsName } from "@/core/data/parts";

export default defineComponent({
  name: "ChooseAvatar",
  emits: ["chooseAvatar"],
  setup(props, { emit }) {
    const userStore = useUserInfoStore();

    const user = userStore.currentUser;

    const width = ref(window.innerWidth);

    const avatarBasicColors = [
      {
        part1: "#FFC3C3",
        part2: "#000000",
        part3: "#424242",
        part4: "#2C1B18",
        part5: "#0098D8",
      },
      {
        part1: "#FFC3C3",
        part2: "#000000",
        part3: "#424242",
        part4: "#6B4600",
        part5: "#03288E",
      },
    ];

    let currentAvatar = ref(0);

    const confirmAvatar = async () => {
      let selected = avatarBasicColors[currentAvatar.value];

      let base = null;

      base = highAvatarsName[currentAvatar.value];

      let data = {
        avatar: {
          base: base,
          parts: selected,
        },
      };

      emit("chooseAvatar", data);
    };

    let glide;

    onMounted(() => {
      nextTick(() => {
        window.addEventListener("resize", () => {
          width.value = window.innerWidth;
        });

        try {
            glide = new Glide(".glide", {
                type: "carousel",
                perView: 2,
                breakpoints: {
                    768: {
                    perView: 1,
                    },
                },
            }).mount();

            glide.on(["mount.after", "run"], () => {
                currentAvatar.value = glide.index;
            });
        } catch( exception ) {
            console.log("EXCEPTION");
        }
      });
    });

    onUnmounted(() => {
      window.removeEventListener("resize", () => {
        width.value = window.innerWidth;
      });
    });

    const changeAvatar = (newIndex) => {
      if (newIndex == currentAvatar.value) {
        return;
      }

      currentAvatar.value = newIndex;
    };

    return {
      user,
      width,
      currentAvatar,
      confirmAvatar,
      changeAvatar,
    };
  },
});
</script>

<style lang="scss" scoped>
.glide {
  cursor: pointer;
}
.glide__slides {
  display: flex;
  align-items: center;
}

.glide__slide {
  display: flex;
  justify-content: center;
  align-items: center;
}
.robot-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 280px !important;
  width: 280px !important;
  background: white;
  border-radius: 24px;
  box-shadow: 0px 8px 16px 0px #7b879414;
  box-shadow: 0px 1px 8px 0px #7b87941f;

  padding: 5%;

  position: relative;
}

.robot-wrapper.active {
  border: 6px solid #3ce8da;
}

.robot {
  height: 80%;
  width: 80%;
}

.form-control {
  background: #ffffff;
  border: 1px solid #cbd2d9;
  box-shadow: 0px 0px 4px rgba(123, 135, 148, 0.08),
    0px 2px 4px rgba(123, 135, 148, 0.1);
  border-radius: 49px;
}

.title {
  font-weight: 700;
  font-size: 2.5rem;
  line-height: 120%;
  text-align: center;
  letter-spacing: -0.019em;
}

.subtitle {
  font-weight: 500;
  font-size: 1.2rem;
  line-height: 120%;
  color: #242424;
}

.turn-back {
  position: absolute;
  top: 15px;
  left: 20px;
  // background: #fde3e4;
  // box-shadow: 0px 1px 8px rgba(123, 135, 148, 0.12),
  //   0px 8px 16px rgba(123, 135, 148, 0.08);
  // border-radius: 50px;
  padding: 8px 8px;
  margin: 0 !important;
}

.turn-back-text {
    border-radius: 50px;
    padding: 18px 24px 18px 24px;
    border: 3px solid rgba(60, 232, 218, 1);
    box-shadow: 0px 8px 16px 0px rgba(123, 135, 148, 0.08);
    box-shadow: 0px 1px 8px 0px rgba(123, 135, 148, 0.12);

    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0.5px;
    text-align: center;
    color: rgba(65, 45, 205, 1);
    text-transform: uppercase;
/*
  font-weight: 600;
  font-size: 1.2rem;
  line-height: 120%;
  text-align: justify;
  letter-spacing: 0.5px;
  color: var(--bs-blue);
*/
}

.avatar-select-icon {
  position: absolute;
  width: 80px;
  bottom: -50px;
  display: none;
}

.glide__slide--active .robot-wrapper {
  border: 6px solid #3ce8da;
}

.glide__slide--active .robot-wrapper .avatar-select-icon {
  display: block;
}

@media only screen and (min-width: 768px) {
  .title {
    font-size: 3rem;
  }

  .subtitle {
    font-size: 1.4rem;
  }
}
</style>
