<template>
  <div
    v-if="!loadingView"
    id="login"
    class="container-xxl position-relative px-lg-5"
  >
    <div class="row pt-3 py-lg-5 mt-lg-0">
      <div class="col-12 d-none d-lg-block">
        <img src="@/assets/images/logos/logo.svg" alt="" />
      </div>
      <div class="col-12 d-flex justify-content-center d-lg-none">
        <img src="@/assets/images/logos/logo_mobile.svg" alt="" />
      </div>
    </div>
    <div class="row d-flex justify-content-center mt-5 mt-lg-3">
      <div class="col-12 col-lg-8">
        <h1 class="text-center d-none d-lg-block text-primary fs-1 fw-bold">
          Entra in Easycode
        </h1>
        <p v-if="isDemo" class="subtitle d-none d-lg-block text-center m-0 px-4">
          Accedi con la tua mail e la password demo-easycode.
        </p>
        <p v-else class="subtitle d-none d-lg-block text-center m-0 px-4">
          Accedi con la mail e la password fornite dalla tua scuola.
        </p>
        <h1
          class="text-center d-block d-lg-none text-primary login-header-title"
        >
          Entra in Easycode
        </h1>
        <p v-if="isDemo" class="d-block d-lg-none text-center m-0 px-4 login-header-p">
          Accedi con la tua mail e la password demo-easycode.
        </p>
        <p v-else class="d-block d-lg-none text-center m-0 px-4 login-header-p">
          Accedi con la mail e la password fornite dalla tua scuola.
        </p>
        <div id="login_inputs" class="row d-flex justify-content-center mt-5">
          <div class="col-12 col-lg-8 px-4 px-lg-0">
            <div
              v-if="error && error?.message"
              class="login-error-wrapper mb-2 p-1 d-flex align-items-center"
            >
              <p class="login-error-text p-0 m-0">{{ error?.message }}</p>
            </div>
            <form @submit.prevent="login">
              <div class="mb-3 position-relative">
                <label for="InputEmail1" class="form-label ms-3">Email</label>
                <input
                  type="email"
                  v-model="email"
                  required
                  class="form-control px-5"
                  :class="[
                    email != '' ? 'active' : '',
                    error && error?.message ? 'is-invalid' : '',
                  ]"
                  id="InputEmail1"
                  placeholder="Inserisci la mail"
                  aria-describedby="emailHelp"
                />
                <img
                  class="position-absolute"
                  style="top: 45px; left: 20px"
                  src="@/assets/images/icons/Mail.svg"
                  alt=""
                />
              </div>
              <div class="mb-3 position-relative">
                <label for="InputPassword1" class="form-label ms-3"
                  >Password</label
                >
                <input
                  :type="showPassword ? 'text' : 'password'"
                  v-model="password"
                  required
                  class="form-control px-5"
                  :class="[
                    password != '' ? 'active' : '',
                    error && error?.message ? 'is-invalid' : '',
                  ]"
                  placeholder="Inserisci la password"
                  id="InputPassword1"
                />
                <img
                  class="position-absolute"
                  style="top: 45px; left: 20px"
                  src="@/assets/images/icons/Kye.svg"
                  alt=""
                />
              </div>
              <div class="mb-3 form-check">
                <input
                  type="checkbox"
                  class="form-check-input"
                  v-model="showPassword"
                  id="Check1"
                />
                <label class="form-check-label" for="Check1"
                  >Mostra Password</label
                >
              </div>

              <div v-show="isDemo">
                <p class="privacy-text mt-5">Ai sensi e per gli effetti degli artt. 6, 7, 12, 13 del Reg. 
                UE 2016/679 – GDPR, cliccando su “Accedi”, dichiaro di aver preso visione dell'
                <a href="https://easycode.org/assets/docs/easycode_Luna_Labs_Privacy_Policy_Website.pdf" target="_blank">informativa per il trattamento dei dati personali</a>.
              </p>
              <div class="mt-4 form-check" v-if="isDemo">
                <input
                  required
                  type="checkbox"
                  class="form-check-input"
                  v-model="newsletter"
                  id="Check2"
                />
                <label class="accept-newsletter"
                  >Presto il mio consenso per l’invio di comunicazioni promozionali (compresa la newsletter) da parte del sito a mezzo email e riferita a prodotti propri</label
                >
              </div>
              </div>
              <button
                ref="submitButton"
                type="submit"
                class="form-submit-button btn w-100 mt-4"
                :disabled="email == '' || password == ''"
              >
                ACCEDI
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
  <LoadingView v-else />
</template>

<script lang="ts">
import LoadingView from "@/components/LoadingView.vue";
import { defineComponent, ref, computed } from "vue";
import { useUserInfoStore } from "@/store";

export default defineComponent({
  name: "LoginView",
  components: {
    LoadingView,
  },
  setup() {
    console.log("LOGIN VIEW");
    var loadingView = ref(false);
    const store = useUserInfoStore();

    const email = ref("");
    const password = ref("");
    const showPassword = ref(false);
    const newsletter = ref(false);

    const submitButton = ref<HTMLButtonElement | null>(null);

    const error: any = computed(() => store.getErrors);

    const login = async () => {
      // eslint-disable-next-line
      submitButton.value!.disabled = true;

      let result = await store.login(email.value, password.value, newsletter.value);

      if (error.value) {
        password.value = "";
      }
      // eslint-disable-next-line
      submitButton.value!.disabled = false;

      if (result) {
        loadingView.value = true;
      }
    };

    const isDemo = window.location.href.indexOf("demo") > -1;

    return {
      loadingView,
      login,
      email,
      password,
      showPassword,
      newsletter,
      submitButton,
      error,
      isDemo
    };
  },
});
</script>

<style lang="scss" scoped>
.subtitle {
  align-content: center;
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0px;
  color: #242424;
}

.login-header-title {
  font-weight: 700;
  font-size: 40px;
  line-height: 120%;
  letter-spacing: 0.01em;
}

.login-header-p {
  font-weight: 500;
  font-size: 18px;
  line-height: 120%;
  color: #242424;
}

.form-control {
  border: 1px solid var(--bs-blue) !important;
}

.form-control.active {
  border: 3px solid $platform-bg !important;
  border-radius: 49px !important;
}

.form-control:focus-visible {
  border: 3px solid $platform-bg !important;
  box-shadow: 0px 0px 0px 4px rgba(3, 40, 142, 0.3) !important;
  border-radius: 49px !important;
}

.form-control.is-invalid {
  border: 3px solid #ff5252 !important;
  box-shadow: 0px 0px 0px 4px #e8533a !important;
  border-radius: 49px !important;
}

.form-submit-button {
  color: $platform-text-color-2;
  background-color: $platform-bg;
  border-radius: 50px;
  padding: 20px 24px 20px 24px;
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.5px;
  text-align: center;
}

.form-submit-button:disabled {
  background-color: #e4e7eb;
  color: #9aa5b1;
}

.login-error-wrapper {
  background: #fde3e4;
  border-radius: 0px 5px 5px 0px;
  border-left: 3px solid #ff5252 !important;
  border-radius: 5px 5px 5px 5px;
}

.login-error-text {
  font-weight: 500;
  font-size: 16px;
  line-height: 120%;
  color: #1f2933;
}

.privacy-text {
  font-size: 12px;
  text-justify: inter-word;
  }

.accept-newsletter {
  font-size: 14px;
  text-justify: inter-word;
}
</style>
