import { defineStore } from "pinia";
import router from "@/router";
import ApiService from "@/core/services/ApiService";

import { primarySteps, secondarySteps, highSteps } from "@/core/data/steps";

import User from "@/core/data/user";
import UserInfo from "@/core/data/user-info";
import Notice from "@/core/data/notice";
import Lesson from "@/core/data/lesson";

export interface UserAuthInfo {
  errors: unknown;
  token: string;
  user: User;
  userInfo: UserInfo;
  isAuthenticated: boolean;
}

export const useUserInfoStore = defineStore("userInfo", {
  state: () => ({ userInfo: {} as UserAuthInfo }),
  getters: {
    currentUser: (state) => {
      return state.userInfo.user;
    },

    currentUserInfo: (state) => {
      return state.userInfo.userInfo;
    },

    getUserSteps(state) {
        if (state.userInfo.userInfo.role == "primary-student") {
          return primarySteps;
        } else if (state.userInfo.userInfo.role == "secondary-student") {
          return secondarySteps;
        } else {
          return highSteps;
        }
      },
  
      getNormalizedImageStep(state) {
        return (level: number) => {
            let s;
            if (state.userInfo.userInfo.role == "primary-student") {
                s = primarySteps;
            } else if (state.userInfo.userInfo.role == "secondary-student") {
                s = secondarySteps;
            } else {
                s = highSteps;
            }

            for(const step of s) {
                if( step.points == level ) {
                    return step.imageUnlock.substring(process.env.VUE_APP_S3_PATH.length);
                }
            }
            return "";
        }
      },
  
        currentLevel: (state) => {
      let currentLevel;
      let steps;

      if (state.userInfo.userInfo.role == "primary-student") {
        steps = primarySteps;
      } else if (state.userInfo.userInfo.role == "secondary-student") {
        steps = secondarySteps;
      } else {
        steps = highSteps;
      }

      for (let i = 1; i < steps.length; i++) {
        if (state.userInfo.user.level < steps[i].points) {
          currentLevel = steps[i-1];
          break;
        }
      }

      if (!currentLevel) {
        currentLevel = steps[0];
      }

      return currentLevel;
    },

    isUserAuthenticated: (state) => {
      return state.userInfo.isAuthenticated;
    },

    getRole: (state) => {
      return state.userInfo.userInfo.role;
    },

    getErrors: (state) => {
      return state.userInfo.errors;
    },
  },
  actions: {
    setError(error: object) {
      this.userInfo.errors = { ...error };
    },

    setAuth(user: User, info: UserInfo, token: string) {
      this.userInfo.errors = {};
      this.userInfo.user = user;
      this.userInfo.userInfo = info;
      this.userInfo.token = token;
      this.userInfo.isAuthenticated = true;

      localStorage.setItem("token", token);
    },

    purgeAuth() {
      this.userInfo.errors = {};
      this.userInfo.user = {} as User;
      this.userInfo.isAuthenticated = false;
      this.userInfo.token = "";

      localStorage.setItem("token", this.userInfo.token);

      router.push("/login");
    },

    async login(account: string, credentials: string, newsletter: boolean) {
      let success = false;
      await ApiService.post("/api/login", {
        email: account,
        password: credentials,
        newsletter: newsletter
      })
        .then(({ data }) => {
          if (data) {
            this.setAuth(data.data.user, data.data.user, data.data.token);
            success = true;
          }
        })
        .catch(({ response }) => {
          if (response) {
            this.setError(response.data.errors);
          }
        });

      return success;
    },

    async logout() {
      await ApiService.post("/api/logout", {}).then(() => {
        this.purgeAuth();
      });
    },

    async verifyAuth() {
      await ApiService.get("/api/info").then(async (response) => {
        const userInfo = response.data.data.user;

        await ApiService.get("/api/profile").then(async (response) => {
          const profile = response.data.data.profile;

          await ApiService.get("/api/avatar").then((response) => {
            profile.avatar = response.data.data.avatar;
            const token = localStorage.getItem("token") ?? "";

            this.setAuth(profile, userInfo, token);
          });
        });
      });
    },
  },
});

export const useLayoutStore = defineStore("layout", {
  state: () => ({ aside: true, notifications: [] as Notice[] }),
  getters: {
    getAside: (state) => state.aside,
    getNotifications: (state) => state.notifications,
    getWidth: () => window.innerWidth,
  },
  actions: {
    toggleAside() {
      this.aside = !this.aside;
    },
    async getUserNotifications() {
      await ApiService.get("api/notifications").then((response) => {
        this.notifications = response.data.data;
      });
    },
  },
});

export const useLessonsStore = defineStore("lessons", {
  state: () => ({ currentLesson: null as Lesson | null}),
  getters: {
    getLesson: (state) => state.currentLesson
  },
  actions: {
    setLesson(l: Lesson | null) {
      this.currentLesson = l;
    },
  },
});
