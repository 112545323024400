<template>
  <div id="chooseAvatar" class="container-xl px-lg-5 py-5">
    <ChooseAvatar @chooseAvatar="chooseAvatar" v-if="!changeView && userStore.currentUserInfo.role != 'high-student'" />
    <ChooseAvatarHigh @chooseAvatar="chooseAvatar" v-else-if="!changeView && userStore.currentUserInfo.role == 'high-student'" />
    <ChooseColors :avatar="avatar" @returnBack="returnBack" v-else />

    <WelcomeModal2 />
    <ConfirmAvatarModal />
    <StartEasycodeModal :name="userStore.currentUser.nickname" />
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";

import router from "@/router";

import { useUserInfoStore } from "@/store";

import ChooseAvatar from "@/components/start/ChooseAvatar.vue";
import ChooseAvatarHigh from "@/components/start/ChooseAvatarHigh.vue";
import ChooseColors from "@/components/start/ChooseColors.vue"

import WelcomeModal2 from "@/components/modals/start/WelcomeModal2.vue";
import ConfirmAvatarModal from "@/components/modals/start/ConfirmAvatarModal.vue";
import StartEasycodeModal from "@/components/modals/start/StartEasycodeModal.vue";

export default defineComponent({
  name: "Avatar",
  components: {
    ChooseAvatar,
    ChooseAvatarHigh,
    ChooseColors,
    ConfirmAvatarModal,
    StartEasycodeModal,
    WelcomeModal2
},
  setup() {
    console.log("AVATAR VIEW");
    const userStore = useUserInfoStore();
    const user = userStore.currentUser

    if(user.avatar) {
      router.push("/start")
    }

    const changeView = ref(false);
    const avatar = ref({});

    const chooseAvatar = (data: any) => {

      avatar.value = data
      changeView.value = true

    }

    const returnBack = () => {
      avatar.value = {}
      changeView.value = false
    }

    return {
      userStore,
      changeView,
      avatar,
      chooseAvatar,
      returnBack,
    }
  }
});
</script>
