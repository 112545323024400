const primaryAvatarsName = [
  "robot-1",
  "robot-2",
  "robot-3",
  "robot-4",
  "robot-5",
];

const secondaryAvatarsName = [
  "guy-green-violet",
  "gal-band",
  "guy-band",
  "bot-blue",
  "bot-salmon",
];

const highAvatarsName = ["boys", "girls"];

const primaryAvatarParts = ["hair", "head", "body", "hands", "dot"];

const primaryAvatarPartsItalian = {
    "robot-1": [
        "Mascherino",
        "Faccia",
        "Corpo",
        "Braccia",
        "Piedi"
    ],
    "robot-2": [
        "Capelli",
        "Faccia",
        "Corpo",
        "Braccia",
        "Piedi"
    ],
    "robot-3": [
        "Capelli",
        "Faccia",
        "Corpo",
        "Braccia",
        "Cappello"
    ],
    "robot-4": [
        "Mascherino",
        "Faccia",
        "Corpo",
        "Braccia",
        "Scudo"
    ],
    "robot-5": [
        "Orecchie",
        "Faccia",
        "Corpo",
        "Braccia",
        "Capelli"
    ]    
};
type primaryAvatarPartsItalianKey = keyof typeof primaryAvatarPartsItalian;

const secondaryAvatarParts = ["mask", "face", "body", "hands", "feet"];

const secondaryAvatarPartsItalian = [
  "Capelli/Mascherino",
  "Viso",
  "Corpo",
  "Braccia",
  "Piedi",
];

const highAvatarParts = ["body", "eyes", "eye-brow", "hair", "tshirt"];

const highAvatarPartsItalian = ["Corpo", "Occhi", "Sopracciglia", "Capelli", "T-shirt"];

export {
  primaryAvatarsName,
  secondaryAvatarsName,
  highAvatarsName,
  primaryAvatarParts,
  secondaryAvatarParts,
  highAvatarParts,
  primaryAvatarPartsItalian, primaryAvatarPartsItalianKey,
  secondaryAvatarPartsItalian,
  highAvatarPartsItalian
};
