<template>
  <svg
    width="319"
    height="420"
    viewBox="0 0 319 420"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="RAGAZZA BENDA">
      <g id="Gamba dx">
        <g id="polpaccio dx" filter="url(#filter0_i_2963_677)">
          <path
            d="M115.55 344.342C116.65 336.121 124.207 330.347 132.428 331.447C140.65 332.547 146.423 340.104 145.323 348.325L140.336 385.607C139.236 393.829 131.679 399.602 123.458 398.502C115.236 397.402 109.463 389.846 110.563 381.624L115.55 344.342Z"
            fill="url(#paint0_linear_2963_677)"
          />
        </g>
        <g id="Scarpa dx" filter="url(#filter1_i_2963_677)">
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M132.249 386C128.957 381.742 123.799 379 118 379L101 379C91.0589 379 83 387.059 83 397C83 406.941 91.0589 415 101 415L113 415L118 415C118.004 415 118.008 415 118.012 415H141C143.209 415 145 413.209 145 411V396C145 390.477 140.523 386 135 386H132.249Z"
            fill="url(#paint1_radial_2963_677)"
          />
        </g>
        <g id="Suola dx" filter="url(#filter2_i_2963_677)">
          <path
            d="M141.5 409C144.538 409 147 411.463 147 414.5C147 417.538 144.538 420 141.5 420L88.5 420C85.4624 420 83 417.538 83 414.5C83 411.463 85.4624 409 88.5 409L141.5 409Z"
            fill="url(#paint2_radial_2963_677)"
          />
        </g>
        <g id="Coscia dx" filter="url(#filter3_i_2963_677)">
          <path
            d="M114.164 323.715C115.492 313.789 124.615 306.819 134.54 308.147C144.466 309.475 151.436 318.598 150.108 328.524L145.921 359.823L109.977 355.014L114.164 323.715Z"
            fill="#DD22A4"
          />
        </g>
      </g>
      <g id="Gamba sx">
        <g id="polpaccio sx" filter="url(#filter4_i_2963_677)">
          <path
            d="M211.962 344.342C210.862 336.121 203.306 330.347 195.084 331.447C186.862 332.547 181.089 340.104 182.189 348.325L187.177 385.607C188.277 393.829 195.833 399.602 204.055 398.502C212.276 397.402 218.05 389.846 216.95 381.624L211.962 344.342Z"
            fill="url(#paint3_linear_2963_677)"
          />
        </g>
        <g id="Scarpa sx" filter="url(#filter5_i_2963_677)">
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M195.264 386C198.556 381.742 203.714 379 209.513 379L226.513 379C236.454 379 244.513 387.059 244.513 397C244.513 406.941 236.454 415 226.513 415L214 415L209.513 415C209.509 415 209.505 415 209.501 415H186C183.791 415 182 413.209 182 411V396C182 390.477 186.477 386 192 386H195.264Z"
            fill="url(#paint4_radial_2963_677)"
          />
        </g>
        <g id="Suola sx" filter="url(#filter6_i_2963_677)">
          <path
            d="M186.013 409C182.975 409 180.513 411.463 180.513 414.5C180.513 417.538 182.975 420 186.013 420L239.013 420C242.05 420 244.513 417.538 244.513 414.5C244.513 411.463 242.05 409 239.013 409L186.013 409Z"
            fill="url(#paint5_radial_2963_677)"
          />
        </g>
        <g id="Coscia sx" filter="url(#filter7_i_2963_677)">
          <path
            d="M213.349 323.715C212.021 313.789 202.898 306.819 192.972 308.147C183.046 309.475 176.077 318.598 177.404 328.524L181.592 359.823L217.536 355.014L213.349 323.715Z"
            fill="#DD22A4"
          />
        </g>
      </g>
      <g id="Body">
        <path
          id="body-sub"
          d="M95 255C95 238.431 108.431 225 125 225H206C222.569 225 236 238.431 236 255V275C236 308.137 209.137 335 176 335H155C121.863 335 95 308.137 95 275V255Z"
          :fill="body"
        />
        <g id="bottom">
          <path
            d="M103.027 305C113.401 322.934 132.791 335 155 335H176C198.208 335 217.599 322.934 227.973 305H103.027Z"
            :fill="body"
          />
        </g>
      </g>
      <g id="Tondo grigio">
        <path
          id="Tondo sub"
          d="M191 276C191 289.807 179.807 301 166 301C152.193 301 141 289.807 141 276C141 262.193 152.193 251 166 251C179.807 251 191 262.193 191 276Z"
          fill="#D9D9D9"
        />
        <g id="Tondo top" filter="url(#filter9_i_2963_677)">
          <path
            d="M186.605 276C186.605 287.38 177.38 296.604 166 296.604C154.621 296.604 145.396 287.38 145.396 276C145.396 264.621 154.621 255.396 166 255.396C177.38 255.396 186.605 264.621 186.605 276Z"
            fill="#C2C2C2"
          />
        </g>
      </g>
      <g id="Spalle" filter="url(#filter10_i_2963_677)">
        <path
          d="M257 215C264.18 215 270 220.82 270 228V231C270 238.18 264.18 244 257 244L69 244C61.8203 244 56 238.18 56 231L56 228C56 220.82 61.8203 215 69 215L257 215Z"
          fill="#94146D"
        />
      </g>
      <path
        id="Collo"
        d="M167.5 217C173.851 217 179 222.149 179 228.5C179 234.851 173.851 240 167.5 240H162.5C156.149 240 151 234.851 151 228.5C151 222.149 156.149 217 162.5 217L167.5 217Z"
        fill="url(#paint6_radial_2963_677)"
      />
      <g id="Orecchio dx" filter="url(#filter11_i_2963_677)">
        <path
          d="M91.2088 161.604C91.2088 172.984 81.9839 182.209 70.6044 182.209C59.2249 182.209 50 172.984 50 161.604C50 150.225 59.2249 141 70.6044 141C81.9839 141 91.2088 150.225 91.2088 161.604Z"
          fill="#DD22A4"
        />
      </g>
      <g id="Orecchio sx" filter="url(#filter12_i_2963_677)">
        <path
          d="M279.209 161.604C279.209 172.984 269.984 182.209 258.604 182.209C247.225 182.209 238 172.984 238 161.604C238 150.225 247.225 141 258.604 141C269.984 141 279.209 150.225 279.209 161.604Z"
          fill="#DD22A4"
        />
      </g>
      <g id="Viso" filter="url(#filter13_di_2963_677)">
        <path
          d="M66 157.5C66 117.459 98.4594 85 138.5 85H189.5C229.541 85 262 117.459 262 157.5C262 197.541 229.541 230 189.5 230H138.5C98.4594 230 66 197.541 66 157.5Z"
          fill="url(#paint7_radial_2963_677)"
        />
      </g>
      <path
        id="Capelli"
        d="M113.928 61.1239C112.63 61.0417 111.32 61 110 61C78.5198 61 53 84.7289 53 114C53 143.271 78.5198 167 110 167C141.48 167 167 143.271 167 114H272.779C266.327 83.5982 239.33 60.7885 207.005 60.7885H120.687C118.406 60.7885 116.151 60.9021 113.928 61.1239Z"
        :fill="feet"
      />
      <g id="Occhio dx">
        <path
          id="Vector"
          d="M131.912 192.241C151.001 190.359 163.428 169.923 160.961 157.621C158.185 143.781 144.337 130.561 123.222 131.597C79.8747 133.723 82.8286 150.896 94.2211 171.806C101.555 185.266 116.225 193.788 131.912 192.241Z"
          fill="#3E4C59"
        />
        <path
          id="Vector_2"
          d="M131.068 187.491C147.104 185.91 157.544 168.743 155.471 158.409C153.139 146.782 141.506 135.677 123.769 136.547C87.3545 138.333 89.8359 152.76 99.4062 170.325C105.567 181.632 117.89 188.791 131.068 187.491Z"
          fill="#7B8794"
        />
        <g id="Vector_3" filter="url(#filter14_i_2963_677)">
          <path
            d="M132.351 183.936C145.83 182.606 154.606 168.175 152.864 159.488C150.904 149.714 141.124 140.379 126.214 141.111C95.6032 142.612 97.6892 154.739 105.734 169.505C110.913 179.01 121.273 185.028 132.351 183.936Z"
            fill="white"
          />
        </g>
        <g id="Vector_4" filter="url(#filter15_di_2963_677)">
          <path
            d="M134.207 174.706C142.304 173.082 147.568 165.286 145.964 157.293C144.361 149.299 136.75 145.391 128.653 147.015C120.556 148.639 115.041 155.18 116.644 163.173C118.247 171.167 126.11 176.33 134.207 174.706Z"
            fill="#282828"
          />
        </g>
      </g>
      <g id="Benda">
        <g id="Rectangle 524" filter="url(#filter16_i_2963_677)">
          <path
            d="M50 138C50 130.268 56.6586 124 64.8723 124H268.128C276.341 124 283 130.268 283 138C283 145.732 276.341 152 268.128 152H64.8723C56.6586 152 50 145.732 50 138Z"
            :fill="mask"
          />
        </g>
        <g
          id="Rectangle 525"
          style="mix-blend-mode: screen"
          filter="url(#filter17_i_2963_677)"
        >
          <path
            d="M63 142.5C63 140.567 64.5185 139 66.3918 139H247.608C249.481 139 251 140.567 251 142.5C251 144.433 249.481 146 247.608 146H66.3918C64.5185 146 63 144.433 63 142.5Z"
            fill="#616E7C"
          />
        </g>
        <path
          id="Occhio sx"
          d="M222 125C239.121 125 253 138.431 253 155C253 171.569 239.121 185 222 185H197C179.879 185 166 171.569 166 155C166 138.431 179.879 125 197 125L222 125Z"
          :fill="mask"
        />
      </g>
      <path
        id="Ciuffo"
        d="M316.346 86.3677C312.336 93.1064 306.707 99.021 299.605 103.477C275.727 118.46 244.242 111.278 229.283 87.4366C224.533 79.8662 222.02 71.5263 221.567 63.1892C220.253 63.6557 218.939 64.1464 217.626 64.6613C174.442 81.597 148.492 118.421 159.665 146.911C170.837 175.4 214.902 184.767 258.087 167.831C300.874 151.051 326.743 114.745 316.346 86.3677Z"
        :fill="feet"
      />
      <path
        id="Coda"
        d="M11.6557 103.275C11.6849 97.4934 13.0506 91.6301 15.8975 86.1431C25.4699 67.6937 48.163 60.4855 66.584 70.0431C72.4333 73.078 77.1484 77.4384 80.5535 82.5671C81.214 81.7794 81.8655 80.9763 82.5074 80.1581C103.618 53.2491 106.341 20.1448 88.5883 6.21757C70.8358 -7.7097 39.3309 2.81405 18.2201 29.723C-2.69691 56.385 -5.56162 89.1292 11.6557 103.275Z"
        :fill="feet"
      />
      <g id="Bocca" filter="url(#filter18_i_2963_677)">
        <path
          d="M152 194.5C152 192.567 153.567 191 155.5 191H175.5C177.433 191 179 192.567 179 194.5C179 196.433 177.433 198 175.5 198H155.5C153.567 198 152 196.433 152 194.5Z"
          fill="#C7033E"
        />
      </g>
      <g id="Braccio sx">
        <g id="Rectangle 521" filter="url(#filter19_i_2963_677)">
          <path
            d="M254.051 302.54C251.009 310.862 241.798 315.142 233.476 312.101C225.155 309.059 220.874 299.848 223.916 291.526L231.702 270.221C234.743 261.9 243.955 257.619 252.276 260.661C260.598 263.702 264.878 272.914 261.837 281.235L254.051 302.54Z"
            fill="url(#paint13_radial_2963_677)"
          />
        </g>
        <g id="Mano sx">
          <path
            id="Rectangle 524_2"
            d="M223.671 327.136C224.423 324.721 226.99 323.371 229.406 324.123C231.821 324.874 233.171 327.441 232.419 329.857L227.755 344.854C227.004 347.269 224.437 348.619 222.021 347.867C219.605 347.116 218.256 344.549 219.007 342.133L223.671 327.136Z"
            fill="url(#paint14_radial_2963_677)"
          />
          <path
            id="Rectangle 529"
            d="M232.121 324.595C232.36 322.077 234.595 320.228 237.113 320.467C239.632 320.706 241.48 322.941 241.241 325.46L239.759 341.095C239.521 343.613 237.285 345.462 234.767 345.223C232.248 344.984 230.4 342.749 230.639 340.23L232.121 324.595Z"
            fill="url(#paint15_radial_2963_677)"
          />
          <path
            id="Rectangle 527"
            d="M215.503 298.923C217.623 297.542 220.46 298.142 221.841 300.262C223.221 302.382 222.621 305.22 220.501 306.6L208.412 314.47C206.292 315.85 203.455 315.251 202.074 313.13C200.694 311.01 201.294 308.173 203.414 306.792L215.503 298.923Z"
            fill="url(#paint16_radial_2963_677)"
          />
          <path
            id="Rectangle 525_2"
            d="M217.795 321.791C219.137 319.646 221.963 318.994 224.108 320.336C226.253 321.677 226.904 324.503 225.563 326.648L216.542 341.074C215.2 343.219 212.374 343.87 210.229 342.529C208.084 341.187 207.433 338.361 208.774 336.216L217.795 321.791Z"
            fill="url(#paint17_radial_2963_677)"
          />
          <path
            id="Rectangle 526"
            d="M213.134 313.917C214.751 311.972 217.639 311.705 219.584 313.323C221.53 314.94 221.796 317.828 220.179 319.773L208.139 334.257C206.522 336.203 203.634 336.469 201.688 334.852C199.743 333.235 199.477 330.347 201.094 328.401L213.134 313.917Z"
            fill="url(#paint18_radial_2963_677)"
          />
          <g id="Rectangle 528">
            <path
              d="M242.218 324.202C238.692 330.512 230.718 332.768 224.409 329.241L218.697 326.049C212.387 322.522 210.131 314.549 213.657 308.239L216.85 302.527C220.376 296.217 228.35 293.961 234.66 297.488L240.372 300.68C246.681 304.207 248.937 312.181 245.411 318.49L242.218 324.202Z"
              :fill="hands"
            />
          </g>
          <g id="Rectangle 522">
            <path
              d="M255.011 306.791C253.172 310.695 248.516 312.369 244.612 310.529L222.775 300.241C218.872 298.402 217.198 293.746 219.037 289.842C220.876 285.938 225.532 284.264 229.436 286.104L251.273 296.392C255.177 298.231 256.851 302.887 255.011 306.791Z"
              :fill="hands"
            />
          </g>
        </g>
        <g id="Rectangle 520" filter="url(#filter22_i_2963_677)">
          <path
            d="M217.001 243.503C213.697 233.585 219.138 222.879 229.098 219.702C238.969 216.555 249.522 222.004 252.67 231.874L267.171 277.346L226.496 272.003L217.001 243.503Z"
            fill="url(#paint19_radial_2963_677)"
          />
        </g>
      </g>
      <g id="Braccio dx">
        <g id="Rectangle 521_2" filter="url(#filter23_i_2963_677)">
          <path
            d="M76.2934 303.537C79.3348 311.858 88.5462 316.139 96.8677 313.097C105.189 310.056 109.47 300.845 106.428 292.523L98.642 271.218C95.6006 262.897 86.3892 258.616 78.0676 261.658C69.7461 264.699 65.4656 273.91 68.507 282.232L76.2934 303.537Z"
            fill="url(#paint20_radial_2963_677)"
          />
        </g>
        <g id="Mano dx">
          <path
            id="Rectangle 524_3"
            d="M106.673 328.133C105.921 325.718 103.354 324.368 100.938 325.12C98.5227 325.871 97.1734 328.438 97.9247 330.854L102.589 345.851C103.34 348.266 105.907 349.616 108.323 348.864C110.739 348.113 112.088 345.546 111.337 343.13L106.673 328.133Z"
            fill="url(#paint21_radial_2963_677)"
          />
          <path
            id="Rectangle 529_2"
            d="M98.2233 325.592C97.9846 323.074 95.7494 321.225 93.2308 321.464C90.7123 321.703 88.8641 323.938 89.1028 326.457L90.5847 342.092C90.8234 344.61 93.0586 346.458 95.5772 346.22C98.0957 345.981 99.9439 343.746 99.7052 341.227L98.2233 325.592Z"
            fill="url(#paint22_radial_2963_677)"
          />
          <path
            id="Rectangle 527_2"
            d="M114.841 299.919C112.72 298.539 109.883 299.139 108.503 301.259C107.122 303.379 107.722 306.217 109.842 307.597L121.931 315.467C124.051 316.847 126.889 316.248 128.269 314.127C129.649 312.007 129.049 309.17 126.929 307.789L114.841 299.919Z"
            fill="url(#paint23_radial_2963_677)"
          />
          <path
            id="Rectangle 525_3"
            d="M112.549 322.788C111.207 320.643 108.381 319.991 106.236 321.333C104.091 322.674 103.44 325.5 104.781 327.645L113.802 342.071C115.144 344.216 117.97 344.867 120.115 343.526C122.26 342.184 122.911 339.358 121.57 337.213L112.549 322.788Z"
            fill="url(#paint24_radial_2963_677)"
          />
          <path
            id="Rectangle 526_2"
            d="M117.21 314.914C115.593 312.968 112.705 312.702 110.76 314.319C108.814 315.937 108.548 318.825 110.165 320.77L122.205 335.254C123.822 337.2 126.71 337.466 128.656 335.849C130.601 334.232 130.867 331.344 129.25 329.398L117.21 314.914Z"
            fill="url(#paint25_radial_2963_677)"
          />
          <g id="Rectangle 528_2">
            <path
              d="M88.1254 325.199C91.6518 331.509 99.6255 333.765 105.935 330.238L111.647 327.046C117.957 323.519 120.213 315.546 116.686 309.236L113.494 303.524C109.967 297.214 101.994 294.958 95.6841 298.485L89.9719 301.677C83.6624 305.204 81.4063 313.177 84.9328 319.487L88.1254 325.199Z"
              :fill="hands"
            />
          </g>
          <g id="Rectangle 522_2">
            <path
              d="M75.3326 307.788C77.1719 311.692 81.8278 313.366 85.7317 311.526L107.568 301.238C111.472 299.399 113.146 294.743 111.307 290.839C109.467 286.935 104.811 285.261 100.907 287.101L79.0709 297.389C75.1669 299.228 73.4932 303.884 75.3326 307.788Z"
              :fill="hands"
            />
          </g>
        </g>
        <g id="Rectangle 520_2" filter="url(#filter26_i_2963_677)">
          <path
            d="M113.343 244.5C116.647 234.582 111.205 223.876 101.245 220.7C91.3751 217.552 80.8219 223.001 77.6741 232.872L63.1723 278.343L103.848 273L113.343 244.5Z"
            fill="url(#paint26_radial_2963_677)"
          />
        </g>
      </g>
      <g id="Elastico" filter="url(#filter27_i_2963_677)">
        <path
          d="M108.264 56.9502C110.892 62.4041 108.602 68.9565 103.148 71.5853L74.4295 85.4276C68.9755 88.0564 62.4232 85.7662 59.7944 80.3122C57.1656 74.8583 59.4558 68.3059 64.9098 65.6771L93.6285 51.8348C99.0824 49.206 105.635 51.4962 108.264 56.9502Z"
          fill="#DD22A4"
        />
      </g>
    </g>
    <defs>
      <filter
        id="filter0_i_2963_677"
        x="110.428"
        y="331.313"
        width="35.0293"
        height="71.3237"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="5" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect1_innerShadow_2963_677"
        />
      </filter>
      <filter
        id="filter1_i_2963_677"
        x="83"
        y="379"
        width="62"
        height="41.0588"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="5.0588" />
        <feGaussianBlur stdDeviation="2.5294" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect1_innerShadow_2963_677"
        />
      </filter>
      <filter
        id="filter2_i_2963_677"
        x="79"
        y="409"
        width="68"
        height="15"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="-4" dy="4" />
        <feGaussianBlur stdDeviation="4.5" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.891667 0 0 0 0 0.891667 0 0 0 0 0.891667 0 0 0 1 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect1_innerShadow_2963_677"
        />
      </filter>
      <filter
        id="filter3_i_2963_677"
        x="105.977"
        y="307.985"
        width="44.2939"
        height="55.8379"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="-4" dy="4" />
        <feGaussianBlur stdDeviation="10" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.337405 0 0 0 0 0.0432639 0 0 0 0 0.598403 0 0 0 1 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect1_innerShadow_2963_677"
        />
      </filter>
      <filter
        id="filter4_i_2963_677"
        x="182.055"
        y="331.313"
        width="35.0293"
        height="71.3237"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="5" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect1_innerShadow_2963_677"
        />
      </filter>
      <filter
        id="filter5_i_2963_677"
        x="182"
        y="379"
        width="62.5127"
        height="41.0588"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="5.0588" />
        <feGaussianBlur stdDeviation="2.5294" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect1_innerShadow_2963_677"
        />
      </filter>
      <filter
        id="filter6_i_2963_677"
        x="176.513"
        y="409"
        width="68"
        height="15"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="-4" dy="4" />
        <feGaussianBlur stdDeviation="4.5" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.891667 0 0 0 0 0.891667 0 0 0 0 0.891667 0 0 0 1 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect1_innerShadow_2963_677"
        />
      </filter>
      <filter
        id="filter7_i_2963_677"
        x="173.242"
        y="307.985"
        width="44.2939"
        height="55.8379"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="-4" dy="4" />
        <feGaussianBlur stdDeviation="10" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.337405 0 0 0 0 0.0432639 0 0 0 0 0.598403 0 0 0 1 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect1_innerShadow_2963_677"
        />
      </filter>
      <filter
        id="filter8_i_2963_677"
        x="99.0269"
        y="305"
        width="128.946"
        height="34"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="-4" dy="4" />
        <feGaussianBlur stdDeviation="10" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.337405 0 0 0 0 0.0432639 0 0 0 0 0.598403 0 0 0 1 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect1_innerShadow_2963_677"
        />
      </filter>
      <filter
        id="filter9_i_2963_677"
        x="145.396"
        y="255.396"
        width="42.1297"
        height="42.1295"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="0.92073" dy="0.92073" />
        <feGaussianBlur stdDeviation="0.690547" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect1_innerShadow_2963_677"
        />
      </filter>
      <filter
        id="filter10_i_2963_677"
        x="52"
        y="215"
        width="218"
        height="33"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="-4" dy="4" />
        <feGaussianBlur stdDeviation="10" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.337405 0 0 0 0 0.0432639 0 0 0 0 0.598403 0 0 0 1 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect1_innerShadow_2963_677"
        />
      </filter>
      <filter
        id="filter11_i_2963_677"
        x="46"
        y="141"
        width="45.209"
        height="45.2087"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="-4" dy="4" />
        <feGaussianBlur stdDeviation="10" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.337405 0 0 0 0 0.0432639 0 0 0 0 0.598403 0 0 0 1 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect1_innerShadow_2963_677"
        />
      </filter>
      <filter
        id="filter12_i_2963_677"
        x="234"
        y="141"
        width="45.209"
        height="45.2087"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="-4" dy="4" />
        <feGaussianBlur stdDeviation="10" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.337405 0 0 0 0 0.0432639 0 0 0 0 0.598403 0 0 0 1 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect1_innerShadow_2963_677"
        />
      </filter>
      <filter
        id="filter13_di_2963_677"
        x="61"
        y="84"
        width="206"
        height="155"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="2.5" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_2963_677"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_2963_677"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1" />
        <feGaussianBlur stdDeviation="4.5" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect2_innerShadow_2963_677"
        />
      </filter>
      <filter
        id="filter14_i_2963_677"
        x="100.606"
        y="139.57"
        width="53.9784"
        height="44.4932"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="1.50041" dy="-1.50041" />
        <feGaussianBlur stdDeviation="1.50041" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect1_innerShadow_2963_677"
        />
      </filter>
      <filter
        id="filter15_di_2963_677"
        x="114.866"
        y="145.903"
        width="32.8866"
        height="32.1028"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="0.750207" />
        <feGaussianBlur stdDeviation="0.750207" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_2963_677"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_2963_677"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="3.00083" />
        <feGaussianBlur stdDeviation="1.50041" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.44 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect2_innerShadow_2963_677"
        />
      </filter>
      <filter
        id="filter16_i_2963_677"
        x="46"
        y="124"
        width="237"
        height="32"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="-4" dy="4" />
        <feGaussianBlur stdDeviation="12" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.121569 0 0 0 0 0.160784 0 0 0 0 0.2 0 0 0 1 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect1_innerShadow_2963_677"
        />
      </filter>
      <filter
        id="filter17_i_2963_677"
        x="63"
        y="139"
        width="188"
        height="11"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect1_innerShadow_2963_677"
        />
      </filter>
      <filter
        id="filter18_i_2963_677"
        x="152"
        y="189"
        width="27"
        height="9"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="-4" />
        <feGaussianBlur stdDeviation="1" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect1_innerShadow_2963_677"
        />
      </filter>
      <filter
        id="filter19_i_2963_677"
        x="222.937"
        y="259.682"
        width="39.8799"
        height="54.3982"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1" />
        <feGaussianBlur stdDeviation="4.5" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect1_innerShadow_2963_677"
        />
      </filter>
      <filter
        id="filter20_i_2963_677"
        x="206.757"
        y="295.822"
        width="40.32"
        height="40.3199"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="-5.23508" dy="5.23508" />
        <feGaussianBlur stdDeviation="13.0877" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.337405 0 0 0 0 0.0432639 0 0 0 0 0.598403 0 0 0 1 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect1_innerShadow_2963_677"
        />
      </filter>
      <filter
        id="filter21_i_2963_677"
        x="215.115"
        y="285.357"
        width="40.643"
        height="29.0947"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="-3.17474" dy="3.17474" />
        <feGaussianBlur stdDeviation="7.93686" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.337405 0 0 0 0 0.0432639 0 0 0 0 0.598403 0 0 0 1 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect1_innerShadow_2963_677"
        />
      </filter>
      <filter
        id="filter22_i_2963_677"
        x="212.034"
        y="218.811"
        width="55.1372"
        height="62.5353"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="-4" dy="4" />
        <feGaussianBlur stdDeviation="12" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.121569 0 0 0 0 0.160784 0 0 0 0 0.2 0 0 0 1 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect1_innerShadow_2963_677"
        />
      </filter>
      <filter
        id="filter23_i_2963_677"
        x="67.5278"
        y="260.678"
        width="39.8799"
        height="54.3982"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1" />
        <feGaussianBlur stdDeviation="4.5" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect1_innerShadow_2963_677"
        />
      </filter>
      <filter
        id="filter24_i_2963_677"
        x="78.032"
        y="296.819"
        width="40.32"
        height="40.3199"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="-5.23508" dy="5.23508" />
        <feGaussianBlur stdDeviation="13.0877" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.337405 0 0 0 0 0.0432639 0 0 0 0 0.598403 0 0 0 1 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect1_innerShadow_2963_677"
        />
      </filter>
      <filter
        id="filter25_i_2963_677"
        x="71.4107"
        y="286.354"
        width="40.643"
        height="29.0947"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="-3.17474" dy="3.17474" />
        <feGaussianBlur stdDeviation="7.93686" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.337405 0 0 0 0 0.0432639 0 0 0 0 0.598403 0 0 0 1 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect1_innerShadow_2963_677"
        />
      </filter>
      <filter
        id="filter26_i_2963_677"
        x="59.1724"
        y="219.808"
        width="55.1372"
        height="62.5353"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="-4" dy="4" />
        <feGaussianBlur stdDeviation="12" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.121569 0 0 0 0 0.160784 0 0 0 0 0.2 0 0 0 1 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect1_innerShadow_2963_677"
        />
      </filter>
      <filter
        id="filter27_i_2963_677"
        x="55.5298"
        y="50.745"
        width="53.8237"
        height="38.9472"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="-3.17474" dy="3.17474" />
        <feGaussianBlur stdDeviation="7.93686" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.337405 0 0 0 0 0.0432639 0 0 0 0 0.598403 0 0 0 1 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect1_innerShadow_2963_677"
        />
      </filter>
      <linearGradient
        id="paint0_linear_2963_677"
        x1="130.402"
        y1="342.408"
        x2="126.03"
        y2="375.087"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#FF9C65" />
        <stop offset="1" stop-color="#FFAD9B" />
      </linearGradient>
      <radialGradient
        id="paint1_radial_2963_677"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(109.015 394.353) rotate(87.2679) scale(20.6706 33.8576)"
      >
        <stop stop-color="#A59FB7" />
        <stop offset="1" stop-color="#615E6A" />
      </radialGradient>
      <radialGradient
        id="paint2_radial_2963_677"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(103.645 413.742) rotate(87.8052) scale(9.26542 33.5564)"
      >
        <stop stop-color="#F2F2F2" />
        <stop offset="1" stop-color="#A2A2A2" />
      </radialGradient>
      <linearGradient
        id="paint3_linear_2963_677"
        x1="197.111"
        y1="342.408"
        x2="201.483"
        y2="375.087"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#FF9C65" />
        <stop offset="1" stop-color="#FFAD9B" />
      </linearGradient>
      <radialGradient
        id="paint4_radial_2963_677"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(218.498 394.353) rotate(92.7321) scale(20.6706 33.8576)"
      >
        <stop stop-color="#A59FB7" />
        <stop offset="1" stop-color="#615E6A" />
      </radialGradient>
      <radialGradient
        id="paint5_radial_2963_677"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(223.868 413.742) rotate(92.1948) scale(9.26542 33.5564)"
      >
        <stop stop-color="#F2F2F2" />
        <stop offset="1" stop-color="#A2A2A2" />
      </radialGradient>
      <radialGradient
        id="paint6_radial_2963_677"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(165 228.5) rotate(-95.9051) scale(11.1088 22.4218)"
      >
        <stop stop-color="#FFAD9B" />
        <stop offset="1" stop-color="#FF9C65" />
      </radialGradient>
      <radialGradient
        id="paint7_radial_2963_677"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(164 157.5) rotate(176.404) scale(94.3502 116.502)"
      >
        <stop stop-color="#FFAD9B" />
        <stop offset="1" stop-color="#FF9C65" />
      </radialGradient>
      <radialGradient
        id="paint8_radial_2963_677"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(90 89) rotate(22.5398) scale(302.616 313.796)"
      >
        <stop stop-color="#C1FF12" />
        <stop offset="1" stop-color="#00DADA" />
      </radialGradient>
      <radialGradient
        id="paint9_radial_2963_677"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(125.161 136.069) rotate(69.36) scale(15.992 116.662)"
      >
        <stop offset="0.0178998" />
        <stop offset="1" stop-color="#1F2933" />
      </radialGradient>
      <radialGradient
        id="paint10_radial_2963_677"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(215.5 144.355) rotate(178.212) scale(46.5226 32.0867)"
      >
        <stop offset="0.0178998" />
        <stop offset="1" stop-color="#1F2933" />
      </radialGradient>
      <radialGradient
        id="paint11_radial_2963_677"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(204.5 140.5) rotate(3.70463) scale(139.291 163.554)"
      >
        <stop stop-color="#C1FF12" />
        <stop offset="1" stop-color="#00DADA" />
      </radialGradient>
      <radialGradient
        id="paint12_radial_2963_677"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(62.4931 27.0555) rotate(153.234) scale(102.703 120.593)"
      >
        <stop stop-color="#C1FF12" />
        <stop offset="1" stop-color="#00DADA" />
      </radialGradient>
      <radialGradient
        id="paint13_radial_2963_677"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(242.877 286.381) rotate(11.8244) scale(15.5756 43.6343)"
      >
        <stop stop-color="#FFAD9B" />
        <stop offset="1" stop-color="#FF9C65" />
      </radialGradient>
      <radialGradient
        id="paint14_radial_2963_677"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(225.713 335.995) rotate(-175.71) scale(4.51692 19.5069)"
      >
        <stop stop-color="#FFAD9B" />
        <stop offset="1" stop-color="#FF9C65" />
      </radialGradient>
      <radialGradient
        id="paint15_radial_2963_677"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(235.94 332.845) rotate(172.429) scale(4.51692 19.5069)"
      >
        <stop stop-color="#FFAD9B" />
        <stop offset="1" stop-color="#FF9C65" />
      </radialGradient>
      <radialGradient
        id="paint16_radial_2963_677"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(211.958 306.696) rotate(-135.403) scale(4.50546 18.5494)"
      >
        <stop stop-color="#FFAD9B" />
        <stop offset="1" stop-color="#FF9C65" />
      </radialGradient>
      <radialGradient
        id="paint17_radial_2963_677"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(217.169 331.432) rotate(-161.623) scale(4.52923 20.4778)"
      >
        <stop stop-color="#FFAD9B" />
        <stop offset="1" stop-color="#FF9C65" />
      </radialGradient>
      <radialGradient
        id="paint18_radial_2963_677"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(210.637 324.087) rotate(-154.819) scale(4.54733 21.815)"
      >
        <stop stop-color="#FFAD9B" />
        <stop offset="1" stop-color="#FF9C65" />
      </radialGradient>
      <radialGradient
        id="paint19_radial_2963_677"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(231.308 249.078) rotate(70.8388) scale(35.5475 20.1962)"
      >
        <stop offset="0.0178998" />
        <stop offset="1" stop-color="#1F2933" />
      </radialGradient>
      <radialGradient
        id="paint20_radial_2963_677"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(87.4677 287.377) rotate(168.176) scale(15.5756 43.6343)"
      >
        <stop stop-color="#FFAD9B" />
        <stop offset="1" stop-color="#FF9C65" />
      </radialGradient>
      <radialGradient
        id="paint21_radial_2963_677"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(104.631 336.992) rotate(-4.29019) scale(4.51692 19.5069)"
      >
        <stop stop-color="#FFAD9B" />
        <stop offset="1" stop-color="#FF9C65" />
      </radialGradient>
      <radialGradient
        id="paint22_radial_2963_677"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(94.404 333.842) rotate(7.57127) scale(4.51692 19.5069)"
      >
        <stop stop-color="#FFAD9B" />
        <stop offset="1" stop-color="#FF9C65" />
      </radialGradient>
      <radialGradient
        id="paint23_radial_2963_677"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(118.386 307.693) rotate(-44.5973) scale(4.50546 18.5494)"
      >
        <stop stop-color="#FFAD9B" />
        <stop offset="1" stop-color="#FF9C65" />
      </radialGradient>
      <radialGradient
        id="paint24_radial_2963_677"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(113.175 332.429) rotate(-18.3766) scale(4.52923 20.4778)"
      >
        <stop stop-color="#FFAD9B" />
        <stop offset="1" stop-color="#FF9C65" />
      </radialGradient>
      <radialGradient
        id="paint25_radial_2963_677"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(119.708 325.084) rotate(-25.1805) scale(4.54733 21.815)"
      >
        <stop stop-color="#FFAD9B" />
        <stop offset="1" stop-color="#FF9C65" />
      </radialGradient>
      <radialGradient
        id="paint26_radial_2963_677"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(99.036 250.075) rotate(109.161) scale(35.5475 20.1962)"
      >
        <stop offset="0.0178998" />
        <stop offset="1" stop-color="#1F2933" />
      </radialGradient>
    </defs>
  </svg>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "Secondary2",
  props: {
    mask: String,
    face: String,
    body: String,
    hands: String,
    feet: String,
  },
});
</script>
