<template>
  <!-- Modal -->
  <div
    class="modal fade"
    id="CreatePasswordModal1"
    data-bs-backdrop="static"
    tabindex="-1"
    aria-labelledby="CreatePasswordModal1Label"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg modal-dialog-centered">
      <div class="modal-content m-4 m-lg-0">
        <div class="row flex-column-reverse flex-lg-row p-4 p-lg-5">
          <div
            class="col-12 col-lg-6 text-center text-lg-start d-flex flex-column justify-content-between mt-3 mt-lg-0"
          >
            <h2 class="text-primary create-password-modal-title mt-2">
              Ciao io sono Floppy!
            </h2>
            <p class="fs-5 fw-medium mt-3">
              Sono qui per guidarti nel magico mondo di Easycode. <br /> <br />
              Ma prima, devi creare la tua password personale!
            </p>
            <button
              class="btn btn-platform mt-3"
              data-bs-target="#CreatePasswordModal2"
              data-bs-toggle="modal"
            >
              CREA PASSWORD
            </button>
          </div>
          <div class="col-12 col-lg-6">
            <img
              class="img-fluid"
              src="@/assets/images/logos/floppy.png"
              alt="floppy"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Toggle -->
  <button
    id="CreatePasswordModal"
    type="button"
    class="d-none"
    data-bs-toggle="modal"
    data-bs-target="#CreatePasswordModal1"
  ></button>
</template>

<style lang="scss" scoped>
.create-password-modal-title {
  font-weight: 600;
  font-size: 2.2rem;
  line-height: 120%;
  letter-spacing: 0.01em;
  color: #1f2933;
}
</style>
