<template>
  <svg
    width="287"
    height="436"
    viewBox="0 0 287 436"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="omino verde-viola">
      <g id="Orecchio dx">
        <g id="Ellipse 7" filter="url(#filter0_d_2764_39785)">
          <circle cx="71.1154" cy="31.8234" r="27.8234" :fill="mask" />
        </g>
        <g id="Ellipse 8" filter="url(#filter1_i_2764_39785)">
          <path
            d="M91.2104 31.8233C91.2104 42.9213 82.2137 51.918 71.1157 51.918C60.0177 51.918 51.021 42.9213 51.021 31.8233C51.021 20.7253 60.0177 11.7286 71.1157 11.7286C82.2137 11.7286 91.2104 20.7253 91.2104 31.8233Z"
            :fill="mask"
          />
        </g>
      </g>
      <g id="Orecchio sx">
        <g id="Ellipse 7_2" filter="url(#filter2_d_2764_39785)">
          <circle cx="211.115" cy="31.8234" r="27.8234" :fill="mask" />
        </g>
        <g id="Ellipse 8_2" filter="url(#filter3_i_2764_39785)">
          <circle cx="211.116" cy="31.8233" r="20.0947" :fill="mask" />
        </g>
      </g>
      <g id="Gamba sx" filter="url(#filter4_i_2764_39785)">
        <path
          d="M162.997 274.646C162.997 264.867 170.924 256.94 180.703 256.94H190.82C200.599 256.94 208.526 264.867 208.526 274.646V353.057C208.526 362.836 200.599 370.763 190.82 370.763H180.703C170.924 370.763 162.997 362.836 162.997 353.057V274.646Z"
          fill="url(#paint0_linear_2764_39785)"
        />
      </g>
      <g id="Braccio dx" filter="url(#filter5_i_2764_39785)">
        <path
          d="M20.3957 163.222C21.4877 152.802 30.82 145.24 41.24 146.332C51.6601 147.423 59.2221 156.756 58.1301 167.176L53.3851 212.457C52.2932 222.877 42.9609 230.439 32.5408 229.347C22.1208 228.255 14.5588 218.923 15.6507 208.503L20.3957 163.222Z"
          fill="url(#paint1_radial_2764_39785)"
        />
      </g>
      <g id="Gamba dx" filter="url(#filter6_i_2764_39785)">
        <path
          d="M76.9976 289.822C76.9976 280.044 84.9247 272.117 94.7034 272.117H104.821C114.6 272.117 122.527 280.044 122.527 289.822V345.469C122.527 355.248 114.6 363.175 104.821 363.175H94.7034C84.9247 363.175 76.9976 355.248 76.9976 345.469V289.822Z"
          fill="url(#paint2_linear_2764_39785)"
        />
      </g>
      <g id="Braccio sx" filter="url(#filter7_i_2764_39785)">
        <path
          d="M228.6 166.591C227.546 156.518 234.857 147.497 244.93 146.443C255.003 145.389 264.023 152.7 265.077 162.773L270.342 213.087C271.397 223.159 264.085 232.18 254.013 233.234C243.94 234.288 234.919 226.977 233.865 216.904L228.6 166.591Z"
          fill="url(#paint3_radial_2764_39785)"
        />
      </g>
      <g id="Body">
        <path
          id="Subtract"
          d="M121.559 125.411C83.1424 125.411 52 156.554 52 194.97V215.205H230.323V240.27C230.323 240.236 230.323 240.201 230.323 240.166V194.97C230.323 156.554 199.18 125.411 160.764 125.411H121.559Z"
          :fill="body"
        />
        <g id="Subtract_2" filter="url(#filter8_di_2764_39785)">
          <path
            d="M162.029 306.264C199.747 306.264 230.323 275.687 230.323 237.97V205.82C230.323 205.802 230.323 205.784 230.323 205.767V224.058H52L52 237.97C52 275.687 82.5762 306.264 120.294 306.264H162.029Z"
            :fill="body"
          />
        </g>
      </g>
      <g id="Cappuccio" filter="url(#filter9_i_2764_39785)">
        <path
          d="M51.7036 94.4261C51.7036 44.4852 92.1888 4 142.13 4C192.071 4 232.556 44.4851 232.556 94.4261V104.544C232.556 154.485 192.071 194.97 142.13 194.97C92.1888 194.97 51.7036 154.485 51.7036 104.544V94.4261Z"
          :fill="mask"
        />
      </g>
      <g id="Mano dx">
        <g id="Rectangle 457" filter="url(#filter10_i_2764_39785)">
          <path
            d="M11.8888 232.27C12.9807 221.85 22.3131 214.288 32.7331 215.379C43.1532 216.471 50.7152 225.804 49.6232 236.224L48.8324 243.771C47.7405 254.191 38.4082 261.753 27.9881 260.661C17.568 259.569 10.0061 250.237 11.098 239.816L11.8888 232.27Z"
            fill="url(#paint5_radial_2764_39785)"
          />
        </g>
        <g id="Rectangle 464" filter="url(#filter11_di_2764_39785)">
          <path
            d="M45.9856 208.875C53.2086 210.146 58.0334 217.032 56.7621 224.255C55.4908 231.478 48.6049 236.303 41.3819 235.032L13.9797 230.209C6.75676 228.938 1.93196 222.052 3.20324 214.829C4.47453 207.606 11.3605 202.781 18.5834 204.052L45.9856 208.875Z"
            :fill="hands"
          />
        </g>
      </g>
      <g id="Mano sx">
        <g id="Rectangle 458" filter="url(#filter12_i_2764_39785)">
          <path
            d="M237.164 236.269C236.073 225.849 243.636 216.517 254.057 215.427C264.477 214.336 273.808 221.9 274.899 232.32L275.688 239.867C276.779 250.287 269.216 259.618 258.795 260.709C248.375 261.799 239.044 254.236 237.953 243.816L237.164 236.269Z"
            fill="url(#paint7_radial_2764_39785)"
          />
        </g>
        <g id="Rectangle 465" filter="url(#filter13_di_2764_39785)">
          <path
            d="M245.858 235.03C238.635 236.301 231.75 231.475 230.479 224.252C229.209 217.029 234.035 210.143 241.258 208.873L268.661 204.054C275.884 202.784 282.769 207.609 284.039 214.833C285.31 222.056 280.484 228.941 273.261 230.211L245.858 235.03Z"
            :fill="hands"
          />
        </g>
      </g>
      <path
        id="Spalla dx"
        d="M64.8818 128.681C72.923 128.223 78.9145 135.986 76.4334 143.648L62.0033 188.213C59.1647 196.98 47.6641 198.979 42.0341 191.684L9.69728 149.785C4.06733 142.49 8.91518 131.872 18.1149 131.347L64.8818 128.681Z"
        fill="url(#paint9_linear_2764_39785)"
      />
      <path
        id="Spalla sx"
        d="M213.703 128.681C205.661 128.223 199.67 135.986 202.151 143.648L216.581 188.213C219.42 196.98 230.92 198.979 236.55 191.684L268.887 149.785C274.517 142.49 269.669 131.872 260.47 131.347L213.703 128.681Z"
        fill="url(#paint10_linear_2764_39785)"
      />
      <g id="Scarpa sx">
        <g id="Rectangle 453" filter="url(#filter14_i_2764_39785)">
          <path
            d="M150.35 366.969C150.35 357.19 158.277 349.263 168.056 349.263H189.556C199.334 349.263 207.262 357.19 207.262 366.969V393.528C207.262 403.306 199.334 411.234 189.556 411.234H168.056C158.277 411.234 150.35 403.306 150.35 393.528V366.969Z"
            :fill="feet"
          />
        </g>
        <path
          id="suola sx"
          d="M234.453 390.998C241.787 390.998 247.732 396.944 247.732 404.278C247.732 411.612 241.787 417.557 234.453 417.557L159.836 417.557C152.502 417.557 146.556 411.612 146.556 404.278C146.556 396.944 152.502 390.998 159.836 390.998L234.453 390.998Z"
          fill="url(#paint11_linear_2764_39785)"
        />
        <g id="Rectangle 452" filter="url(#filter15_i_2764_39785)">
          <path
            d="M170.585 349.263C170.585 342.977 175.681 337.881 181.968 337.881H208.526C214.813 337.881 219.909 342.977 219.909 349.263V372.028C219.909 378.314 214.813 383.41 208.526 383.41H181.968C175.681 383.41 170.585 378.314 170.585 372.028V349.263Z"
            :fill="feet"
          />
        </g>
        <g id="Ellipse 6" filter="url(#filter16_i_2764_39785)">
          <path
            d="M154.144 392.263C154.144 380.859 158.675 369.922 166.739 361.858C174.803 353.794 185.74 349.263 197.144 349.263C208.548 349.263 219.486 353.794 227.55 361.858C235.614 369.922 240.144 380.859 240.144 392.263L197.144 392.263L154.144 392.263Z"
            :fill="feet"
          />
        </g>
      </g>
      <g id="Scarpa dx">
        <g id="Rectangle 453_2" filter="url(#filter17_i_2764_39785)">
          <path
            d="M135.174 366.969C135.174 357.19 127.247 349.263 117.468 349.263H95.968C86.1894 349.263 78.2622 357.19 78.2622 366.969V393.528C78.2622 403.306 86.1894 411.234 95.968 411.234H117.468C127.247 411.234 135.174 403.306 135.174 393.528V366.969Z"
            :fill="feet"
          />
        </g>
        <path
          id="suola dx"
          d="M51.0709 390.998C43.7369 390.998 37.7915 396.944 37.7915 404.278C37.7915 411.612 43.7369 417.557 51.0709 417.557L125.688 417.557C133.022 417.557 138.968 411.612 138.968 404.278C138.968 396.944 133.022 390.998 125.688 390.998L51.0709 390.998Z"
          fill="url(#paint13_linear_2764_39785)"
        />
        <g id="Rectangle 452_2" filter="url(#filter18_i_2764_39785)">
          <path
            d="M114.939 349.263C114.939 342.977 109.843 337.881 103.556 337.881H76.9975C70.7113 337.881 65.6152 342.977 65.6152 349.263V372.028C65.6152 378.314 70.7113 383.41 76.9975 383.41H103.556C109.843 383.41 114.939 378.314 114.939 372.028V349.263Z"
            :fill="feet"
          />
        </g>
        <g id="Ellipse 6_2" filter="url(#filter19_i_2764_39785)">
          <path
            d="M131.38 392.263C131.38 380.859 126.849 369.922 118.785 361.858C110.721 353.794 99.784 349.263 88.3797 349.263C76.9755 349.263 66.0383 353.794 57.9742 361.858C49.9102 369.922 45.3799 380.859 45.3799 392.263L88.3797 392.263L131.38 392.263Z"
            :fill="feet"
          />
        </g>
      </g>
      <g id="Cappuccio 2" filter="url(#filter20_di_2764_39785)">
        <path
          d="M67.3506 94.1967C65.5265 51.5817 101.242 17.2286 143.867 18.8073C184.663 20.3183 217.339 54.3145 214.566 95.044C214.145 101.214 213.678 106.46 213.164 110.059C210.354 129.732 201.922 150.108 190.68 163.458C181.805 173.997 155.025 182.428 144.308 182.428C133.591 182.428 112.838 175.906 98.6381 163.458C81.1852 148.157 69.6231 125.339 68.4257 112.167C68.0144 107.642 67.658 101.379 67.3506 94.1967Z"
          :fill="mask"
        />
      </g>
      <g id="Viso">
        <path
          d="M83.4157 93.1486C81.9868 59.7669 109.964 32.8569 143.354 34.0936C175.31 35.2772 200.907 61.9076 198.734 93.8124C198.405 98.6452 198.039 102.755 197.636 105.574C195.435 120.985 191.732 133.5 180.024 147.403C171.521 157.5 154 162.263 143.699 162.263C133.398 162.263 119.047 157.155 107.924 147.403C94.2528 135.418 85.1958 117.544 84.2578 107.225C83.9356 103.681 83.6565 98.7744 83.4157 93.1486Z"
          :fill="face"
        />
      </g>
      <g id="Bocca" filter="url(#filter22_di_2764_39785)">
        <path
          d="M156.545 126.231C158.443 126.396 159.381 128.328 157.794 129.382C156.766 130.065 155.496 130.68 154.019 131.203C150.706 132.376 146.556 133.012 142.283 133C138.01 132.988 133.88 132.33 130.601 131.139C129.138 130.607 127.886 129.984 126.877 129.295C125.309 128.224 126.273 126.311 128.166 126.157C129.354 126.061 130.47 126.78 131.391 127.536C132.142 128.151 133.163 128.703 134.411 129.156C136.631 129.963 139.428 130.409 142.321 130.416C145.214 130.424 148.024 129.994 150.267 129.2C151.563 128.741 152.623 128.178 153.397 127.546C154.296 126.811 155.388 126.13 156.545 126.231Z"
          fill="#343333"
        />
      </g>
      <g id="Occhio dx">
        <g id="Vector">
          <g filter="url(#filter23_i_2764_39785)">
            <path
              d="M125.246 89.0804C133.665 89.0804 140.491 95.7782 140.491 104.04C140.491 112.302 133.665 119 125.246 119C116.826 119 110 112.302 110 104.04C110 95.7782 116.826 89.0804 125.246 89.0804Z"
              fill="white"
            />
          </g>
          <path
            d="M139.991 104.04C139.991 96.0632 133.398 89.5804 125.246 89.5804C117.093 89.5804 110.5 96.0632 110.5 104.04C110.5 112.017 117.093 118.5 125.246 118.5C133.398 118.5 139.991 112.017 139.991 104.04Z"
            stroke="#EAEAEA"
          />
        </g>
        <path
          id="Vector_2"
          d="M125.023 95.2923C130.006 95.2923 134.046 99.2563 134.046 104.146C134.046 109.036 130.006 113 125.023 113C120.04 113 116 109.036 116 104.146C116 99.2563 120.04 95.2923 125.023 95.2923Z"
          fill="url(#paint16_linear_2764_39785)"
        />
      </g>
      <g id="Occhio sx">
        <g id="Vector_3">
          <g filter="url(#filter24_i_2764_39785)">
            <path
              d="M158.754 89.0462C167.174 89.0462 174 95.744 174 104.006C174 112.268 167.174 118.966 158.754 118.966C150.334 118.966 143.509 112.268 143.509 104.006C143.509 95.744 150.334 89.0462 158.754 89.0462Z"
              fill="white"
            />
          </g>
          <path
            d="M173.5 104.006C173.5 96.029 166.907 89.5462 158.754 89.5462C150.602 89.5462 144.009 96.029 144.009 104.006C144.009 111.983 150.602 118.466 158.754 118.466C166.907 118.466 173.5 111.983 173.5 104.006Z"
            stroke="#EAEAEA"
          />
        </g>
        <path
          id="Vector_4"
          d="M159.023 95.2923C164.006 95.2923 168.046 99.2563 168.046 104.146C168.046 109.036 164.006 113 159.023 113C154.04 113 150 109.036 150 104.146C150 99.2563 154.04 95.2923 159.023 95.2923Z"
          fill="url(#paint17_linear_2764_39785)"
        />
      </g>
      <g id="sopracciglia dx" filter="url(#filter25_i_2764_39785)">
        <path
          d="M137.139 92.6327C138.305 91.7328 138.533 90.0404 137.471 89.0199C135.728 87.3454 133.658 86.0325 131.381 85.1691C128.27 83.9893 124.891 83.6952 121.623 84.3197C118.354 84.9442 115.322 86.4627 112.864 88.7065C111.066 90.3484 109.626 92.3316 108.623 94.5307C108.012 95.8709 108.847 97.3601 110.263 97.7668C111.679 98.1735 113.133 97.3353 113.827 96.0358C114.502 94.7699 115.391 93.6223 116.461 92.6461C118.19 91.0672 120.323 89.9988 122.623 89.5594C124.924 89.12 127.301 89.3269 129.49 90.1571C130.844 90.6703 132.093 91.4095 133.188 92.3371C134.312 93.2894 135.973 93.5327 137.139 92.6327Z"
          fill="url(#paint18_radial_2764_39785)"
        />
      </g>
      <g id="sopracciglia sx" filter="url(#filter26_i_2764_39785)">
        <path
          d="M146.861 92.6327C145.695 91.7328 145.467 90.0404 146.529 89.0199C148.272 87.3454 150.342 86.0325 152.619 85.1691C155.73 83.9893 159.109 83.6952 162.377 84.3197C165.646 84.9442 168.678 86.4627 171.136 88.7065C172.934 90.3484 174.374 92.3316 175.377 94.5307C175.988 95.8709 175.153 97.3601 173.737 97.7668C172.321 98.1735 170.867 97.3353 170.173 96.0358C169.498 94.7699 168.609 93.6223 167.539 92.6461C165.81 91.0672 163.677 89.9988 161.377 89.5594C159.076 89.12 156.699 89.3269 154.51 90.1571C153.156 90.6703 151.907 91.4095 150.812 92.3371C149.688 93.2894 148.027 93.5327 146.861 92.6327Z"
          fill="url(#paint19_radial_2764_39785)"
        />
      </g>
      <g id="Pettorina" filter="url(#filter27_di_2764_39785)">
        <path
          d="M147.12 241.215C143.719 244.739 138.073 244.739 134.672 241.215L40.0673 143.173C35.3631 138.297 37.8408 130.177 44.4968 128.914C107.808 116.903 175.252 114.89 238.099 128.572C244.567 129.98 246.815 137.897 242.219 142.66L147.12 241.215Z"
          :fill="body"
        />
      </g>
      <g id="Cintura">
        <path
          id="cintura"
          d="M47.9092 219.074C47.9092 212.746 53.0385 207.617 59.3659 207.617H224.893C231.22 207.617 236.35 212.746 236.35 219.074V227.778C236.35 234.105 231.22 239.234 224.893 239.234H59.3659C53.0385 239.234 47.9092 234.105 47.9092 227.778V219.074Z"
          fill="url(#paint21_linear_2764_39785)"
        />
        <g id="Fibbia">
          <g id="Ellipse 7_3" filter="url(#filter28_d_2764_39785)">
            <circle cx="141.115" cy="222.793" r="27.8234" fill="#FFE600" />
          </g>
          <g id="Ellipse 8_3" filter="url(#filter29_i_2764_39785)">
            <circle cx="141.292" cy="223" r="22" fill="#CCBB25" />
          </g>
        </g>
      </g>
    </g>
    <defs>
      <filter
        id="filter0_d_2764_39785"
        x="43.292"
        y="0.908509"
        width="61.83"
        height="61.8298"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="3.09149" />
        <feGaussianBlur stdDeviation="1.54575" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_2764_39785"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_2764_39785"
          result="shape"
        />
      </filter>
      <filter
        id="filter1_i_2764_39785"
        x="51.021"
        y="11.7286"
        width="40.1895"
        height="46.3724"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="6.18298" />
        <feGaussianBlur stdDeviation="3.09149" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect1_innerShadow_2764_39785"
        />
      </filter>
      <filter
        id="filter2_d_2764_39785"
        x="183.292"
        y="0.908509"
        width="61.83"
        height="61.8298"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="3.09149" />
        <feGaussianBlur stdDeviation="1.54575" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_2764_39785"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_2764_39785"
          result="shape"
        />
      </filter>
      <filter
        id="filter3_i_2764_39785"
        x="191.021"
        y="11.7286"
        width="40.1895"
        height="46.3724"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="6.18298" />
        <feGaussianBlur stdDeviation="3.09149" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect1_innerShadow_2764_39785"
        />
      </filter>
      <filter
        id="filter4_i_2764_39785"
        x="162.997"
        y="256.94"
        width="45.5293"
        height="118.601"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="4.77777" />
        <feGaussianBlur stdDeviation="8.3611" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect1_innerShadow_2764_39785"
        />
      </filter>
      <filter
        id="filter5_i_2764_39785"
        x="15.5459"
        y="146.227"
        width="42.689"
        height="88.0031"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="4.77777" />
        <feGaussianBlur stdDeviation="8.3611" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect1_innerShadow_2764_39785"
        />
      </filter>
      <filter
        id="filter6_i_2764_39785"
        x="76.9976"
        y="272.117"
        width="45.5293"
        height="95.8362"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="4.77777" />
        <feGaussianBlur stdDeviation="8.3611" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect1_innerShadow_2764_39785"
        />
      </filter>
      <filter
        id="filter7_i_2764_39785"
        x="228.499"
        y="146.342"
        width="41.9443"
        height="91.7703"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="4.77777" />
        <feGaussianBlur stdDeviation="8.3611" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect1_innerShadow_2764_39785"
        />
      </filter>
      <filter
        id="filter8_di_2764_39785"
        x="49.4706"
        y="205.767"
        width="183.382"
        height="105.556"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="2.5294" />
        <feGaussianBlur stdDeviation="1.2647" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_2764_39785"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_2764_39785"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="5.0588" />
        <feGaussianBlur stdDeviation="2.5294" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect2_innerShadow_2764_39785"
        />
      </filter>
      <filter
        id="filter9_i_2764_39785"
        x="51.7036"
        y="4"
        width="180.852"
        height="195.825"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="4.85509" />
        <feGaussianBlur stdDeviation="3.16175" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect1_innerShadow_2764_39785"
        />
      </filter>
      <filter
        id="filter10_i_2764_39785"
        x="10.9932"
        y="215.275"
        width="38.7349"
        height="50.2687"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="4.77777" />
        <feGaussianBlur stdDeviation="8.3611" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect1_innerShadow_2764_39785"
        />
      </filter>
      <filter
        id="filter11_di_2764_39785"
        x="0.470598"
        y="202.584"
        width="59.0241"
        height="37.7096"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1.2647" />
        <feGaussianBlur stdDeviation="1.2647" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_2764_39785"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_2764_39785"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="5.0588" />
        <feGaussianBlur stdDeviation="2.5294" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect2_innerShadow_2764_39785"
        />
      </filter>
      <filter
        id="filter12_i_2764_39785"
        x="237.059"
        y="215.322"
        width="38.7339"
        height="50.2688"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="4.77777" />
        <feGaussianBlur stdDeviation="8.3611" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect1_innerShadow_2764_39785"
        />
      </filter>
      <filter
        id="filter13_di_2764_39785"
        x="227.747"
        y="202.586"
        width="61.2065"
        height="37.3583"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1.2647" />
        <feGaussianBlur stdDeviation="1.2647" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_2764_39785"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_2764_39785"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="4.7113" dy="4.7113" />
        <feGaussianBlur stdDeviation="20.023" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect2_innerShadow_2764_39785"
        />
      </filter>
      <filter
        id="filter14_i_2764_39785"
        x="150.35"
        y="349.263"
        width="56.9116"
        height="66.7481"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="4.77777" />
        <feGaussianBlur stdDeviation="8.3611" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect1_innerShadow_2764_39785"
        />
      </filter>
      <filter
        id="filter15_i_2764_39785"
        x="170.585"
        y="337.881"
        width="49.3232"
        height="50.307"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="4.77777" />
        <feGaussianBlur stdDeviation="8.3611" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect1_innerShadow_2764_39785"
        />
      </filter>
      <filter
        id="filter16_i_2764_39785"
        x="154.145"
        y="349.263"
        width="85.9995"
        height="48.0586"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="5.0588" />
        <feGaussianBlur stdDeviation="2.5294" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect1_innerShadow_2764_39785"
        />
      </filter>
      <filter
        id="filter17_i_2764_39785"
        x="78.2622"
        y="349.263"
        width="56.9116"
        height="66.7481"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="4.77777" />
        <feGaussianBlur stdDeviation="8.3611" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect1_innerShadow_2764_39785"
        />
      </filter>
      <filter
        id="filter18_i_2764_39785"
        x="65.6152"
        y="337.881"
        width="49.3232"
        height="50.307"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="4.77777" />
        <feGaussianBlur stdDeviation="8.3611" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect1_innerShadow_2764_39785"
        />
      </filter>
      <filter
        id="filter19_i_2764_39785"
        x="45.3799"
        y="349.263"
        width="85.9995"
        height="48.0586"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="5.0588" />
        <feGaussianBlur stdDeviation="2.5294" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect1_innerShadow_2764_39785"
        />
      </filter>
      <filter
        id="filter20_di_2764_39785"
        x="64.7543"
        y="18.755"
        width="152.505"
        height="171.262"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="5.0588" />
        <feGaussianBlur stdDeviation="1.2647" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_2764_39785"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_2764_39785"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="5.6209" />
        <feGaussianBlur stdDeviation="27.9017" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect2_innerShadow_2764_39785"
        />
      </filter>
      <filter
        id="filter21_i_2764_39785"
        x="83.3633"
        y="34.0526"
        width="116.665"
        height="133.782"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="1.16551" dy="5.57113" />
        <feGaussianBlur stdDeviation="4.20749" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect1_innerShadow_2764_39785"
        />
      </filter>
      <filter
        id="filter22_di_2764_39785"
        x="124.477"
        y="125.34"
        width="35.7386"
        height="10.892"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="0.808055" />
        <feGaussianBlur stdDeviation="0.808055" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_2764_39785"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_2764_39785"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="3.23222" />
        <feGaussianBlur stdDeviation="1.61611" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.44 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect2_innerShadow_2764_39785"
        />
      </filter>
      <filter
        id="filter23_i_2764_39785"
        x="110"
        y="87.5062"
        width="32.0654"
        height="31.4938"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="1.57422" dy="-1.57422" />
        <feGaussianBlur stdDeviation="1.57422" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect1_innerShadow_2764_39785"
        />
      </filter>
      <filter
        id="filter24_i_2764_39785"
        x="143.509"
        y="87.472"
        width="32.0654"
        height="31.4938"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="1.57422" dy="-1.57422" />
        <feGaussianBlur stdDeviation="1.57422" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect1_innerShadow_2764_39785"
        />
      </filter>
      <filter
        id="filter25_i_2764_39785"
        x="108.42"
        y="84"
        width="30.8984"
        height="19.4412"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="1.16551" dy="5.57113" />
        <feGaussianBlur stdDeviation="4.20749" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect1_innerShadow_2764_39785"
        />
      </filter>
      <filter
        id="filter26_i_2764_39785"
        x="145.847"
        y="84"
        width="30.8984"
        height="19.4412"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="1.16551" dy="5.57113" />
        <feGaussianBlur stdDeviation="4.20749" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect1_innerShadow_2764_39785"
        />
      </filter>
      <filter
        id="filter27_di_2764_39785"
        x="35.1088"
        y="117.823"
        width="214.595"
        height="132.359"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="1.2647" dy="2.5294" />
        <feGaussianBlur stdDeviation="1.89705" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_2764_39785"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_2764_39785"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="4.7113" dy="4.7113" />
        <feGaussianBlur stdDeviation="20.023" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect2_innerShadow_2764_39785"
        />
      </filter>
      <filter
        id="filter28_d_2764_39785"
        x="113.292"
        y="191.878"
        width="61.83"
        height="61.8298"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="3.09149" />
        <feGaussianBlur stdDeviation="1.54575" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_2764_39785"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_2764_39785"
          result="shape"
        />
      </filter>
      <filter
        id="filter29_i_2764_39785"
        x="119.292"
        y="201"
        width="44"
        height="50.183"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="6.18298" />
        <feGaussianBlur stdDeviation="3.09149" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect1_innerShadow_2764_39785"
        />
      </filter>
      <linearGradient
        id="paint0_linear_2764_39785"
        x1="182.6"
        y1="259.567"
        x2="180.176"
        y2="378.62"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#A5CE00" />
        <stop offset="1" stop-color="#E6F5A8" />
      </linearGradient>
      <radialGradient
        id="paint1_radial_2764_39785"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(35.8271 190.932) rotate(95.9822) scale(105.729 50.9885)"
      >
        <stop stop-color="#E6F5A8" />
        <stop offset="0.963542" stop-color="#A5CE00" />
      </radialGradient>
      <linearGradient
        id="paint2_linear_2764_39785"
        x1="96.6004"
        y1="274.218"
        x2="95.0487"
        y2="369.474"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#A5CE00" />
        <stop offset="1" stop-color="#E6F5A8" />
      </linearGradient>
      <radialGradient
        id="paint3_radial_2764_39785"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(249.111 193.226) rotate(84.0258) scale(110.535 49.2889)"
      >
        <stop stop-color="#E6F5A8" />
        <stop offset="0.963542" stop-color="#A5CE00" />
      </radialGradient>
      <radialGradient
        id="paint4_radial_2764_39785"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(147.485 249.831) rotate(-87.6579) scale(61.896 100.91)"
      >
        <stop stop-color="#7866B4" />
        <stop offset="1" stop-color="#2F1E67" />
      </radialGradient>
      <radialGradient
        id="paint5_radial_2764_39785"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(29.4478 239.672) rotate(95.9822) scale(57.6705 50.9885)"
      >
        <stop stop-color="#E6F5A8" />
        <stop offset="1" stop-color="#A5CE00" />
      </radialGradient>
      <linearGradient
        id="paint6_linear_2764_39785"
        x1="56.7621"
        y1="224.255"
        x2="-26.9468"
        y2="201.062"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#3A1C9D" />
        <stop offset="1" stop-color="#A88CFF" />
      </linearGradient>
      <radialGradient
        id="paint7_radial_2764_39785"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(255.875 239.873) rotate(84.0258) scale(57.6705 50.9885)"
      >
        <stop stop-color="#E6F5A8" />
        <stop offset="1" stop-color="#A5CE00" />
      </radialGradient>
      <linearGradient
        id="paint8_linear_2764_39785"
        x1="230.479"
        y1="224.252"
        x2="317.078"
        y2="217.482"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#3A1C9D" />
        <stop offset="1" stop-color="#A88CFF" />
      </linearGradient>
      <linearGradient
        id="paint9_linear_2764_39785"
        x1="64.3506"
        y1="143.117"
        x2="18.8214"
        y2="160.191"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#383765" />
        <stop offset="1" stop-color="#282828" />
      </linearGradient>
      <linearGradient
        id="paint10_linear_2764_39785"
        x1="214.233"
        y1="143.117"
        x2="259.763"
        y2="160.191"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#383765" />
        <stop offset="1" stop-color="#282828" />
      </linearGradient>
      <linearGradient
        id="paint11_linear_2764_39785"
        x1="171.792"
        y1="391"
        x2="171.792"
        y2="418"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#383765" />
        <stop offset="1" stop-color="#282828" />
      </linearGradient>
      <radialGradient
        id="paint12_radial_2764_39785"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(203.468 385.94) rotate(106.699) scale(39.6116 39.6116)"
      >
        <stop stop-color="#7866B4" />
        <stop offset="1" stop-color="#2F1E67" />
      </radialGradient>
      <linearGradient
        id="paint13_linear_2764_39785"
        x1="113.731"
        y1="391"
        x2="113.731"
        y2="418"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#383765" />
        <stop offset="1" stop-color="#282828" />
      </linearGradient>
      <radialGradient
        id="paint14_radial_2764_39785"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(82.0562 385.94) rotate(73.3008) scale(39.6116 39.6116)"
      >
        <stop stop-color="#7866B4" />
        <stop offset="1" stop-color="#2F1E67" />
      </radialGradient>
      <radialGradient
        id="paint15_radial_2764_39785"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(139.193 102.024) rotate(91.6237) scale(120.024 116.058)"
      >
        <stop stop-color="#E6F5A8" />
        <stop offset="1" stop-color="#A5CE00" />
      </radialGradient>
      <linearGradient
        id="paint16_linear_2764_39785"
        x1="119.275"
        y1="109.207"
        x2="131.1"
        y2="104.832"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#383765" />
        <stop offset="1" stop-color="#282828" />
      </linearGradient>
      <linearGradient
        id="paint17_linear_2764_39785"
        x1="153.275"
        y1="109.207"
        x2="165.1"
        y2="104.832"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#383765" />
        <stop offset="1" stop-color="#282828" />
      </linearGradient>
      <radialGradient
        id="paint18_radial_2764_39785"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(124.401 103.085) rotate(91.8022) scale(33.7045 36.1706)"
      >
        <stop stop-color="#E6F5A8" />
        <stop offset="1" stop-color="#A5CE00" />
      </radialGradient>
      <radialGradient
        id="paint19_radial_2764_39785"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(159.599 103.085) rotate(88.1978) scale(33.7045 36.1706)"
      >
        <stop stop-color="#E6F5A8" />
        <stop offset="1" stop-color="#A5CE00" />
      </radialGradient>
      <linearGradient
        id="paint20_linear_2764_39785"
        x1="140.896"
        y1="247.665"
        x2="146.486"
        y2="41.4936"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#3A1C9D" />
        <stop offset="1" stop-color="#A88CFF" />
      </linearGradient>
      <linearGradient
        id="paint21_linear_2764_39785"
        x1="144.924"
        y1="215.381"
        x2="143.878"
        y2="232.457"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#383765" />
        <stop offset="1" stop-color="#282828" />
      </linearGradient>
    </defs>
  </svg>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "Secondary1",
  props: {
    mask: String,
    face: String,
    body: String,
    hands: String,
    feet: String,
  },
});
</script>
