<template>
  <svg
    width="210"
    height="248"
    viewBox="0 0 210 248"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M192.01 99.2888H17.1365V169.876H192.01V99.2888Z" :fill="body" />
    <path
      d="M104.574 179.319C152.865 179.319 192.012 175.091 192.012 169.876C192.012 164.661 152.865 160.434 104.574 160.434C56.2838 160.434 17.1365 164.661 17.1365 169.876C17.1365 175.091 56.2838 179.319 104.574 179.319Z"
      :fill="body"
    />
    <path
      d="M104.574 108.731C152.865 108.731 192.012 104.504 192.012 99.2888C192.012 94.0738 152.865 89.8462 104.574 89.8462C56.2838 89.8462 17.1365 94.0738 17.1365 99.2888C17.1365 104.504 56.2838 108.731 104.574 108.731Z"
      :fill="head"
    />
    <path
      d="M24.2352 188.718C37.1118 188.718 47.5503 178.279 47.5503 165.402C47.5503 152.526 37.1118 142.087 24.2352 142.087C11.3587 142.087 0.920166 152.526 0.920166 165.402C0.920166 178.279 11.3587 188.718 24.2352 188.718Z"
      fill="white"
    />
    <path
      d="M185.765 184.88C198.641 184.88 209.08 174.442 209.08 161.565C209.08 148.689 198.641 138.25 185.765 138.25C172.888 138.25 162.45 148.689 162.45 161.565C162.45 174.442 172.888 184.88 185.765 184.88Z"
      fill="white"
    />
    <path
      d="M34.7763 208.881C51.1048 208.881 64.3418 195.644 64.3418 179.316C64.3418 162.987 51.1048 149.75 34.7763 149.75C18.4478 149.75 5.21082 162.987 5.21082 179.316C5.21082 195.644 18.4478 208.881 34.7763 208.881Z"
      :fill="hands"
    />
    <path
      d="M177.843 205.045C194.172 205.045 207.409 191.808 207.409 175.479C207.409 159.151 194.172 145.914 177.843 145.914C161.515 145.914 148.278 159.151 148.278 175.479C148.278 191.808 161.515 205.045 177.843 205.045Z"
      :fill="hands"
    />
    <path
      d="M130.045 99.2888C130.045 99.2888 141.117 102.525 157.932 98.9768C166.261 97.2208 169.881 93.0101 169.881 93.0101L151.55 90.6533L130.045 99.2888Z"
      fill="#282828"
    />
    <path
      d="M61.5053 0H147.943C156.637 0 164.976 3.45395 171.124 9.60204C177.272 15.7501 180.726 24.0887 180.726 32.7834V64.7031H28.7218V32.7834C28.7218 24.0887 32.1757 15.7501 38.3238 9.60204C44.4719 3.45395 52.8106 0 61.5053 0V0Z"
      :fill="head"
    />
    <path
      d="M116.077 60.9227C123.643 60.9227 129.777 54.7891 129.777 47.2229C129.777 39.6568 123.643 33.5232 116.077 33.5232C108.511 33.5232 102.378 39.6568 102.378 47.2229C102.378 54.7891 108.511 60.9227 116.077 60.9227Z"
      fill="white"
    />
    <path
      d="M93.6208 60.9227C101.187 60.9227 107.321 54.7891 107.321 47.2229C107.321 39.6568 101.187 33.5232 93.6208 33.5232C86.0547 33.5232 79.9211 39.6568 79.9211 47.2229C79.9211 54.7891 86.0547 60.9227 93.6208 60.9227Z"
      fill="white"
    />
    <path
      d="M111.531 55.3325C116.01 55.3325 119.641 51.7018 119.641 47.223C119.641 42.7443 116.01 39.1135 111.531 39.1135C107.053 39.1135 103.422 42.7443 103.422 47.223C103.422 51.7018 107.053 55.3325 111.531 55.3325Z"
      fill="#1F2933"
    />
    <path
      d="M98.0353 55.3325C102.514 55.3325 106.145 51.7018 106.145 47.223C106.145 42.7443 102.514 39.1135 98.0353 39.1135C93.5566 39.1135 89.9258 42.7443 89.9258 47.223C89.9258 51.7018 93.5566 55.3325 98.0353 55.3325Z"
      fill="#1F2933"
    />
    <path
      d="M180.726 64.7031C180.726 84.9652 146.633 101.393 104.574 101.393C62.5159 101.393 28.42 84.9652 28.42 64.7031"
      fill="#3E4C59"
    />
    <path
      d="M131.487 189.661C125.078 183.252 116.386 179.652 107.322 179.652C98.2585 179.652 89.5661 183.252 83.1573 189.661C76.7484 196.07 73.1479 204.762 73.1479 213.826C73.1479 222.889 76.7484 231.581 83.1573 237.99"
      fill="white"
    />
    <path
      d="M83.1572 237.991C86.3306 241.164 90.098 243.681 94.2442 245.399C98.3903 247.116 102.834 248 107.322 248C111.81 248 116.254 247.116 120.4 245.399C124.546 243.681 128.313 241.164 131.487 237.991C134.66 234.817 137.177 231.05 138.895 226.904C140.612 222.758 141.496 218.314 141.496 213.826C141.496 209.338 140.612 204.894 138.895 200.748C137.177 196.602 134.66 192.835 131.487 189.661"
      :fill="dot"
    />
    <path
      d="M146.886 0H62.2634C56.0986 0 51.101 4.99758 51.101 11.1624V12.3022C51.101 18.467 56.0986 23.4646 62.2634 23.4646H146.886C153.05 23.4646 158.048 18.467 158.048 12.3022V11.1624C158.048 4.99758 153.05 0 146.886 0Z"
      :fill="hair"
    />
  </svg>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "Avatar1",
  props: {
    hair: String,
    head: String,
    body: String,
    hands: String,
    dot: String,
  },
});
</script>

<style></style>
