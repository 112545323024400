<template>
  <!-- Modal -->
  <div
    class="modal fade"
    id="CreatePasswordModal3"
    tabindex="-1"
    aria-labelledby="CreatePasswordModal3Label"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg modal-dialog-centered">
      <div class="modal-content m-4 m-lg-0">
        <button
          class="close-modal d-flex justify-content-center align-items-center"
          data-bs-dismiss="modal"
          aria-label="Close"
        >
          <img src="@/assets/images/icons/Close.svg" alt="" />
        </button>
        <div class="row flex-column-reverse p-4 p-lg-5">
          <div
            class="col-12 text-center text-lg-start d-flex flex-column justify-content-between mt-3 mt-lg-0"
          >
            <h2 class="text-primary fw-semibold text-center">Password creata!</h2>
            <p class="text1 fs-4 text-center mt-3">
              Missione compiuta, ora puoi entrare nel mondo di Easycode.
            </p>
          </div>
          <div class="col-12 d-flex justify-content-center">
            <img
              class="img-fluid"
              src="@/assets/images/frame/thumb.png"
              alt="floppy"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.close-modal {
  position: absolute;
  width: 52px;
  height: 52px;
  top: -16px;
  right: -16px;
  box-shadow: 0px 1px 8px rgba(123, 135, 148, 0.12),
    0px 8px 16px rgba(123, 135, 148, 0.08) !important;
  border-radius: 50px;
  background-color: #fde3e4;
  border: none;
}

.text1 {
  font-weight: 600;
  line-height: 120%;
  letter-spacing: 0.01em;
  color: #1f2933;
}
</style>
