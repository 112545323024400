<template>
  <!-- Modal -->
  <div
    class="modal fade"
    id="WelcomeModal2"
    tabindex="-1"
    aria-labelledby="WelcomeModal2Label"
    aria-hidden="true"
  >
    <div
      class="modal-dialog modal-dialog-slideout modal-lg modal-dialog-centered"
    >
      <div class="modal-content m-4 m-lg-0">
        <div class="row flex-column-reverse flex-lg-row p-4 p-lg-5">
          <div
            class="col-12 col-lg-6 text-center text-lg-start d-flex flex-column justify-content-between mt-3 mt-lg-0"
          >
            <h2 class="text-primary modal-title">
              Sei già al secondo traguardo!
            </h2>
            <p class="fs-4 modal-p mt-3">
              Hai scelto la password e il tuo avatar: ti sei meritato un altro
              distintivo. Continua così!
            </p>
            <button
              class="btn btn-platform mt-3"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              VAI AVANTI
            </button>
          </div>
          <div class="col-12 col-lg-6 d-flex justify-content-center">
            <img
              class="img-fluid"
              src="@/assets/images/frame/reward.png"
              alt="floppy"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "WelcomeModal2",
});
</script>

<style lang="scss" scoped>
.modal-title {
  font-weight: 600;
  font-size: 2.2rem;
  line-height: 120%;
  letter-spacing: 0.01em;
}
.modal-p {
  font-weight: 500;
  line-height: 120%;
  letter-spacing: 0.01em;
}
</style>
