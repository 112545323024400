<template>
  <svg
    width="242"
    height="573"
    viewBox="0 0 242 573"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="BOT BLU">
      <g id="braccio sx">
        <g id="Group 806">
          <g id="Rectangle 539" filter="url(#filter0_i_2963_676)">
            <path
              d="M236.069 323.921C237.228 316.305 231.994 309.19 224.378 308.031C216.761 306.871 209.647 312.105 208.487 319.722L203.593 351.862C202.434 359.478 207.668 366.593 215.284 367.752C222.901 368.912 230.015 363.678 231.175 356.061L236.069 323.921Z"
              :fill="hands"
            />
          </g>
          <g id="Rectangle 538" filter="url(#filter1_i_2963_676)">
            <path
              d="M228.423 246.929C227.226 238.805 219.671 233.19 211.547 234.387C203.424 235.583 197.809 243.139 199.005 251.262L205.936 298.309C207.132 306.432 214.688 312.048 222.811 310.851C230.935 309.654 236.55 302.099 235.353 293.976L228.423 246.929Z"
              :fill="hands"
            />
          </g>
          <g id="Ellipse 26" filter="url(#filter2_di_2963_676)">
            <path
              d="M221.668 235.324C221.668 244.751 214.025 252.394 204.598 252.394C195.17 252.394 187.528 244.751 187.528 235.324C187.528 225.896 195.17 218.254 204.598 218.254C214.025 218.254 221.668 225.896 221.668 235.324Z"
              fill="url(#paint2_linear_2963_676)"
            />
          </g>
          <g id="Ellipse 30" filter="url(#filter3_di_2963_676)">
            <path
              d="M237.962 310.586C237.962 318.3 231.709 324.553 223.996 324.553C216.282 324.553 210.029 318.3 210.029 310.586C210.029 302.873 216.282 296.62 223.996 296.62C231.709 296.62 237.962 302.873 237.962 310.586Z"
              fill="url(#paint3_linear_2963_676)"
            />
          </g>
        </g>
      </g>
      <g id="mano sx">
        <g id="Polygon 19" filter="url(#filter4_i_2963_676)">
          <path
            d="M216.065 381.951C212.799 385.296 207.134 383.817 205.92 379.303L198.984 353.519C197.78 349.047 201.855 344.942 206.336 346.112L231.927 352.793C236.408 353.963 237.956 359.536 234.72 362.849L216.065 381.951Z"
            fill="url(#paint4_linear_2963_676)"
          />
        </g>
        <g id="Group 797">
          <g id="Rectangle 524" filter="url(#filter5_i_2963_676)">
            <path
              d="M202.056 394.803C202.892 392.112 205.752 390.61 208.442 391.446C211.132 392.283 212.635 395.142 211.798 397.833L206.604 414.534C205.767 417.224 202.908 418.727 200.218 417.89C197.527 417.054 196.025 414.194 196.861 411.504L202.056 394.803Z"
              fill="url(#paint5_radial_2963_676)"
            />
          </g>
          <g id="Rectangle 529" filter="url(#filter6_i_2963_676)">
            <path
              d="M211.465 391.973C211.731 389.168 214.221 387.109 217.026 387.375C219.83 387.641 221.889 390.13 221.623 392.935L219.972 410.348C219.707 413.153 217.217 415.211 214.412 414.945C211.608 414.679 209.549 412.19 209.815 409.385L211.465 391.973Z"
              fill="url(#paint6_radial_2963_676)"
            />
          </g>
          <g id="Rectangle 527" filter="url(#filter7_i_2963_676)">
            <path
              d="M192.959 363.381C195.32 361.844 198.48 362.512 200.017 364.873C201.555 367.234 200.887 370.395 198.525 371.932L185.063 380.696C182.701 382.234 179.541 381.566 178.004 379.204C176.467 376.843 177.135 373.683 179.496 372.146L192.959 363.381Z"
              fill="url(#paint7_radial_2963_676)"
            />
          </g>
          <g id="Rectangle 525" filter="url(#filter8_i_2963_676)">
            <path
              d="M195.512 388.849C197.006 386.461 200.153 385.735 202.542 387.229C204.931 388.723 205.656 391.87 204.162 394.259L194.115 410.325C192.621 412.713 189.474 413.439 187.085 411.945C184.696 410.451 183.971 407.304 185.465 404.915L195.512 388.849Z"
              fill="url(#paint8_radial_2963_676)"
            />
          </g>
          <g id="Rectangle 526" filter="url(#filter9_i_2963_676)">
            <path
              d="M190.32 380.081C192.121 377.914 195.337 377.617 197.504 379.418C199.671 381.219 199.967 384.436 198.166 386.603L184.758 402.733C182.957 404.9 179.74 405.196 177.573 403.395C175.407 401.594 175.11 398.378 176.911 396.211L190.32 380.081Z"
              fill="url(#paint9_radial_2963_676)"
            />
          </g>
          <g id="Rectangle 528" filter="url(#filter10_i_2963_676)">
            <path
              d="M222.413 392.067C218.65 398.8 210.141 401.207 203.408 397.444L195.983 393.294C189.25 389.531 186.842 381.022 190.606 374.289L194.756 366.864C198.519 360.131 207.028 357.723 213.761 361.486L221.186 365.636C227.919 369.4 230.327 377.908 226.563 384.642L222.413 392.067Z"
              fill="url(#paint10_linear_2963_676)"
            />
          </g>
        </g>
      </g>
      <g id="braccio dx">
        <g id="Group 807">
          <g id="Rectangle 539_2" filter="url(#filter11_i_2963_676)">
            <path
              d="M4.93041 323.921C3.77073 316.305 9.00495 309.19 16.6214 308.031C24.2378 306.871 31.3522 312.105 32.5119 319.722L37.4056 351.862C38.5653 359.478 33.3311 366.593 25.7146 367.752C18.0982 368.912 10.9838 363.678 9.82408 356.061L4.93041 323.921Z"
              :fill="hands"
            />
          </g>
          <g id="Rectangle 538_2" filter="url(#filter12_i_2963_676)">
            <path
              d="M12.5764 246.929C13.773 238.805 21.3284 233.19 29.4518 234.387C37.5752 235.583 43.1905 243.139 41.9939 251.262L35.0635 298.309C33.8669 306.432 26.3115 312.048 18.1881 310.851C10.0647 309.654 4.44942 302.099 5.64605 293.976L12.5764 246.929Z"
              :fill="hands"
            />
          </g>
          <g id="Ellipse 26_2" filter="url(#filter13_di_2963_676)">
            <path
              d="M19.332 235.324C19.332 244.751 26.9745 252.394 36.402 252.394C45.8294 252.394 53.4719 244.751 53.4719 235.324C53.4719 225.896 45.8294 218.254 36.402 218.254C26.9745 218.254 19.332 225.896 19.332 235.324Z"
              fill="url(#paint13_linear_2963_676)"
            />
          </g>
          <g id="Ellipse 30_2" filter="url(#filter14_di_2963_676)">
            <path
              d="M3.0376 310.586C3.0376 318.3 9.29053 324.553 17.0039 324.553C24.7173 324.553 30.9702 318.3 30.9702 310.586C30.9702 302.873 24.7173 296.62 17.0039 296.62C9.29053 296.62 3.0376 302.873 3.0376 310.586Z"
              fill="url(#paint14_linear_2963_676)"
            />
          </g>
        </g>
      </g>
      <g id="mano dx">
        <g id="Polygon 19_2" filter="url(#filter15_i_2963_676)">
          <path
            d="M24.9342 381.951C28.2004 385.296 33.8651 383.817 35.0796 379.303L42.0158 353.519C43.2189 349.047 39.1445 344.942 34.6635 346.112L9.07247 352.793C4.59149 353.963 3.04363 359.536 6.27933 362.849L24.9342 381.951Z"
            fill="url(#paint15_linear_2963_676)"
          />
        </g>
        <g id="Group 797_2">
          <g id="Rectangle 524_2" filter="url(#filter16_i_2963_676)">
            <path
              d="M38.9438 394.803C38.1071 392.112 35.2479 390.61 32.5575 391.446C29.8672 392.283 28.3645 395.142 29.2012 397.833L34.3955 414.534C35.2322 417.224 38.0914 418.727 40.7817 417.89C43.4721 417.054 44.9748 414.195 44.138 411.504L38.9438 394.803Z"
              fill="url(#paint16_radial_2963_676)"
            />
          </g>
          <g id="Rectangle 529_2" filter="url(#filter17_i_2963_676)">
            <path
              d="M29.534 391.973C29.2681 389.168 26.7788 387.109 23.9739 387.375C21.1691 387.641 19.1108 390.13 19.3766 392.935L21.027 410.348C21.2929 413.153 23.7822 415.211 26.5871 414.945C29.3919 414.679 31.4502 412.19 31.1844 409.385L29.534 391.973Z"
              fill="url(#paint17_radial_2963_676)"
            />
          </g>
          <g id="Rectangle 527_2" filter="url(#filter18_i_2963_676)">
            <path
              d="M48.0406 363.381C45.6794 361.844 42.5192 362.512 40.982 364.873C39.4449 367.235 40.1129 370.395 42.474 371.932L55.9369 380.696C58.2981 382.234 61.4583 381.566 62.9955 379.204C64.5326 376.843 63.8646 373.683 61.5035 372.146L48.0406 363.381Z"
              fill="url(#paint18_radial_2963_676)"
            />
          </g>
          <g id="Rectangle 525_2" filter="url(#filter19_i_2963_676)">
            <path
              d="M45.4879 388.849C43.9941 386.461 40.8465 385.735 38.4577 387.229C36.069 388.723 35.3435 391.871 36.8374 394.259L46.8842 410.325C48.3781 412.713 51.5256 413.439 53.9144 411.945C56.3032 410.451 57.0287 407.304 55.5348 404.915L45.4879 388.849Z"
              fill="url(#paint19_radial_2963_676)"
            />
          </g>
          <g id="Rectangle 526_2" filter="url(#filter20_i_2963_676)">
            <path
              d="M50.6799 380.081C48.8789 377.914 45.6625 377.617 43.4958 379.418C41.3292 381.219 41.0328 384.436 42.8338 386.603L56.2423 402.733C58.0433 404.9 61.2597 405.196 63.4264 403.395C65.593 401.594 65.8894 398.378 64.0884 396.211L50.6799 380.081Z"
              fill="url(#paint20_radial_2963_676)"
            />
          </g>
          <g id="Rectangle 528_2" filter="url(#filter21_i_2963_676)">
            <path
              d="M18.5857 392.067C22.3489 398.8 30.8578 401.207 37.5909 397.444L45.0161 393.294C51.7492 389.531 54.1567 381.022 50.3935 374.289L46.2435 366.864C42.4803 360.131 33.9714 357.723 27.2383 361.486L19.8131 365.636C13.08 369.4 10.6725 377.909 14.4357 384.642L18.5857 392.067Z"
              fill="url(#paint21_linear_2963_676)"
            />
          </g>
        </g>
      </g>
      <g id="bacino" filter="">
        <path
          id="Rectangle 513"
          d="M54.0723 361.763C54.0723 351.068 62.7418 342.399 73.4362 342.399H165.061C175.755 342.399 184.425 351.068 184.425 361.763C184.425 386.207 164.608 406.023 140.164 406.023H98.3327C73.8883 406.023 54.0723 386.207 54.0723 361.763Z"
          :fill="body"
        />
        <g id="Subtract" filter="url(#filter23_i_2963_676)">
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M67.7109 308C64.0046 308 61.0001 311.005 61.0001 314.711V328.739C61.0001 350.518 78.656 368.174 100.436 368.174H135.928C157.707 368.174 175.363 350.518 175.363 328.739V314.711C175.363 311.005 172.359 308 168.653 308H67.7109Z"
            fill="url(#paint23_linear_2963_676)"
          />
        </g>
      </g>
      <g id="orecchio sx" filter="url(#filter24_di_2963_676)">
        <path
          d="M234.082 119.326C234.082 134.967 221.403 147.646 205.761 147.646C190.12 147.646 177.441 134.967 177.441 119.326C177.441 103.685 190.12 91.005 205.761 91.005C221.403 91.005 234.082 103.685 234.082 119.326Z"
          fill="url(#paint24_linear_2963_676)"
        />
      </g>
      <g id="orecchio dx" filter="url(#filter25_di_2963_676)">
        <path
          d="M58.7276 119.326C58.7276 134.967 46.048 147.646 30.407 147.646C14.766 147.646 2.08643 134.967 2.08643 119.326C2.08643 103.685 14.766 91.005 30.407 91.005C46.048 91.005 58.7276 103.685 58.7276 119.326Z"
          fill="url(#paint25_linear_2963_676)"
        />
      </g>
      <g id="Body" filter="url(#filter26_i_2963_676)">
        <path
          id="Rectangle 512"
          d="M37.002 234.11C37.002 222.353 46.5328 212.822 58.2896 212.822H181.758C193.515 212.822 203.046 222.353 203.046 234.11V284.167C203.046 316.306 176.992 342.36 144.853 342.36H95.1949C63.0558 342.36 37.002 316.306 37.002 284.167V234.11Z"
          :fill="body"
        />
        <g id="Subtract_2" filter="url(#filter27_i_2963_676)">
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M41.6659 307.032C50.5504 327.804 71.1729 342.36 95.1951 342.36H144.853C168.875 342.36 189.498 327.804 198.382 307.032H41.6659Z"
            :fill="body"
          />
        </g>
      </g>
      <g id="collo" filter="url(#filter28_di_2963_676)">
        <path
          d="M123.221 274.216C120.845 276.7 116.876 276.7 114.5 274.216L56.8487 213.956C53.5922 210.552 55.3205 204.915 59.95 204.043C98.6917 196.751 139.867 195.544 178.328 203.812C182.827 204.779 184.395 210.273 181.214 213.598L123.221 274.216Z"
          fill="url(#paint28_linear_2963_676)"
        />
      </g>
      <g id="Casco" filter="url(#filter29_i_2963_676)">
        <path
          d="M212.357 113.118C191.408 178.682 168.518 219.417 118.473 219.417C73.082 219.417 42.0458 179.846 24.5879 113.118C24.5879 55.0537 49.0289 7.98303 118.473 7.98303C184.425 7.98303 212.357 55.0537 212.357 113.118Z"
          :fill="face"
        />
      </g>
      <g id="bocca">
        <g id="Rectangle 540" filter="url(#filter30_di_2963_676)">
          <path
            d="M83.5562 173.855C83.5562 170.522 86.2581 167.82 89.5911 167.82H147.353C150.686 167.82 153.388 170.522 153.388 173.855V182.044C153.388 184.456 151.938 186.645 149.719 187.588C121.666 199.519 116.736 199.508 87.3164 187.555C85.0485 186.634 83.5562 184.42 83.5562 181.972V173.855Z"
            fill="url(#paint30_linear_2963_676)"
          />
        </g>
        <path
          id="Rectangle 541"
          d="M102.954 181.405C102.954 179.902 104.173 178.682 105.677 178.682H131.252C132.764 178.682 133.99 179.908 133.99 181.421C133.99 182.735 133.051 183.867 131.759 184.107C120.5 186.193 117.298 186.185 105.208 184.084C103.907 183.858 102.954 182.725 102.954 181.405Z"
          fill="black"
        />
      </g>
      <g id="maschera">
        <g id="visieera" filter="url(#filter31_di_2963_676)">
          <path
            d="M21.4839 99.1063C21.4839 87.7148 29.9598 78.1242 41.2901 76.9444C62.0447 74.7833 94.9176 71.817 118.996 71.817C142.969 71.817 175.237 74.7571 195.698 76.9156C207.008 78.1088 215.46 87.6899 215.46 99.0628V157.784C215.46 169.824 206.004 179.741 193.978 180.314L168.775 181.514C163.38 181.771 158.072 180.083 153.816 176.758L149.757 173.587C145.791 170.488 140.903 168.805 135.871 168.805H118.996H101.998C96.7215 168.805 91.612 170.655 87.5585 174.033L84.8348 176.303C80.5052 179.911 74.9818 181.767 69.352 181.507L42.9962 180.286C30.9577 179.729 21.4839 169.807 21.4839 157.755V99.1063Z"
            :fill="mask"
          />
        </g>
        <g id="visiera (Stroke)" filter="url(#filter32_di_2963_676)">
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M210.029 157.784V99.0628C210.029 90.4104 203.617 83.2125 195.128 82.317C174.674 80.1592 142.656 77.2484 118.996 77.2484C95.2278 77.2484 62.6043 80.1858 41.8526 82.3465C33.346 83.2323 26.9152 90.4393 26.9152 99.1063V157.755C26.9152 166.905 34.1077 174.438 43.2474 174.861L69.6032 176.081C73.8773 176.279 78.0707 174.87 81.3577 172.13L84.0814 169.861C89.111 165.669 95.451 163.374 101.998 163.374H135.871C142.115 163.374 148.18 165.462 153.1 169.306L157.16 172.478C160.391 175.002 164.421 176.284 168.517 176.088L193.72 174.888C202.85 174.454 210.029 166.924 210.029 157.784ZM41.2901 76.9444C29.9598 78.1242 21.4839 87.7148 21.4839 99.1063V157.755C21.4839 169.807 30.9577 179.729 42.9962 180.286L69.352 181.507C74.9818 181.767 80.5052 179.911 84.8348 176.303L87.5585 174.033C91.612 170.655 96.7215 168.805 101.998 168.805H135.871C140.903 168.805 145.791 170.488 149.757 173.587L153.816 176.758C158.072 180.083 163.38 181.771 168.775 181.514L193.978 180.314C206.004 179.741 215.46 169.824 215.46 157.784V99.0628C215.46 87.6899 207.008 78.1088 195.698 76.9156C175.237 74.7571 142.969 71.817 118.996 71.817C94.9176 71.817 62.0447 74.7833 41.2901 76.9444Z"
            fill="url(#paint32_linear_2963_676)"
          />
        </g>
        <g id="Rectangle 543" filter="url(#filter33_i_2963_676)">
          <path
            d="M156.816 99.9484C156.816 94.9656 160.856 90.9262 165.839 90.9262H185.387C190.369 90.9262 194.409 94.9656 194.409 99.9484C194.409 104.931 190.369 108.971 185.387 108.971H165.839C160.856 108.971 156.816 104.931 156.816 99.9484Z"
            fill="url(#paint33_linear_2963_676)"
          />
        </g>
      </g>
      <g id="Gamba dx">
        <g id="Rectangle 544" filter="url(#filter34_i_2963_676)">
          <path
            d="M55.4415 409.495C56.0934 397.721 66.1662 388.706 77.9396 389.358C89.713 390.009 98.7287 400.082 98.0767 411.856L96.3355 443.301C95.6835 455.075 85.6108 464.091 73.8374 463.439C62.064 462.787 53.0483 452.714 53.7002 440.941L55.4415 409.495Z"
            :fill="feet"
          />
        </g>
        <g id="Rectangle 546" filter="url(#filter35_i_2963_676)">
          <path
            d="M54.2595 472.114C53.7271 461.067 62.2514 451.679 73.2988 451.147C84.3463 450.615 93.7336 459.139 94.2659 470.186L96.1602 509.499C96.6925 520.546 88.1683 529.934 77.1208 530.466C66.0734 530.998 56.6861 522.474 56.1538 511.426L54.2595 472.114Z"
            :fill="feet"
          />
        </g>
        <g id="Rectangle 548" filter="url(#filter36_di_2963_676)">
          <path
            d="M72.3059 445.594C80.2336 445.594 86.6602 452.021 86.6602 459.948C86.6602 467.876 80.2336 474.303 72.3059 474.303H71.5301C63.6024 474.303 57.1758 467.876 57.1758 459.948C57.1758 452.021 63.6024 445.594 71.5301 445.594L72.3059 445.594Z"
            fill="url(#paint36_linear_2963_676)"
          />
        </g>
        <g id="Ellipse 33" filter="url(#filter37_di_2963_676)">
          <path
            d="M93.6432 396.324C93.6432 405.109 86.5218 412.23 77.7371 412.23C68.9524 412.23 61.8311 405.109 61.8311 396.324C61.8311 387.539 68.9524 380.418 77.7371 380.418C86.5218 380.418 93.6432 387.539 93.6432 396.324Z"
            fill="url(#paint37_linear_2963_676)"
          />
        </g>
      </g>
      <g id="Gamba sx">
        <g id="Rectangle 545" filter="url(#filter38_i_2963_676)">
          <path
            d="M184.606 409.495C183.954 397.721 173.881 388.706 162.108 389.358C150.335 390.009 141.319 400.082 141.971 411.856L143.712 443.301C144.364 455.075 154.437 464.091 166.21 463.439C177.983 462.787 186.999 452.714 186.347 440.941L184.606 409.495Z"
            :fill="feet"
          />
        </g>
        <g id="Rectangle 547" filter="url(#filter39_i_2963_676)">
          <path
            d="M185.788 472.114C186.32 461.067 177.796 451.679 166.748 451.147C155.701 450.615 146.314 459.139 145.781 470.186L143.887 509.499C143.355 520.546 151.879 529.934 162.926 530.466C173.974 530.998 183.361 522.474 183.893 511.426L185.788 472.114Z"
            :fill="feet"
          />
        </g>
        <g id="Rectangle 549" filter="url(#filter40_di_2963_676)">
          <path
            d="M167.742 445.594C159.814 445.594 153.388 452.021 153.388 459.948C153.388 467.876 159.814 474.303 167.742 474.303H168.518C176.446 474.303 182.872 467.876 182.872 459.948C182.872 452.021 176.446 445.594 168.518 445.594L167.742 445.594Z"
            fill="url(#paint40_linear_2963_676)"
          />
        </g>
        <g id="Ellipse 34" filter="url(#filter41_di_2963_676)">
          <path
            d="M146.405 396.324C146.405 405.109 153.526 412.23 162.311 412.23C171.096 412.23 178.217 405.109 178.217 396.324C178.217 387.539 171.096 380.418 162.311 380.418C153.526 380.418 146.405 387.539 146.405 396.324Z"
            fill="url(#paint41_linear_2963_676)"
          />
        </g>
      </g>
      <g id="piede dx">
        <g id="Rectangle 453" filter="url(#filter42_di_2963_676)">
          <path
            d="M106.833 509.994C106.833 499.281 98.1486 490.597 87.4356 490.597H64.1584C53.4454 490.597 44.7607 499.281 44.7607 509.994V547.238C44.7607 557.951 53.4454 566.635 64.1584 566.635H87.4356C98.1486 566.635 106.833 557.951 106.833 547.238V509.994Z"
            :fill="feet"
          />
        </g>
        <path
          id="Rectangle 446"
          d="M32.734 549.566C26.5205 549.566 21.4834 554.603 21.4834 560.816C21.4834 567.03 26.5205 572.067 32.734 572.067L101.79 572.067C108.003 572.067 113.04 567.03 113.04 560.816C113.04 554.603 108.003 549.566 101.79 549.566L32.734 549.566Z"
          fill="url(#paint43_linear_2963_676)"
        />
        <g id="Ellipse 6" filter="url(#filter43_di_2963_676)">
          <path
            d="M105.282 549.954C105.282 540.385 101.235 531.208 94.0323 524.441C86.8295 517.675 77.0604 513.874 66.8742 513.874C56.6879 513.874 46.9188 517.675 39.7161 524.441C32.5133 531.208 28.4668 540.385 28.4668 549.954L105.282 549.954Z"
            :fill="feet"
          />
        </g>
      </g>
      <g id="piede sx">
        <g id="Rectangle 453_2" filter="url(#filter44_di_2963_676)">
          <path
            d="M133.214 509.994C133.214 499.281 141.899 490.597 152.612 490.597H175.889C186.602 490.597 195.287 499.281 195.287 509.994V547.238C195.287 557.951 186.602 566.636 175.889 566.636H152.612C141.899 566.636 133.214 557.951 133.214 547.238V509.994Z"
            :fill="feet"
          />
        </g>
        <path
          id="Rectangle 446_2"
          d="M207.314 549.566C213.527 549.566 218.564 554.603 218.564 560.816C218.564 567.03 213.527 572.067 207.314 572.067L138.258 572.067C132.044 572.067 127.007 567.03 127.007 560.816C127.007 554.603 132.044 549.566 138.258 549.566L207.314 549.566Z"
          fill="url(#paint46_linear_2963_676)"
        />
        <g id="Ellipse 6_2" filter="url(#filter45_di_2963_676)">
          <path
            d="M134.767 549.954C134.767 540.385 138.813 531.208 146.016 524.441C153.219 517.675 162.988 513.874 173.174 513.874C183.36 513.874 193.129 517.675 200.332 524.441C207.535 531.208 211.581 540.385 211.581 549.954L134.767 549.954Z"
            :fill="feet"
          />
        </g>
      </g>
      <g id="triangolo casco" filter="url(#filter46_di_2963_676)">
        <path
          d="M127.891 59.5324C125.66 64.6984 117.754 64.7701 115.45 59.6359C112.414 52.87 108.628 45.1657 104.874 39.4556C100.006 32.0512 92.0225 23.177 86.1858 17.0395C82.9882 13.677 84.4528 8.00783 88.925 6.77074C116.931 -0.97626 124.186 -0.923219 152.715 6.92986C157.071 8.12906 158.618 13.5643 155.635 16.9582C150.051 23.311 142.308 32.5352 138.018 39.4556C134.28 45.4844 130.719 52.9861 127.891 59.5324Z"
          fill="url(#paint48_linear_2963_676)"
        />
      </g>
    </g>
    <defs>
      <filter
        id="filter0_i_2963_676"
        x="203.433"
        y="302.74"
        width="33.9503"
        height="65.1736"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="1.15338" dy="-5.13033" />
        <feGaussianBlur stdDeviation="25.453" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect1_innerShadow_2963_676"
        />
      </filter>
      <filter
        id="filter1_i_2963_676"
        x="198.845"
        y="229.096"
        width="37.8228"
        height="81.9161"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="1.15338" dy="-5.13033" />
        <feGaussianBlur stdDeviation="25.453" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect1_innerShadow_2963_676"
        />
      </filter>
      <filter
        id="filter2_di_2963_676"
        x="185.763"
        y="217.371"
        width="39.4345"
        height="39.4347"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="0.88247" dy="1.76494" />
        <feGaussianBlur stdDeviation="1.3237" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_2963_676"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_2963_676"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="3.2874" dy="3.2874" />
        <feGaussianBlur stdDeviation="13.9714" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect2_innerShadow_2963_676"
        />
      </filter>
      <filter
        id="filter3_di_2963_676"
        x="208.264"
        y="295.738"
        width="33.2274"
        height="33.2274"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="0.88247" dy="1.76494" />
        <feGaussianBlur stdDeviation="1.3237" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_2963_676"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_2963_676"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="3.2874" dy="3.2874" />
        <feGaussianBlur stdDeviation="13.9714" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect2_innerShadow_2963_676"
        />
      </filter>
      <filter
        id="filter4_i_2963_676"
        x="198.77"
        y="345.91"
        width="40.9588"
        height="41.1507"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="3.2874" dy="3.2874" />
        <feGaussianBlur stdDeviation="13.9714" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect1_innerShadow_2963_676"
        />
      </filter>
      <filter
        id="filter5_i_2963_676"
        x="196.63"
        y="385.831"
        width="16.6103"
        height="32.2909"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="1.21035" dy="-5.38375" />
        <feGaussianBlur stdDeviation="26.7103" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect1_innerShadow_2963_676"
        />
      </filter>
      <filter
        id="filter6_i_2963_676"
        x="209.792"
        y="381.968"
        width="13.0644"
        height="32.9999"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="1.21035" dy="-5.38375" />
        <feGaussianBlur stdDeviation="26.7103" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect1_innerShadow_2963_676"
        />
      </filter>
      <filter
        id="filter7_i_2963_676"
        x="177.177"
        y="357.171"
        width="24.8773"
        height="24.3523"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="1.21035" dy="-5.38375" />
        <feGaussianBlur stdDeviation="26.7103" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect1_innerShadow_2963_676"
        />
      </filter>
      <filter
        id="filter8_i_2963_676"
        x="184.688"
        y="381.068"
        width="21.4613"
        height="31.6533"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="1.21035" dy="-5.38375" />
        <feGaussianBlur stdDeviation="26.7103" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect1_innerShadow_2963_676"
        />
      </filter>
      <filter
        id="filter9_i_2963_676"
        x="175.733"
        y="372.856"
        width="24.8222"
        height="31.7176"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="1.21035" dy="-5.38375" />
        <feGaussianBlur stdDeviation="26.7103" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect1_innerShadow_2963_676"
        />
      </filter>
      <filter
        id="filter10_i_2963_676"
        x="188.828"
        y="359.709"
        width="42.8001"
        height="42.8002"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="3.2874" dy="3.2874" />
        <feGaussianBlur stdDeviation="13.9714" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect1_innerShadow_2963_676"
        />
      </filter>
      <filter
        id="filter11_i_2963_676"
        x="4.76953"
        y="302.74"
        width="33.9503"
        height="65.1736"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="1.15338" dy="-5.13033" />
        <feGaussianBlur stdDeviation="25.453" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect1_innerShadow_2963_676"
        />
      </filter>
      <filter
        id="filter12_i_2963_676"
        x="5.48535"
        y="229.096"
        width="37.8228"
        height="81.9161"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="1.15338" dy="-5.13033" />
        <feGaussianBlur stdDeviation="25.453" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect1_innerShadow_2963_676"
        />
      </filter>
      <filter
        id="filter13_di_2963_676"
        x="17.5671"
        y="217.371"
        width="39.4345"
        height="39.4347"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="0.88247" dy="1.76494" />
        <feGaussianBlur stdDeviation="1.3237" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_2963_676"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_2963_676"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="3.2874" dy="3.2874" />
        <feGaussianBlur stdDeviation="13.9714" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect2_innerShadow_2963_676"
        />
      </filter>
      <filter
        id="filter14_di_2963_676"
        x="1.27266"
        y="295.738"
        width="33.2274"
        height="33.2274"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="0.88247" dy="1.76494" />
        <feGaussianBlur stdDeviation="1.3237" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_2963_676"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_2963_676"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="3.2874" dy="3.2874" />
        <feGaussianBlur stdDeviation="13.9714" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect2_innerShadow_2963_676"
        />
      </filter>
      <filter
        id="filter15_i_2963_676"
        x="4.55811"
        y="345.91"
        width="40.9588"
        height="41.1507"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="3.2874" dy="3.2874" />
        <feGaussianBlur stdDeviation="13.9714" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect1_innerShadow_2963_676"
        />
      </filter>
      <filter
        id="filter16_i_2963_676"
        x="28.9697"
        y="385.831"
        width="16.6103"
        height="32.2909"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="1.21035" dy="-5.38375" />
        <feGaussianBlur stdDeviation="26.7103" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect1_innerShadow_2963_676"
        />
      </filter>
      <filter
        id="filter17_i_2963_676"
        x="19.3535"
        y="381.968"
        width="13.0644"
        height="32.9999"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="1.21035" dy="-5.38375" />
        <feGaussianBlur stdDeviation="26.7103" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect1_innerShadow_2963_676"
        />
      </filter>
      <filter
        id="filter18_i_2963_676"
        x="40.1553"
        y="357.171"
        width="24.8773"
        height="24.3523"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="1.21035" dy="-5.38375" />
        <feGaussianBlur stdDeviation="26.7103" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect1_innerShadow_2963_676"
        />
      </filter>
      <filter
        id="filter19_i_2963_676"
        x="36.0605"
        y="381.069"
        width="21.4613"
        height="31.6533"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="1.21035" dy="-5.38375" />
        <feGaussianBlur stdDeviation="26.7103" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect1_innerShadow_2963_676"
        />
      </filter>
      <filter
        id="filter20_i_2963_676"
        x="41.6553"
        y="372.856"
        width="24.8222"
        height="31.7176"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="1.21035" dy="-5.38375" />
        <feGaussianBlur stdDeviation="26.7103" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect1_innerShadow_2963_676"
        />
      </filter>
      <filter
        id="filter21_i_2963_676"
        x="12.6582"
        y="359.709"
        width="42.8001"
        height="42.8002"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="3.2874" dy="3.2874" />
        <feGaussianBlur stdDeviation="13.9714" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect1_innerShadow_2963_676"
        />
      </filter>
      <filter
        id="filter22_di_2963_676"
        x="52.1097"
        y="307.019"
        width="136.24"
        height="103.911"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="0.981289" dy="1.96258" />
        <feGaussianBlur stdDeviation="1.47193" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_2963_676"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_2963_676"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="3.65552" dy="3.65552" />
        <feGaussianBlur stdDeviation="15.536" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect2_innerShadow_2963_676"
        />
      </filter>
      <filter
        id="filter23_i_2963_676"
        x="58.371"
        y="308"
        width="116.992"
        height="62.8032"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="-2.62904" dy="2.62904" />
        <feGaussianBlur stdDeviation="6.5726" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.682353 0 0 0 0 0.682353 0 0 0 0 0.682353 0 0 0 1 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect1_innerShadow_2963_676"
        />
      </filter>
      <filter
        id="filter24_di_2963_676"
        x="175.676"
        y="90.1226"
        width="61.9359"
        height="61.936"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="0.88247" dy="1.76494" />
        <feGaussianBlur stdDeviation="1.3237" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_2963_676"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_2963_676"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="3.2874" dy="3.2874" />
        <feGaussianBlur stdDeviation="13.9714" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect2_innerShadow_2963_676"
        />
      </filter>
      <filter
        id="filter25_di_2963_676"
        x="0.321486"
        y="90.1226"
        width="61.9359"
        height="61.936"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="0.88247" dy="1.76494" />
        <feGaussianBlur stdDeviation="1.3237" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_2963_676"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_2963_676"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="3.2874" dy="3.2874" />
        <feGaussianBlur stdDeviation="13.9714" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect2_innerShadow_2963_676"
        />
      </filter>
      <filter
        id="filter26_i_2963_676"
        x="37.002"
        y="207.692"
        width="167.197"
        height="134.668"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="1.15338" dy="-5.13033" />
        <feGaussianBlur stdDeviation="25.453" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect1_innerShadow_2963_676"
        />
      </filter>
      <filter
        id="filter27_i_2963_676"
        x="38.8277"
        y="307.032"
        width="159.555"
        height="38.1668"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="-2.83836" dy="2.83836" />
        <feGaussianBlur stdDeviation="7.09589" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0.52549 0 0 0 0 0.905882 0 0 0 1 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect1_innerShadow_2963_676"
        />
      </filter>
      <filter
        id="filter28_di_2963_676"
        x="53.4069"
        y="197.198"
        width="133.011"
        height="83.294"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="0.88247" dy="1.76494" />
        <feGaussianBlur stdDeviation="1.3237" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_2963_676"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_2963_676"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="3.2874" dy="3.2874" />
        <feGaussianBlur stdDeviation="13.9714" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect2_innerShadow_2963_676"
        />
      </filter>
      <filter
        id="filter29_i_2963_676"
        x="24.5879"
        y="2.37323"
        width="189.031"
        height="217.044"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="1.26117" dy="-5.6098" />
        <feGaussianBlur stdDeviation="27.8318" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect1_innerShadow_2963_676"
        />
      </filter>
      <filter
        id="filter30_di_2963_676"
        x="81.7912"
        y="166.937"
        width="75.1264"
        height="34.0033"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="0.88247" dy="1.76494" />
        <feGaussianBlur stdDeviation="1.3237" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_2963_676"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_2963_676"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="3.2874" dy="3.2874" />
        <feGaussianBlur stdDeviation="13.9714" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect2_innerShadow_2963_676"
        />
      </filter>
      <filter
        id="filter31_di_2963_676"
        x="19.5822"
        y="70.8662"
        width="199.682"
        height="115.427"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="0.950862" dy="1.90172" />
        <feGaussianBlur stdDeviation="1.42629" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_2963_676"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_2963_676"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="3.54217" dy="3.54217" />
        <feGaussianBlur stdDeviation="15.0542" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect2_innerShadow_2963_676"
        />
      </filter>
      <filter
        id="filter32_di_2963_676"
        x="19.7189"
        y="70.9346"
        width="199.271"
        height="115.017"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="0.88247" dy="1.76494" />
        <feGaussianBlur stdDeviation="1.3237" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_2963_676"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_2963_676"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="3.2874" dy="3.2874" />
        <feGaussianBlur stdDeviation="13.9714" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect2_innerShadow_2963_676"
        />
      </filter>
      <filter
        id="filter33_i_2963_676"
        x="156.816"
        y="90.9262"
        width="40.7777"
        height="21.2298"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="3.18546" dy="3.18546" />
        <feGaussianBlur stdDeviation="13.5382" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect1_innerShadow_2963_676"
        />
      </filter>
      <filter
        id="filter34_i_2963_676"
        x="53.667"
        y="384.194"
        width="45.5962"
        height="79.2779"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="1.15338" dy="-5.13033" />
        <feGaussianBlur stdDeviation="25.453" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect1_innerShadow_2963_676"
        />
      </filter>
      <filter
        id="filter35_i_2963_676"
        x="54.2358"
        y="445.993"
        width="43.1011"
        height="84.4964"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="1.15338" dy="-5.13033" />
        <feGaussianBlur stdDeviation="25.453" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect1_innerShadow_2963_676"
        />
      </filter>
      <filter
        id="filter36_di_2963_676"
        x="55.4108"
        y="444.712"
        width="34.7792"
        height="34.0033"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="0.88247" dy="1.76494" />
        <feGaussianBlur stdDeviation="1.3237" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_2963_676"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_2963_676"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="3.2874" dy="3.2874" />
        <feGaussianBlur stdDeviation="13.9714" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect2_innerShadow_2963_676"
        />
      </filter>
      <filter
        id="filter37_di_2963_676"
        x="60.0661"
        y="379.536"
        width="37.1068"
        height="37.107"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="0.88247" dy="1.76494" />
        <feGaussianBlur stdDeviation="1.3237" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_2963_676"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_2963_676"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="3.2874" dy="3.2874" />
        <feGaussianBlur stdDeviation="13.9714" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect2_innerShadow_2963_676"
        />
      </filter>
      <filter
        id="filter38_i_2963_676"
        x="141.938"
        y="384.194"
        width="45.5962"
        height="79.2779"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="1.15338" dy="-5.13033" />
        <feGaussianBlur stdDeviation="25.453" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect1_innerShadow_2963_676"
        />
      </filter>
      <filter
        id="filter39_i_2963_676"
        x="143.863"
        y="445.993"
        width="43.1011"
        height="84.4964"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="1.15338" dy="-5.13033" />
        <feGaussianBlur stdDeviation="25.453" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect1_innerShadow_2963_676"
        />
      </filter>
      <filter
        id="filter40_di_2963_676"
        x="151.623"
        y="444.712"
        width="34.7792"
        height="34.0033"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="0.88247" dy="1.76494" />
        <feGaussianBlur stdDeviation="1.3237" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_2963_676"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_2963_676"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="3.2874" dy="3.2874" />
        <feGaussianBlur stdDeviation="13.9714" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect2_innerShadow_2963_676"
        />
      </filter>
      <filter
        id="filter41_di_2963_676"
        x="144.64"
        y="379.536"
        width="37.1068"
        height="37.107"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="0.88247" dy="1.76494" />
        <feGaussianBlur stdDeviation="1.3237" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_2963_676"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_2963_676"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="3.2874" dy="3.2874" />
        <feGaussianBlur stdDeviation="13.9714" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect2_innerShadow_2963_676"
        />
      </filter>
      <filter
        id="filter42_di_2963_676"
        x="42.7982"
        y="489.615"
        width="67.96"
        height="81.9266"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="0.981289" dy="1.96258" />
        <feGaussianBlur stdDeviation="1.47193" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_2963_676"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_2963_676"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="3.65552" dy="3.65552" />
        <feGaussianBlur stdDeviation="15.536" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect2_innerShadow_2963_676"
        />
      </filter>
      <filter
        id="filter43_di_2963_676"
        x="26.5042"
        y="512.893"
        width="82.7027"
        height="41.9674"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="0.981289" dy="1.96258" />
        <feGaussianBlur stdDeviation="1.47193" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_2963_676"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_2963_676"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="3.65552" dy="3.65552" />
        <feGaussianBlur stdDeviation="15.536" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect2_innerShadow_2963_676"
        />
      </filter>
      <filter
        id="filter44_di_2963_676"
        x="131.252"
        y="489.615"
        width="67.96"
        height="81.9265"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="0.981289" dy="1.96258" />
        <feGaussianBlur stdDeviation="1.47193" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_2963_676"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_2963_676"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="3.65552" dy="3.65552" />
        <feGaussianBlur stdDeviation="15.536" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect2_innerShadow_2963_676"
        />
      </filter>
      <filter
        id="filter45_di_2963_676"
        x="132.804"
        y="512.893"
        width="82.7027"
        height="41.9674"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="0.981289" dy="1.96258" />
        <feGaussianBlur stdDeviation="1.47193" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_2963_676"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_2963_676"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="3.65552" dy="3.65552" />
        <feGaussianBlur stdDeviation="15.536" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect2_innerShadow_2963_676"
        />
      </filter>
      <filter
        id="filter46_di_2963_676"
        x="82.7023"
        y="0.11753"
        width="78.0038"
        height="67.7418"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="0.88247" dy="1.76494" />
        <feGaussianBlur stdDeviation="1.3237" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_2963_676"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_2963_676"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="3.2874" dy="3.2874" />
        <feGaussianBlur stdDeviation="13.9714" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect2_innerShadow_2963_676"
        />
      </filter>
      <linearGradient
        id="paint0_linear_2963_676"
        x1="238.994"
        y1="340.06"
        x2="186.833"
        y2="341.077"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#01D6FF" />
        <stop offset="1" stop-color="#3A1C9D" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_2963_676"
        x1="237.361"
        y1="268.688"
        x2="183.823"
        y2="284.594"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#01D6FF" />
        <stop offset="1" stop-color="#3A1C9D" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_2963_676"
        x1="204.505"
        y1="241.638"
        x2="204.505"
        y2="269.782"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="white" />
        <stop offset="1" stop-color="#AEAEAE" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_2963_676"
        x1="223.92"
        y1="315.753"
        x2="223.92"
        y2="338.779"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="white" />
        <stop offset="0.0001" stop-color="white" />
        <stop offset="1" stop-color="#AEAEAE" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_2963_676"
        x1="213.909"
        y1="366.84"
        x2="229.427"
        y2="329.208"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="white" />
        <stop offset="1" stop-color="#AEAEAE" />
      </linearGradient>
      <radialGradient
        id="paint5_radial_2963_676"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(203.827 405.619) rotate(107.276) scale(35.0785 13.7115)"
      >
        <stop stop-color="#C1CAD0" />
        <stop offset="1" stop-color="#2F4D53" />
      </radialGradient>
      <radialGradient
        id="paint6_radial_2963_676"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(215.422 402.194) rotate(95.4144) scale(35.0785 13.7115)"
      >
        <stop stop-color="#C1CAD0" />
        <stop offset="1" stop-color="#2F4D53" />
      </radialGradient>
      <radialGradient
        id="paint7_radial_2963_676"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(188.062 372.42) rotate(146.935) scale(33.272 13.7115)"
      >
        <stop stop-color="#C1CAD0" />
        <stop offset="1" stop-color="#2F4D53" />
      </radialGradient>
      <radialGradient
        id="paint8_radial_2963_676"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(194.055 400.426) rotate(122.021) scale(36.9248 13.7115)"
      >
        <stop stop-color="#C1CAD0" />
        <stop offset="1" stop-color="#2F4D53" />
      </radialGradient>
      <radialGradient
        id="paint9_radial_2963_676"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(186.625 392.196) rotate(129.735) scale(39.4932 13.7115)"
      >
        <stop stop-color="#C1CAD0" />
        <stop offset="1" stop-color="#2F4D53" />
      </radialGradient>
      <linearGradient
        id="paint10_linear_2963_676"
        x1="220.504"
        y1="373.823"
        x2="240.29"
        y2="348.994"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="white" />
        <stop offset="1" stop-color="#AEAEAE" />
      </linearGradient>
      <linearGradient
        id="paint11_linear_2963_676"
        x1="2.00508"
        y1="340.06"
        x2="54.1663"
        y2="341.077"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#01D6FF" />
        <stop offset="1" stop-color="#3A1C9D" />
      </linearGradient>
      <linearGradient
        id="paint12_linear_2963_676"
        x1="3.63876"
        y1="268.688"
        x2="57.1763"
        y2="284.594"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#01D6FF" />
        <stop offset="1" stop-color="#3A1C9D" />
      </linearGradient>
      <linearGradient
        id="paint13_linear_2963_676"
        x1="36.4944"
        y1="241.638"
        x2="36.4944"
        y2="269.782"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="white" />
        <stop offset="1" stop-color="#AEAEAE" />
      </linearGradient>
      <linearGradient
        id="paint14_linear_2963_676"
        x1="17.0797"
        y1="315.753"
        x2="17.0797"
        y2="338.779"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="white" />
        <stop offset="1" stop-color="#AEAEAE" />
      </linearGradient>
      <linearGradient
        id="paint15_linear_2963_676"
        x1="27.0903"
        y1="366.84"
        x2="11.5722"
        y2="329.208"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="white" />
        <stop offset="1" stop-color="#AEAEAE" />
      </linearGradient>
      <radialGradient
        id="paint16_radial_2963_676"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(37.1729 405.619) rotate(72.7241) scale(35.0785 13.7115)"
      >
        <stop stop-color="#C1CAD0" />
        <stop offset="1" stop-color="#2F4D53" />
      </radialGradient>
      <radialGradient
        id="paint17_radial_2963_676"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(25.5773 402.194) rotate(84.5856) scale(35.0785 13.7115)"
      >
        <stop stop-color="#C1CAD0" />
        <stop offset="1" stop-color="#2F4D53" />
      </radialGradient>
      <radialGradient
        id="paint18_radial_2963_676"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(52.937 372.42) rotate(33.0647) scale(33.272 13.7115)"
      >
        <stop stop-color="#C1CAD0" />
        <stop offset="1" stop-color="#2F4D53" />
      </radialGradient>
      <radialGradient
        id="paint19_radial_2963_676"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(46.9436 400.426) rotate(57.9792) scale(36.9248 13.7115)"
      >
        <stop stop-color="#C1CAD0" />
        <stop offset="1" stop-color="#2F4D53" />
      </radialGradient>
      <radialGradient
        id="paint20_radial_2963_676"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(54.3742 392.196) rotate(50.2653) scale(39.4932 13.7115)"
      >
        <stop stop-color="#C1CAD0" />
        <stop offset="1" stop-color="#2F4D53" />
      </radialGradient>
      <linearGradient
        id="paint21_linear_2963_676"
        x1="20.4953"
        y1="373.823"
        x2="0.709648"
        y2="348.994"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="white" />
        <stop offset="1" stop-color="#AEAEAE" />
      </linearGradient>
      <linearGradient
        id="paint22_linear_2963_676"
        x1="119.248"
        y1="342.399"
        x2="116.904"
        y2="444.44"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#3A1C9D" />
        <stop offset="1" stop-color="#01D6FF" />
      </linearGradient>
      <linearGradient
        id="paint23_linear_2963_676"
        x1="118.182"
        y1="308"
        x2="115.792"
        y2="404.5"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#AEAEAE" />
        <stop offset="1" stop-color="white" />
      </linearGradient>
      <linearGradient
        id="paint24_linear_2963_676"
        x1="205.608"
        y1="129.802"
        x2="205.608"
        y2="176.495"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="white" />
        <stop offset="1" stop-color="#AEAEAE" />
      </linearGradient>
      <linearGradient
        id="paint25_linear_2963_676"
        x1="30.2532"
        y1="129.802"
        x2="30.2532"
        y2="176.495"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="white" />
        <stop offset="1" stop-color="#AEAEAE" />
      </linearGradient>
      <radialGradient
        id="paint26_radial_2963_676"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(116.805 282.537) rotate(89.1906) scale(90.5188 123.102)"
      >
        <stop stop-color="#01D6FF" />
        <stop offset="1" stop-color="#0086E7" />
      </radialGradient>
      <radialGradient
        id="paint27_radial_2963_676"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(116.987 326.045) rotate(87.2008) scale(24.7139 116.06)"
      >
        <stop stop-color="#01D6FF" />
        <stop offset="1" stop-color="#0086E7" />
      </radialGradient>
      <linearGradient
        id="paint28_linear_2963_676"
        x1="119.237"
        y1="223.502"
        x2="119.237"
        y2="156.981"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="white" />
        <stop offset="1" stop-color="#AEAEAE" />
      </linearGradient>
      <radialGradient
        id="paint29_radial_2963_676"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(114.833 121.147) rotate(91.893) scale(171.688 162.662)"
      >
        <stop stop-color="#01D6FF" />
        <stop offset="1" stop-color="#3A1C9D" />
        <stop offset="1" stop-color="#3A1C9D" />
      </radialGradient>
      <linearGradient
        id="paint30_linear_2963_676"
        x1="118.282"
        y1="187.484"
        x2="118.282"
        y2="211.15"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="white" />
        <stop offset="1" stop-color="#AEAEAE" />
      </linearGradient>
      <linearGradient
        id="paint31_linear_2963_676"
        x1="118.472"
        y1="85.2873"
        x2="182.807"
        y2="149.895"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#2F4D53" />
        <stop offset="0.409287" stop-color="#C1CAD0" />
        <stop offset="0.90625" stop-color="#2F4D53" />
      </linearGradient>
      <linearGradient
        id="paint32_linear_2963_676"
        x1="117.945"
        y1="146.972"
        x2="117.945"
        y2="237.423"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="white" />
        <stop offset="1" stop-color="#AEAEAE" />
      </linearGradient>
      <linearGradient
        id="paint33_linear_2963_676"
        x1="175.511"
        y1="103.286"
        x2="175.511"
        y2="118.161"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="white" />
        <stop offset="1" stop-color="#AEAEAE" />
      </linearGradient>
      <linearGradient
        id="paint34_linear_2963_676"
        x1="46.4896"
        y1="423.859"
        x2="122.907"
        y2="444.744"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#01D6FF" />
        <stop offset="1" stop-color="#3A1C9D" />
      </linearGradient>
      <linearGradient
        id="paint35_linear_2963_676"
        x1="47.5292"
        y1="491.165"
        x2="121.511"
        y2="501.427"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#01D6FF" />
        <stop offset="1" stop-color="#3A1C9D" />
      </linearGradient>
      <linearGradient
        id="paint36_linear_2963_676"
        x1="66.4646"
        y1="459.87"
        x2="42.1586"
        y2="459.87"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="white" />
        <stop offset="1" stop-color="#AEAEAE" />
      </linearGradient>
      <linearGradient
        id="paint37_linear_2963_676"
        x1="77.6508"
        y1="402.208"
        x2="77.6508"
        y2="428.433"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="white" />
        <stop offset="1" stop-color="#AEAEAE" />
      </linearGradient>
      <linearGradient
        id="paint38_linear_2963_676"
        x1="193.558"
        y1="423.859"
        x2="117.141"
        y2="444.744"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#01D6FF" />
        <stop offset="1" stop-color="#3A1C9D" />
      </linearGradient>
      <linearGradient
        id="paint39_linear_2963_676"
        x1="192.518"
        y1="491.165"
        x2="118.535"
        y2="501.427"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#01D6FF" />
        <stop offset="1" stop-color="#3A1C9D" />
      </linearGradient>
      <linearGradient
        id="paint40_linear_2963_676"
        x1="173.583"
        y1="459.87"
        x2="197.889"
        y2="459.87"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="white" />
        <stop offset="1" stop-color="#AEAEAE" />
      </linearGradient>
      <linearGradient
        id="paint41_linear_2963_676"
        x1="162.397"
        y1="402.208"
        x2="162.397"
        y2="428.433"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="white" />
        <stop offset="1" stop-color="#AEAEAE" />
      </linearGradient>
      <linearGradient
        id="paint42_linear_2963_676"
        x1="75.7968"
        y1="490.597"
        x2="83.4219"
        y2="600.748"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.046875" stop-color="#0086E7" />
        <stop offset="0.583333" stop-color="#01D6FF" />
      </linearGradient>
      <linearGradient
        id="paint43_linear_2963_676"
        x1="17.1423"
        y1="572.442"
        x2="159.608"
        y2="572.442"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#34343C" />
        <stop offset="0.114583" stop-color="#B3B3B3" />
        <stop offset="0.114683" stop-color="#3A3A3A" />
        <stop offset="0.229167" stop-color="#B9B9B9" />
        <stop offset="0.229267" stop-color="#4A4A4B" />
        <stop offset="0.354167" stop-color="#E2E2E2" />
        <stop offset="0.354267" stop-color="#333333" />
        <stop offset="0.53115" stop-color="#CCCCD0" />
        <stop offset="0.53125" stop-color="#333333" />
        <stop offset="0.6874" stop-color="#CACACA" />
        <stop offset="0.6875" stop-color="#333333" />
        <stop offset="0.848858" stop-color="#E3E3E3" />
        <stop offset="0.848958" stop-color="#333333" />
      </linearGradient>
      <linearGradient
        id="paint44_linear_2963_676"
        x1="66.8742"
        y1="513.874"
        x2="61.3142"
        y2="618.611"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.046875" stop-color="#0086E7" />
        <stop offset="0.583333" stop-color="#01D6FF" />
      </linearGradient>
      <linearGradient
        id="paint45_linear_2963_676"
        x1="164.251"
        y1="490.597"
        x2="156.625"
        y2="600.748"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.046875" stop-color="#0086E7" />
        <stop offset="0.583333" stop-color="#01D6FF" />
      </linearGradient>
      <linearGradient
        id="paint46_linear_2963_676"
        x1="222.905"
        y1="572.442"
        x2="80.4393"
        y2="572.442"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#34343C" />
        <stop offset="0.114583" stop-color="#B3B3B3" />
        <stop offset="0.114683" stop-color="#3A3A3A" />
        <stop offset="0.229167" stop-color="#B9B9B9" />
        <stop offset="0.229267" stop-color="#4A4A4B" />
        <stop offset="0.354167" stop-color="#E2E2E2" />
        <stop offset="0.354267" stop-color="#333333" />
        <stop offset="0.53115" stop-color="#CCCCD0" />
        <stop offset="0.53125" stop-color="#333333" />
        <stop offset="0.6874" stop-color="#CACACA" />
        <stop offset="0.6875" stop-color="#333333" />
        <stop offset="0.848858" stop-color="#E3E3E3" />
        <stop offset="0.848958" stop-color="#333333" />
      </linearGradient>
      <linearGradient
        id="paint47_linear_2963_676"
        x1="173.174"
        y1="513.874"
        x2="178.734"
        y2="618.611"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.046875" stop-color="#0086E7" />
        <stop offset="0.583333" stop-color="#01D6FF" />
      </linearGradient>
      <linearGradient
        id="paint48_linear_2963_676"
        x1="120.8"
        y1="24.2772"
        x2="120.8"
        y2="-36.6315"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="white" />
        <stop offset="1" stop-color="#AEAEAE" />
      </linearGradient>
    </defs>
  </svg>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "Secondary4",
  props: {
    mask: String,
    face: String,
    body: String,
    hands: String,
    feet: String,
  },
});
</script>
