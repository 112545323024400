// Vue libraries

import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { createPinia } from 'pinia'

// Style js

import "bootstrap"
import ApiService from './core/services/ApiService'

// App init

const app = createApp(App).use(createPinia()).use(router);

ApiService.init(app);

app.mount('#app')
