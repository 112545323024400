<template>
  <svg
    width="140"
    height="200"
    viewBox="0 0 140 200"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.94275 139.818C5.94275 100.902 35.8366 69.3542 72.7136 69.3542C109.591 69.3542 139.485 100.902 139.485 139.818"
      :fill="body"
    />
    <path
      d="M72.7138 146.265C109.567 146.265 139.442 143.379 139.442 139.818C139.442 136.258 109.567 133.371 72.7138 133.371C35.8611 133.371 5.98602 136.258 5.98602 139.818C5.98602 143.379 35.8611 146.265 72.7138 146.265Z"
      :fill="body"
    />
    <path
      d="M106.295 66.1167H39.539C35.0569 66.1167 31.4235 69.7502 31.4235 74.2322V75.0608C31.4235 79.5429 35.0569 83.1764 39.539 83.1764H106.295C110.777 83.1764 114.41 79.5429 114.41 75.0608V74.2322C114.41 69.7502 110.777 66.1167 106.295 66.1167Z"
      :fill="hair"
    />
    <path
      d="M73.2985 69.5396C93.7397 69.5396 110.311 68.2169 110.311 66.5851C110.311 64.9534 93.7397 63.6306 73.2985 63.6306C52.8573 63.6306 36.2864 64.9534 36.2864 66.5851C36.2864 68.2169 52.8573 69.5396 73.2985 69.5396Z"
      :fill="head"
    />
    <path
      d="M110.313 66.5854L36.2848 66.5854V37.0122C36.2848 27.196 40.1843 17.7818 47.1254 10.8407C54.0665 3.8996 63.4807 0.00012365 73.2969 0.00012365V0.00012365C83.1131 0.00012365 92.5272 3.8996 99.4683 10.8407C106.409 17.7818 110.309 27.196 110.309 37.0122V66.5854H110.313Z"
      :fill="head"
    />
    <path
      d="M19.3318 111.61C26.6736 111.61 32.6253 105.658 32.6253 98.3162C32.6253 90.9744 26.6736 85.0227 19.3318 85.0227C11.9901 85.0227 6.03839 90.9744 6.03839 98.3162C6.03839 105.658 11.9901 111.61 19.3318 111.61Z"
      :fill="hair"
    />
    <path
      d="M17.4664 125.301C26.8282 125.301 34.4174 117.711 34.4174 108.35C34.4174 98.9879 26.8282 91.3987 17.4664 91.3987C8.10463 91.3987 0.515442 98.9879 0.515442 108.35C0.515442 117.711 8.10463 125.301 17.4664 125.301Z"
      :fill="hands"
    />
    <path
      d="M122.506 111.61C129.848 111.61 135.799 105.658 135.799 98.3162C135.799 90.9744 129.848 85.0227 122.506 85.0227C115.164 85.0227 109.212 90.9744 109.212 98.3162C109.212 105.658 115.164 111.61 122.506 111.61Z"
      :fill="hair"
    />
    <path
      d="M122.506 125.301C131.867 125.301 139.457 117.711 139.457 108.35C139.457 98.9879 131.867 91.3987 122.506 91.3987C113.144 91.3987 105.555 98.9879 105.555 108.35C105.555 117.711 113.144 125.301 122.506 125.301Z"
      :fill="hands"
    />
    <path
      d="M59.0995 151.779C64.9843 148.168 72.0625 147.043 78.7768 148.651C82.1014 149.447 85.2367 150.891 88.0035 152.899C90.7703 154.906 93.1145 157.44 94.9024 160.354C96.6902 163.267 97.8865 166.505 98.4232 169.881C98.9598 173.257 98.8262 176.706 98.0299 180.031C97.2337 183.356 95.7904 186.491 93.7825 189.258C91.7746 192.024 89.2414 194.369 86.3275 196.156"
      :fill="dot"
    />
    <path
      d="M86.3274 196.157C83.4135 197.944 80.176 199.141 76.7998 199.677C73.4236 200.214 69.9747 200.08 66.6501 199.284C63.3254 198.488 60.1902 197.045 57.4234 195.037C54.6566 193.029 52.3124 190.496 50.5245 187.582C48.7367 184.668 47.5403 181.43 47.0037 178.054C46.4671 174.678 46.6007 171.229 47.3969 167.904C48.1932 164.58 49.6365 161.445 51.6444 158.678C53.6523 155.911 56.1855 153.567 59.0994 151.779"
      :fill="hands"
    />
    <path
      d="M96.4115 28.5082C98.9958 28.5116 101.473 29.5398 103.301 31.3672C105.128 33.1947 106.156 35.6722 106.16 38.2566V46.2709C106.156 48.8552 105.128 51.3328 103.301 53.1602C101.473 54.9876 98.9958 56.0158 96.4115 56.0193H49.4223C46.8379 56.0158 44.3604 54.9876 42.533 53.1602C40.7055 51.3328 39.6773 48.8552 39.6739 46.2709V38.2566C39.6773 35.6722 40.7055 33.1947 42.533 31.3672C44.3604 29.5398 46.8379 28.5116 49.4223 28.5082H96.4115ZM96.4115 20.2595H49.4223C44.6492 20.2595 40.0716 22.1556 36.6965 25.5307C33.3214 28.9058 31.4252 33.4835 31.4252 38.2566V46.2709C31.4252 51.044 33.3214 55.6216 36.6965 58.9967C40.0716 62.3718 44.6492 64.2679 49.4223 64.2679H96.4115C101.185 64.2679 105.762 62.3718 109.137 58.9967C112.512 55.6216 114.409 51.044 114.409 46.2709V38.2566C114.409 33.4835 112.512 28.9058 109.137 25.5307C105.762 22.1556 101.185 20.2595 96.4115 20.2595Z"
      :fill="hair"
    />
    <path
      d="M81.4084 52.6561C87.1464 52.6561 91.798 48.0045 91.798 42.2665C91.798 36.5285 87.1464 31.877 81.4084 31.877C75.6704 31.877 71.0189 36.5285 71.0189 42.2665C71.0189 48.0045 75.6704 52.6561 81.4084 52.6561Z"
      fill="white"
    />
    <path
      d="M65.1907 52.6561C70.9286 52.6561 75.5802 48.0045 75.5802 42.2665C75.5802 36.5285 70.9286 31.877 65.1907 31.877C59.4527 31.877 54.8011 36.5285 54.8011 42.2665C54.8011 48.0045 59.4527 52.6561 65.1907 52.6561Z"
      fill="white"
    />
    <path
      d="M68.2368 48.4154C71.6328 48.4154 74.3858 45.6624 74.3858 42.2664C74.3858 38.8704 71.6328 36.1174 68.2368 36.1174C64.8408 36.1174 62.0878 38.8704 62.0878 42.2664C62.0878 45.6624 64.8408 48.4154 68.2368 48.4154Z"
      fill="#1F2933"
    />
    <path
      d="M78.6266 48.4154C82.0226 48.4154 84.7756 45.6624 84.7756 42.2664C84.7756 38.8704 82.0226 36.1174 78.6266 36.1174C75.2306 36.1174 72.4776 38.8704 72.4776 42.2664C72.4776 45.6624 75.2306 48.4154 78.6266 48.4154Z"
      fill="#1F2933"
    />
  </svg>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "Avatar2",
  props: {
    hair: String,
    head: String,
    body: String,
    hands: String,
    dot: String,
  },
});
</script>
