<template>
  <svg
    width="158"
    height="200"
    viewBox="0 0 158 200"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M144.69 117.255H12.6591V170.549H144.69V117.255Z" :fill="body" />
    <path
      d="M78.6757 177.678C115.136 177.678 144.692 174.487 144.692 170.549C144.692 166.612 115.136 163.42 78.6757 163.42C42.2157 163.42 12.6591 166.612 12.6591 170.549C12.6591 174.487 42.2157 177.678 78.6757 177.678Z"
      :fill="body"
    />
    <path
      d="M78.6757 124.385C115.136 124.385 144.692 121.193 144.692 117.256C144.692 113.318 115.136 110.126 78.6757 110.126C42.2157 110.126 12.6591 113.318 12.6591 117.256C12.6591 121.193 42.2157 124.385 78.6757 124.385Z"
      fill="#9999D6"
    />
    <path
      d="M18.021 184.776C27.743 184.776 35.6241 176.895 35.6241 167.173C35.6241 157.451 27.743 149.57 18.021 149.57C8.29909 149.57 0.417908 157.451 0.417908 167.173C0.417908 176.895 8.29909 184.776 18.021 184.776Z"
      fill="white"
    />
    <path
      d="M139.979 181.878C149.701 181.878 157.582 173.997 157.582 164.275C157.582 154.553 149.701 146.672 139.979 146.672C130.257 146.672 122.376 154.553 122.376 164.275C122.376 173.997 130.257 181.878 139.979 181.878Z"
      fill="white"
    />
    <path
      d="M25.9774 200C38.3056 200 48.2996 190.006 48.2996 177.678C48.2996 165.35 38.3056 155.356 25.9774 155.356C13.6492 155.356 3.65521 165.35 3.65521 177.678C3.65521 190.006 13.6492 200 25.9774 200Z"
      :fill="hands"
    />
    <path
      d="M133.995 197.102C146.323 197.102 156.317 187.108 156.317 174.78C156.317 162.452 146.323 152.458 133.995 152.458C121.667 152.458 111.673 162.452 111.673 174.78C111.673 187.108 121.667 197.102 133.995 197.102Z"
      :fill="hands"
    />
    <path
      d="M97.9081 117.257C97.9081 117.257 106.268 119.7 118.965 117.021C125.251 115.695 127.985 112.516 127.985 112.516L114.147 110.737L97.9081 117.257Z"
      fill="#282828"
    />
    <path
      d="M46.161 26.3398H111.422C114.673 26.3398 117.891 26.9801 120.894 28.224C123.897 29.4679 126.626 31.2911 128.924 33.5895C131.223 35.8879 133.046 38.6165 134.29 41.6196C135.534 44.6226 136.174 47.8412 136.174 51.0917V91.1474H21.4091V51.0917C21.4091 47.8412 22.0493 44.6226 23.2932 41.6196C24.5371 38.6165 26.3603 35.8879 28.6588 33.5895C30.9572 31.2911 33.6858 29.4679 36.6888 28.224C39.6919 26.9801 42.9105 26.3398 46.161 26.3398V26.3398Z"
      :fill="head"
    />
    <path
      d="M87.3629 88.2889C93.0754 88.2889 97.7064 83.658 97.7064 77.9455C97.7064 72.2329 93.0754 67.6021 87.3629 67.6021C81.6504 67.6021 77.0195 72.2329 77.0195 77.9455C77.0195 83.658 81.6504 88.2889 87.3629 88.2889Z"
      fill="white"
    />
    <path
      d="M70.4049 88.2889C76.1174 88.2889 80.7483 83.658 80.7483 77.9455C80.7483 72.2329 76.1174 67.6021 70.4049 67.6021C64.6924 67.6021 60.0615 72.2329 60.0615 77.9455C60.0615 83.658 64.6924 88.2889 70.4049 88.2889Z"
      fill="white"
    />
    <path
      d="M83.9283 84.0687C87.3098 84.0687 90.0511 81.3275 90.0511 77.946C90.0511 74.5645 87.3098 71.8232 83.9283 71.8232C80.5468 71.8232 77.8056 74.5645 77.8056 77.946C77.8056 81.3275 80.5468 84.0687 83.9283 84.0687Z"
      fill="#282828"
    />
    <path
      d="M73.7379 84.0687C77.1194 84.0687 79.8607 81.3275 79.8607 77.946C79.8607 74.5645 77.1194 71.8232 73.7379 71.8232C70.3564 71.8232 67.6152 74.5645 67.6152 77.946C67.6152 81.3275 70.3564 84.0687 73.7379 84.0687Z"
      fill="#282828"
    />
    <path
      d="M111.863 25.8003C111.863 34.5474 108.389 42.9363 102.203 49.1214C96.0183 55.3066 87.6294 58.7813 78.8823 58.7813C70.1352 58.7813 61.7464 55.3066 55.5612 49.1214C49.3761 42.9363 45.9013 34.5474 45.9013 25.8003"
      :fill="hair"
    />
    <path
      d="M136.172 91.147C136.172 106.447 110.43 118.85 78.675 118.85C46.9205 118.85 21.1894 106.441 21.1894 91.147"
      :fill="hair"
    />
    <path
      d="M97.1303 7.55666C92.2915 2.7179 85.7287 -0.000488281 78.8857 -0.000488281C72.0426 -0.000488281 65.4799 2.7179 60.6411 7.55666C55.8023 12.3954 53.0839 18.9582 53.0839 25.8013C53.0839 29.1896 53.7513 32.5448 55.048 35.6752C56.3446 38.8056 58.2452 41.65 60.6411 44.0459"
      fill="white"
    />
    <path
      d="M60.6376 44.0449C65.4764 48.8836 72.0392 51.602 78.8822 51.602C85.7253 51.602 92.2881 48.8836 97.1268 44.0449C101.966 39.2061 104.684 32.6433 104.684 25.8003C104.684 18.9572 101.966 12.3944 97.1268 7.55566"
      :fill="dot"
    />
  </svg>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "Avatar4",
  props: {
    hair: String,
    head: String,
    body: String,
    hands: String,
    dot: String,
  },

});
</script>

