<template>
  <svg
    width="132"
    height="200"
    viewBox="0 0 132 200"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M118.934 130.293C118.934 168.791 95.2292 200 66.0006 200C36.772 200 13.0677 168.791 13.0677 130.293"
      :fill="body"
    />
    <path
      d="M66.0006 135.516C95.2352 135.516 118.934 133.178 118.934 130.293C118.934 127.408 95.2292 125.07 66.0006 125.07C36.772 125.07 13.0677 127.408 13.0677 130.293C13.0677 133.178 36.768 135.516 66.0006 135.516Z"
      :fill="head"
    />
    <path
      d="M15.0765 141.342C17.1544 141.342 19.1471 142.167 20.6163 143.637C22.0856 145.106 22.911 147.099 22.911 149.177V181.117C22.911 183.195 22.0856 185.188 20.6163 186.657C19.1471 188.126 17.1544 188.951 15.0765 188.951C12.9987 188.951 11.006 188.126 9.53673 186.657C8.06748 185.188 7.24207 183.195 7.24207 181.117V149.177C7.24207 147.099 8.06748 145.106 9.53673 143.637C11.006 142.167 12.9987 141.342 15.0765 141.342Z"
      :fill="dot"
    />
    <path
      d="M15.076 169.747C22.9431 169.747 29.3206 163.369 29.3206 155.502C29.3206 147.635 22.9431 141.258 15.076 141.258C7.20889 141.258 0.831299 147.635 0.831299 155.502C0.831299 163.369 7.20889 169.747 15.076 169.747Z"
      :fill="hands"
    />
    <path
      d="M117.44 141.342C119.518 141.342 121.511 142.167 122.98 143.637C124.449 145.106 125.275 147.099 125.275 149.177V181.117C125.275 183.195 124.449 185.188 122.98 186.657C121.511 188.126 119.518 188.951 117.44 188.951C115.362 188.951 113.37 188.126 111.901 186.657C110.431 185.188 109.606 183.195 109.606 181.117V149.177C109.606 147.099 110.431 145.106 111.901 143.637C113.37 142.167 115.362 141.342 117.44 141.342Z"
      :fill="dot"
    />
    <path
      d="M117.44 169.747C125.307 169.747 131.685 163.369 131.685 155.502C131.685 147.635 125.307 141.258 117.44 141.258C109.573 141.258 103.195 147.635 103.195 155.502C103.195 163.369 109.573 169.747 117.44 169.747Z"
      :fill="hands"
    />
    <path
      d="M66.0004 130.512C97.8971 130.512 123.755 110.141 123.755 85.0119C123.755 59.8828 97.8971 39.5117 66.0004 39.5117C34.1037 39.5117 8.24634 59.8828 8.24634 85.0119C8.24634 110.141 34.1037 130.512 66.0004 130.512Z"
      :fill="body"
    />
    <path
      d="M64.8958 111.892C85.6825 111.892 102.533 99.8576 102.533 85.0121C102.533 70.1665 85.6825 58.1318 64.8958 58.1318C44.1092 58.1318 27.2582 70.1665 27.2582 85.0121C27.2582 99.8576 44.1092 111.892 64.8958 111.892Z"
      :fill="head"
    />
    <path
      d="M72.8705 1.02637C74.7976 1.71982 76.568 2.78846 78.0795 4.17052C79.5909 5.55258 80.8132 7.2206 81.6758 9.0781C82.5385 10.9356 83.0243 12.9457 83.1051 14.9922C83.1859 17.0386 82.8601 19.0808 82.1467 21.0005C81.4332 22.9202 80.3461 24.6794 78.9484 26.1764C77.5507 27.6733 75.8701 28.8783 74.0037 29.7215C72.1373 30.5648 70.1223 31.0296 68.0751 31.0891C66.0279 31.1486 63.9892 30.8016 62.077 30.0681"
      fill="black"
    />
    <path
      d="M62.0767 30.0683C58.171 28.6524 54.987 25.7443 53.2237 21.9827C52.1441 19.6576 51.6593 17.1006 51.813 14.5417C51.9666 11.9827 52.7537 9.50209 54.1037 7.32281C55.4537 5.14354 57.3242 3.33394 59.547 2.05678C61.7697 0.779608 64.275 0.0749258 66.8376 0.00605439C68.8941 -0.0510569 70.9417 0.296347 72.8642 1.02855"
      :fill="dot"
    />
    <path
      d="M8.24607 61.2073C10.3505 61.2073 12.3688 62.0433 13.8569 63.5314C15.345 65.0195 16.181 67.0377 16.181 69.1422V100.882C16.1812 101.924 15.9761 102.956 15.5774 103.919C15.1788 104.882 14.5943 105.756 13.8575 106.493C13.1206 107.23 12.2457 107.815 11.2829 108.213C10.32 108.612 9.28817 108.817 8.24607 108.817C6.1416 108.817 4.12333 107.981 2.63525 106.493C1.14717 105.005 0.311157 102.986 0.311157 100.882V69.1422C0.31169 67.0379 1.14787 65.0199 2.63584 63.532C4.12381 62.044 6.14176 61.2078 8.24607 61.2073Z"
      :fill="hair"
    />
    <path
      d="M123.754 61.2073C125.858 61.2073 127.877 62.0433 129.365 63.5314C130.853 65.0195 131.689 67.0377 131.689 69.1422V100.882C131.689 101.924 131.484 102.956 131.085 103.919C130.687 104.882 130.102 105.756 129.365 106.493C128.629 107.23 127.754 107.815 126.791 108.213C125.828 108.612 124.796 108.817 123.754 108.817C121.65 108.817 119.631 107.981 118.143 106.493C116.655 105.005 115.819 102.986 115.819 100.882V69.1422C115.82 67.0379 116.656 65.0199 118.144 63.532C119.632 62.044 121.65 61.2078 123.754 61.2073Z"
      :fill="hair"
    />
    <path
      d="M72.7943 99.5963C78.3837 99.5963 82.9148 95.0652 82.9148 89.4758C82.9148 83.8863 78.3837 79.3552 72.7943 79.3552C67.2049 79.3552 62.6738 83.8863 62.6738 89.4758C62.6738 95.0652 67.2049 99.5963 72.7943 99.5963Z"
      fill="white"
    />
    <path
      d="M56.997 99.5963C62.5864 99.5963 67.1176 95.0652 67.1176 89.4758C67.1176 83.8863 62.5864 79.3552 56.997 79.3552C51.4076 79.3552 46.8765 83.8863 46.8765 89.4758C46.8765 95.0652 51.4076 99.5963 56.997 99.5963Z"
      fill="white"
    />
    <path
      d="M59.9638 95.4661C63.2722 95.4661 65.9542 92.7841 65.9542 89.4757C65.9542 86.1673 63.2722 83.4854 59.9638 83.4854C56.6554 83.4854 53.9735 86.1673 53.9735 89.4757C53.9735 92.7841 56.6554 95.4661 59.9638 95.4661Z"
      fill="#1F2933"
    />
    <path
      d="M70.0841 95.4661C73.3925 95.4661 76.0745 92.7841 76.0745 89.4757C76.0745 86.1673 73.3925 83.4854 70.0841 83.4854C66.7757 83.4854 64.0938 86.1673 64.0938 89.4757C64.0938 92.7841 66.7757 95.4661 70.0841 95.4661Z"
      fill="#1F2933"
    />
  </svg>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "Avatar5",
  props: {
    hair: String,
    head: String,
    body: String,
    hands: String,
    dot: String,
  },
});
</script>
