<template>
  <!-- Modal -->
  <div
    class="modal fade"
    id="WelcomeModal1"
    tabindex="-1"
    aria-labelledby="WelcomeModal1Label"
    aria-hidden="true"
  >
    <div
      class="modal-dialog modal-dialog-slideout modal-lg modal-dialog-centered"
    >
      <div class="modal-content m-4 m-lg-0">
        <div class="row flex-column-reverse flex-lg-row p-4 p-lg-5">
          <div
            class="col-12 col-lg-6 text-center text-lg-start d-flex flex-column justify-content-between mt-3 mt-lg-0"
          >
            <h2 class="text-primary modal-title">
              {{ "Ciao " + name + "!" }}
            </h2>
            <p class="fs-4 modal-p mt-3">
              Entrando nel mondo di Easycode hai conquistato il tuo primo
              distintivo.
              <br /><br />
              Continua e conquistali tutti!
            </p>
            <button class="btn btn-platform mt-3" type="button" @click="next()">
              VAI AVANTI
            </button>
          </div>
          <div class="col-12 col-lg-6">
            <img
              class="img-fluid"
              src="@/assets/images/frame/key.png"
              alt="floppy"
            />
          </div>
        </div>
      </div>
    </div>
    <!-- Toggle -->
    <button
      id="WelcomeModal1Button"
      type="button"
      class="d-none"
      data-bs-toggle="modal"
      data-bs-target="#WelcomeModal1"
    ></button>
    <!-- Close -->
    <button
      id="WelcomeModal1Close"
      class="d-none"
      data-bs-dismiss="modal"
      aria-label="Close"
    ></button>
  </div>
</template>

<script lang="ts" scoped>
import { defineComponent } from "vue";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "WelcomeModal1",
  props: {
    name: String,
  },
  setup() {
    const router = useRouter();

    const next = () => {
      const closeModal = document.getElementById("WelcomeModal1Close");
      closeModal?.click();

      router.push("/choose-avatar");
    };
    return { next };
  },
});
</script>

<style lang="scss" scoped>
.modal-title {
  font-weight: 600;
  font-size: 32px;
  line-height: 120%;
  letter-spacing: 0.01em;
}
.modal-p {
  font-weight: 500;
  line-height: 120%;
  letter-spacing: 0.01em;
}
</style>
