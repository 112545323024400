<template>
  <div class="row">
    <div class="col-12 d-none d-lg-flex justify-content-end position-relative">
      <img src="@/assets/images/logos/logo.svg" alt="" />
      <router-link
        to="/start"
        class="btn turn-back d-flex align-items-center px-3"
      >
        <img src="@/assets/images/icons/Back.svg" class="me-2" alt="" />
        <span class="turn-back-text">INDIETRO</span>
      </router-link>
    </div>
    <div class="col-12 d-flex justify-content-center d-lg-none">
      <img src="@/assets/images/logos/logo45x45.svg" alt="" />
      <router-link to="/start" class="btn turn-back d-flex align-items-center">
        <img src="@/assets/images/icons/Back.svg" alt="" />
      </router-link>
    </div>
  </div>
  <div
    class="row d-flex flex-column align-items-center justify-content-center pt-5 mt-lg-5"
    style="z-index: 1"
  >
    <div class="col-12 col-lg-8">
      <h1 class="title text-center text-primary my-4">Scegli il tuo avatar!</h1>
      <p class="subtitle text-center m-0 px-4">
        {{
          user.nickname +
          ", scegli l’avatar. Dopo potrai anche cambiarlo se vuoi."
        }}
      </p>
    </div>
    <div
      class="col-12 col-lg-10 d-flex justify-content-center my-4 my-lg-5 p-0"
    >
      <div class="glide">
        <div data-glide-el="track" class="glide__track">
          <ul class="glide__slides py-5">
            <li class="glide__slide">
              <div class="robot-wrapper">
                <img
                  :src="
                    require('@/assets/images/avatars/start/' +
                      getBase(true) +
                      '4.svg')
                  "
                  class="robot"
                  alt=""
                  @click="handleImageClick(5 - 1)"
                /><img
                  class="avatar-select-icon"
                  src="@/assets/images/icons/Selected.svg"
                  alt=""
                />
              </div>
            </li>
            <li class="glide__slide">
              <div class="robot-wrapper">
                <img
                  :src="
                    require('@/assets/images/avatars/start/' +
                      getBase(true) +
                      '5.svg')
                  "
                  class="robot"
                  alt=""
                  @click="handleImageClick(5 - 1)"
                /><img
                  class="avatar-select-icon"
                  src="@/assets/images/icons/Selected.svg"
                  alt=""
                />
              </div>
            </li>
            <li class="glide__slide">
              <div class="robot-wrapper">
                <img
                  :src="
                    require('@/assets/images/avatars/start/' +
                      getBase(true) +
                      '1.svg')
                  "
                  class="robot"
                  alt=""
                  @click="handleImageClick(1 - 1)"
                /><img
                  class="avatar-select-icon"
                  src="@/assets/images/icons/Selected.svg"
                  alt=""
                />
              </div>
            </li>
            <li class="glide__slide">
              <div class="robot-wrapper">
                <img
                  :src="
                    require('@/assets/images/avatars/start/' +
                      getBase(true) +
                      '2.svg')
                  "
                  class="robot"
                  alt=""
                  @click="handleImageClick(2 - 1)"
                />
                <img
                  class="avatar-select-icon"
                  src="@/assets/images/icons/Selected.svg"
                  alt=""
                />
              </div>
            </li>
            <li class="glide__slide">
              <div class="robot-wrapper">
                <img
                  :src="
                    require('@/assets/images/avatars/start/' +
                      getBase(true) +
                      '3.svg')
                  "
                  class="robot"
                  alt=""
                  @click="handleImageClick(3 - 1)"
                />
                <img
                  class="avatar-select-icon"
                  src="@/assets/images/icons/Selected.svg"
                  alt=""
                />
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="col-12 col-lg-4 d-flex justify-content-center px-4 px-lg-0">
      <button
        type="button"
        @click="confirmAvatar()"
        data-bs-toggle="modal"
        data-bs-target="#WelcomeModal2"
        class="btn btn-platform w-100 mt-4"
      >
        HO SCELTO!
      </button>
    </div>
  </div>
</template>

<script>
import { defineComponent, onMounted, onUnmounted } from "vue";

// eslint-disable-next-line
import Glide from "@glidejs/glide";

import { useUserInfoStore } from "@/store";

import {
  primaryAvatarsName,
  secondaryAvatarsName,
  highAvatarsName,
} from "@/core/data/parts";

export default defineComponent({
    name: "ChooseAvatar",
    emits: ["chooseAvatar"],
    setup(props, { emit }) {
        const userStore = useUserInfoStore();
        const user = userStore.currentUser;
        var currentAvatar = 0;
        if (window.screen.width > 768) {
            currentAvatar = 2;
        }
        else {
            currentAvatar = 1;
        }
        const avatarBasicColorsPrimary = [
            {
                part1: "#4d93aa",
                part2: "#5fd4dc",
                part3: "#5fd4dc",
                part4: "#fbd346",
                part5: "#fbd346",
            },
            {
                part1: "#021b5f",
                part2: "#567eeb",
                part3: "#021b5f",
                part4: "#e8533a",
                part5: "#e8533a",
            },
            {
                part1: "#555587",
                part2: "#9c9cd4",
                part3: "#555587",
                part4: "#bc1d8c",
                part5: "#bc1d8c",
            },
            {
                part1: "#ebf0f9",
                part2: "#5fa480",
                part3: "#5fa480",
                part4: "#c82296",
                part5: "#c82296",
            },
            {
                part1: "#fbc411",
                part2: "#ebcbfb",
                part3: "#7d16d4",
                part4: "#010a23",
                part5: "#fbc411",
            },
        ];
        const avatarBasicColorsSecondary = [
            {
                part1: "#4325A0",
                part2: "#D4EA79",
                part3: "#6244C1",
                part4: "#FDD145",
                part5: "#6244C1",
            },
            {
                part1: "#021B5F",
                part2: "#D4EA79",
                part3: "#BF1D8E",
                part4: "#BF1D8E",
                part5: "#D4EA79",
            },
            {
                part1: "#294348",
                part2: "#F5B0A5",
                part3: "#8800FF",
                part4: "#8800FF",
                part5: "#46A6AF",
            },
            {
                part1: "#294348",
                part2: "#2158AE",
                part3: "#2158AE",
                part4: "#2158AE",
                part5: "#2158AE",
            },
            {
                part1: "#294348",
                part2: "#EA7082",
                part3: "#EA7082",
                part4: "#EA7082",
                part5: "#EA7082",
            },
        ];
        const getBase = (capitalize) => {
            const role = userStore.currentUserInfo.role;
            const base = role.split("-")[0];
            if (!base) {
                return;
            }
            if (capitalize) {
                return base.charAt(0).toUpperCase() + base.slice(1);
            }
            else {
                return base;
            }
        };
        const confirmAvatar = async () => {
            
            const role = userStore.currentUserInfo.role;
            let base = null;
            let selected = null;
            if (role == "primary-student") {
                base = primaryAvatarsName[currentAvatar];
                selected = avatarBasicColorsPrimary[currentAvatar];
            }
            else if (role == "secondary-student") {
                base = secondaryAvatarsName[currentAvatar];
                selected = avatarBasicColorsSecondary[currentAvatar];

            }
            else {
                base = highAvatarsName[currentAvatar];
            }
            let data = {
                avatar: {
                    base: base,
                    parts: selected,
                },
            };
            console.log(data);
            emit("chooseAvatar", data);
        };

        const handleImageClick = (index) => {
          console.log('Image clicked:', index);
          glide.go(`=${index}`);
        };

        let glide;
        onMounted(() => {
            glide = new Glide(".glide", {
                type: "carousel",
                perView: 5,
                breakpoints: {
                    768: {
                        perView: 3,
                    },
                },
                peek: {
                    before: -160,
                    after: -160,
                },
            }).mount();
            glide.on(["mount.after", "run"], () => {
                if (window.screen.width > 768) {
                    currentAvatar =
                        glide.index + 2 > 4 ? glide.index - 3 : glide.index + 2;
                }
                else {
                    currentAvatar = glide.index + 1 == 5 ? 0 : glide.index + 1;
                }
            });
        });
        onUnmounted(() => {
            glide.destroy();
        });
        return {
            user,
            confirmAvatar,
            getBase,
            handleImageClick
        };
    },
});
</script>

<style lang="scss" scoped>
.glide {
  cursor: pointer;
}
.glide__slides {
  display: flex;
  align-items: center;
}

.glide__slide {
  display: flex;
  justify-content: center;
  align-items: center;
}
.robot-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 193px !important;
  width: 193px !important;
  background: #fcfcfc;
  border: 6px solid #a1e6e0;
  border-radius: 44.6877px;
  box-shadow: 0px 12px 42px 0px #7b87941f;
  box-shadow: 0px 8px 18px 0px #7b87941f;
  padding: 5%;

  position: relative;
}

.robot {
  height: 80%;
  width: 80%;
}

.form-control {
  background: #ffffff;
  border: 1px solid #cbd2d9;
  box-shadow: 0px 0px 4px rgba(123, 135, 148, 0.08),
    0px 2px 4px rgba(123, 135, 148, 0.1);
  border-radius: 49px;
}

.title {
  font-weight: 700;
  font-size: 2.5rem;
  line-height: 120%;
  text-align: center;
  letter-spacing: -0.019em;
}

.subtitle {
  font-weight: 500;
  font-size: 1.2rem;
  line-height: 120%;
  color: #242424;
}

.turn-back {
  position: absolute;
  top: 15px;
  left: 20px;
//   background: #fde3e4;
//   box-shadow: 0px 1px 8px rgba(123, 135, 148, 0.12),
//     0px 8px 16px rgba(123, 135, 148, 0.08);
//   border-radius: 50px;
  padding: 8px 8px;
  margin: 0 !important;
}

.turn-back-text {
    border-radius: 50px;
  padding: 18px 24px 18px 24px;
  border: 3px solid rgba(60, 232, 218, 1);
  box-shadow: 0px 8px 16px 0px rgba(123, 135, 148, 0.08);
  box-shadow: 0px 1px 8px 0px rgba(123, 135, 148, 0.12);

  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.5px;
  text-align: center;
  color: rgba(65, 45, 205, 1);
  text-transform: uppercase;
/*
  font-weight: 600;
  font-size: 1.2rem;
  line-height: 120%;
  text-align: justify;
  letter-spacing: 0.5px;
  color: var(--bs-blue);
  */
}

.avatar-select-icon {
  position: absolute;
  width: 80px;
  bottom: -50px;
  display: none;
}

@media only screen and (max-width: 768px) {
  .glide__slide--active + li .robot-wrapper {
    height: 243px !important;
    width: 243px !important;
  }

  .glide__slide--active + li .robot-wrapper .avatar-select-icon {
    display: block;
  }
}

@media only screen and (min-width: 768px) {
  .title {
    font-size: 3rem;
  }

  .subtitle {
    font-size: 1.4rem;
  }
  .glide__slide--active + li + li .robot-wrapper {
    height: 243px !important;
    width: 243px !important;
  }

  .glide__slide--active + li + li .robot-wrapper .avatar-select-icon {
    display: block;
  }
}
</style>
