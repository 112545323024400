import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  id: "chooseAvatar",
  class: "container-xl px-lg-5 py-5"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ChooseAvatar = _resolveComponent("ChooseAvatar")!
  const _component_ChooseAvatarHigh = _resolveComponent("ChooseAvatarHigh")!
  const _component_ChooseColors = _resolveComponent("ChooseColors")!
  const _component_WelcomeModal2 = _resolveComponent("WelcomeModal2")!
  const _component_ConfirmAvatarModal = _resolveComponent("ConfirmAvatarModal")!
  const _component_StartEasycodeModal = _resolveComponent("StartEasycodeModal")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!_ctx.changeView && _ctx.userStore.currentUserInfo.role != 'high-student')
      ? (_openBlock(), _createBlock(_component_ChooseAvatar, {
          key: 0,
          onChooseAvatar: _ctx.chooseAvatar
        }, null, 8 /* PROPS */, ["onChooseAvatar"]))
      : (!_ctx.changeView && _ctx.userStore.currentUserInfo.role == 'high-student')
        ? (_openBlock(), _createBlock(_component_ChooseAvatarHigh, {
            key: 1,
            onChooseAvatar: _ctx.chooseAvatar
          }, null, 8 /* PROPS */, ["onChooseAvatar"]))
        : (_openBlock(), _createBlock(_component_ChooseColors, {
            key: 2,
            avatar: _ctx.avatar,
            onReturnBack: _ctx.returnBack
          }, null, 8 /* PROPS */, ["avatar", "onReturnBack"])),
    _createVNode(_component_WelcomeModal2),
    _createVNode(_component_ConfirmAvatarModal),
    _createVNode(_component_StartEasycodeModal, {
      name: _ctx.userStore.currentUser.nickname
    }, null, 8 /* PROPS */, ["name"])
  ]))
}